import AuthenticationPage from '@/pages/authentication/page';
import { SignUp } from '@clerk/clerk-react';
import { ErrorComponent, createRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { Route as RootRoute } from './__root';

const searchSchema = z.object({
  redirect_url: z.string().optional(),
});

export const Route = createRoute({
  path: '/signup',
  getParentRoute: () => RootRoute,
  component: SignUpPage,
  validateSearch: searchSchema,
  errorComponent: ({ error }) => {
    console.log('error @signup', error);
    return <ErrorComponent error={error} />;
  },
});

function SignUpPage() {
  const { redirect_url } = Route.useSearch();
  return (
    <AuthenticationPage>
      <SignUp
        path="/signup"
        signInUrl="/signin"
        redirectUrl={redirect_url || '/organizations'}
        appearance={{
          // layout: {
          //   termsPageUrl: "/terms",
          //   privacyPageUrl: "/privacy",
          // },
          variables: { colorPrimary: 'black' },
        }}
      />
    </AuthenticationPage>
  );
}
