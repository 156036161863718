import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from 'sonner';
import { TextEditor } from '../../common/TextEditor';
import { TPosition, useUpdatePositionMutation } from '@/fetchers/usePosition';
import { useNavigate, useParams } from '@tanstack/react-router';
import { VetonIndicator } from '../../common/VetonIndicator';
import { useEffect } from 'react';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import { RouterParams } from '@/main';
import { usePositionManageStickyActionBar } from '@/hooks/usePositionManageStickyActionBar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';
import { useDescriptionFormStore } from '@/lib/useDescriptionFormStore';
import DOMPurify from 'dompurify';

const displayFormSchema = z.object({
  content: z
    .string({
      required_error: 'Content is required.',
    })
    .min(17, 'Content must be at least 10 characters.'),
});

type DisplayFormValues = z.infer<typeof displayFormSchema>;

export function DescriptionForm({ position }: { position?: TPosition }) {
  const { organizationId, slug } = useParams({ strict: false }) as RouterParams;
  const { creating } = usePositionManageRouteSearch();
  const navigate = useNavigate();
  const updatePosition = useUpdatePositionMutation();
  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: {
      content: '',
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = form;

  useEffect(() => {
    if (position?.content) {
      reset({ content: position.content });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position?.content]);

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty && !creating,
    onSave: handleSubmit(onSubmit),
  });
  const { setIsValid: setIsDescriptionFormValid } = useDescriptionFormStore();

  useEffect(() => {
    setIsDescriptionFormValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  async function onSubmit({ content }: DisplayFormValues) {
    const { data, error } = await updatePosition({
      data: {
        // sanitize the content to avoid XSS
        content: DOMPurify.sanitize(content),
      },
      organizationId: Number(organizationId),
      slug,
    });

    if (data) {
      toast.success('Position updated successfully!');
      if (creating) {
        navigate({
          to: '/organizations/$organizationId/positions/manage/$slug/$step',
          params: {
            organizationId,
            slug,
            step: 'application',
          },
          search: {
            creating: true,
          },
          ignoreBlocker: true,
        });
      }
    } else {
      toast.error(error);
    }
  }

  const { StickyActionBar } = usePositionManageStickyActionBar({
    isFormDirty: isDirty,
    position,
    isPublishDisabled: !isValid,
    onSave: handleSubmit(onSubmit),
    publishDisabledReason:
      'Please complete the position description to publish',
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={control}
            name="content"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Job requirements & What to expect from the applicant
                  <VetonIndicator required />
                </FormLabel>
                <FormDescription>
                  This is the main description for the position. Write at least
                  a few paragraphs to make sure applicants and Veton AI knows
                  what is needed for this position
                </FormDescription>
                <FormControl>
                  <TextEditor
                    // @ts-expect-error style works
                    // style={{ height: "80%" }}
                    className="h-[600px] pb-12"
                    theme="snow"
                    placeholder="Your detailed position description"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {StickyActionBar}
        </form>
      </Form>
    </>
  );
}
