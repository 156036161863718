import { Separator } from '@/components/ui/separator';
import { Briefcase, FileText, Infinity, LifeBuoy, Users } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';

import { Badge } from '@/components/ui/badge';
import { Plan, PLAN_LIST_PUBLIC } from '@/fetchers/useOrganization';

type TTier = {
  amount: number;
  isMonthly: boolean;
  maxInterviewAllowed: number;
  maxApplicationAvailable: number;
  maxResumeAnalysisAvailable: number;
  atsIntegration: boolean;
  nextRenewalDate: string;
  planStatus?: string;
  currentPlan?: string;
};
type TProps = {
  tier: TTier;
  portalUrl?: string;
  metrics: {
    interviewsTotal: number;
    applicationsTotal: number;
    teamCount: number;
  };
};
export function BillingComponent({ tier, metrics }: Readonly<TProps>) {
  const { showNewMessage } = useIntercomMessenger();

  function getPlanName(tier: TTier) {
    if (tier?.currentPlan) {
      if (tier?.currentPlan === 'free') {
        return 'Free Trial';
      }

      return PLAN_LIST_PUBLIC[tier.currentPlan as Plan]
    }

    return 'Free Trial';
  }

  return (
    <div className="mx-auto h-screen w-screen max-w-5xl space-y-6 overflow-auto p-5 md:w-full md:p-10 lg:overflow-hidden lg:pb-16">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Billing</h2>
        <p className="text-muted-foreground">
          Manage your billing and usage settings.
        </p>
      </div>
      <Separator className="my-6" />

      <div className="flex items-center justify-between"></div>

      <div className="border-1 rounded border p-4 shadow">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col lg:flex-row">
            <h3 className="mr-4 text-xl font-bold">{getPlanName(tier)}</h3>
            {tier.nextRenewalDate && (
              <Badge variant={'secondary'} className="w-[fit-content]">
                {tier?.planStatus === 'cancelled'
                  ? 'EXPIRES AT'
                  : 'RENEWAL DATE'}
                : {tier.nextRenewalDate?.toUpperCase()}
              </Badge>
            )}
          </div>
          <div>
            <Button
              className="float-right"
              variant={'outline'}
              onClick={() => {
                showNewMessage('Hi! I would like to manage my billing.');
              }}
            >
              Manage Billing
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 pt-4 lg:grid-cols-3">
          <div className="col-span-1 space-y-4">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Total Interviews
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {metrics.interviewsTotal} / {tier.maxInterviewAllowed}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Total Applicants & Resume Analysis
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center text-2xl font-bold">
                  {metrics.applicationsTotal} /{' '}
                  <Infinity className="ml-1 mt-1 font-bold" />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Admin Seats
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center text-2xl font-bold">
                  {metrics.teamCount} / 30
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  ATS Integration
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {tier.atsIntegration ? 'Included' : 'Not Included'}
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="relative col-span-2">
            <div className="space-y-4">
              <div className="flex w-full space-x-4">
                <FeatureBlock
                  title="Unlimited Job Positions"
                  subtitle="Create as many job positions as you want. There is no limit to the number of job positions you can create."
                  Icon={Briefcase}
                />
                <FeatureBlock
                  title="Unlimited Applications"
                  subtitle="Receive as many applications as you can get. There is no limit to the number of applications you can receive."
                  Icon={Users}
                />
              </div>
              <div className="flex w-full space-x-4">
                <FeatureBlock
                  title="Unlimited Resume Analysis"
                  subtitle="High volume of applicants? We got you covered. Analyze as many resumes as you want."
                  Icon={FileText}
                />
                <FeatureBlock
                  title="Support"
                  subtitle="Our team is always here to help you with any issues you may have. We are here to help"
                  Icon={LifeBuoy}
                />
              </div>
            </div>
            <Button
              className="absolute bottom-4 right-4 bg-blue-500 hover:bg-blue-600"
              onClick={() => {
                showNewMessage(
                  'Hi! I would like to talk to sales about my plan.'
                );
              }}
            >
              Talk to Sales
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FeatureBlock({ Icon, title, subtitle }: any) {
  return (
    <div className="w-full p-4">
      <Icon className="mb-4 size-8" />

      <p className="text-lg font-bold text-gray-800">{title}</p>
      <p className="text-sm text-gray-600">{subtitle}</p>
    </div>
  );
}
