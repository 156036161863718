export const afterApplicationSubjectFrench =
  'Nouvel entretien pour {{position_name}} chez {{company_name}}';

export const afterInterviewSubjectFrench =
  '{{company_name}} | Entretien pour {{position_name}}';

export const disqualifiedSubjectFrench =
  'Mise à jour du statut de candidature pour {{position_name}} chez {{company_name}}';

export const applicationEmailFrench = `<p>Cher/Chère {{candidate_name}},</p><p><br/></p><p>Merci beaucoup d'avoir postulé pour le poste de {{position_name}} chez {{company_name}}. Nous sommes prêts pour votre entretien !</p><p><br/></p><p>Dès que vous êtes prêt(e), veuillez cliquer sur le lien ci-dessous pour commencer votre entretien.</p>`;

export const interviewCompletedEmailFrench = `<p>Cher/Chère {{candidate_name}},</p><p><br/></p><p>Merci d'avoir complété l'entretien pour le poste de {{position_name}} chez {{company_name}}. Nous examinerons votre entretien et reviendrons vers vous. Si vous avez des questions, n'hésitez pas à nous contacter à {{company_email}}.</p><p><br/></p><p>Nous valorisons vos retours ! Veuillez prendre un moment pour remplir notre bref questionnaire sur votre expérience d'entretien : https://sprw.io/stt-eG-cv.</p><p><br/></p><p>Nous sommes enthousiastes à l'idée de travailler ensemble et avons hâte de vous recontacter bientôt.</p><p><br/></p><p>Cordialement,<br/>{{company_name}}</p>`;

export const disqualifiedEmailFrench = `<p>Cher/Chère {{candidate_name}},</p><p><br/></p><p>Nous regrettons de vous informer que vous avez été disqualifié(e) du processus de candidature pour le poste de {{position_name}}. Si vous avez des questions, n'hésitez pas à nous contacter à l'adresse {{company_email}}.</p><p><br/></p><p>Cordialement,<br/>{{company_name}}</p>`;
