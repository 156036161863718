import { Button } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { Phone, PhoneCall } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import { cn, getContrastColorFromBackgroundColor } from '@/lib/utils';
import { useParams, useSearch } from '@tanstack/react-router';
import {
  useConfirmationPageStartInterview,
  useInterviewDetailsQuery,
  useUpdateInterviewConfirmationMutation,
} from '@/fetchers/useInterview';
import logo from '@/assets/svg/logo-whitebg.svg';
import { useIsMutating } from '@tanstack/react-query';

import { Loader } from '../loader';
import { MIME_TYPE, UploadInput } from '../common/UploadInput';
import { InterviewGuideline } from './interview-guideline';
import { RouterParams } from '@/main';
import InterviewConfirmationHeader from './interview-confirmation-header';
import { useTranslation } from 'react-i18next';
import { UpdatePhoneDialog } from './update-phone-dialog';
import { Alert } from '../alert';

export function ConfirmationPage() {
  const { phone: rawPhone } = useSearch({ strict: false });
  const phone = decodeURIComponent(`${rawPhone}` || '');
  const { interviewUUid } = useParams({ strict: false }) as RouterParams;
  const [confirmed, setConfirmed] = useState(false);
  const [hasResume, setHasResume] = useState(true);
  const [isUpdatePhoneDialogOpen, setIsUpdatePhoneDialogOpen] = useState(false);
  const update = useUpdateInterviewConfirmationMutation();
  const isMutating = useIsMutating();
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  const { data: interviewDetails, isFetching: isFetchingInterviewDetails } =
    useInterviewDetailsQuery({
      interviewUUid,
    });

  useEffect(() => {
    if (!interviewDetails) return;
    setHasResume(interviewDetails.hasResume);
    i18n.changeLanguage(interviewDetails.language);
  }, [interviewDetails, i18n]);

  useConfirmationPageStartInterview({
    interviewUUid,
    enabled: confirmed,
  });

  const organization = interviewDetails?.organization;
  const phoneIsValid = interviewDetails?.phoneIsValid;

  if (isFetchingInterviewDetails) {
    return <Loader />;
  }

  return (
    <>
      <UpdatePhoneDialog
        open={isUpdatePhoneDialogOpen}
        setOpen={setIsUpdatePhoneDialogOpen}
        phone={phone}
      />
      <div className="mx-auto flex h-screen w-screen flex-col justify-between overflow-y-auto overflow-x-hidden">
        <InterviewConfirmationHeader
          logo={organization?.logoUrl || ''}
          title={organization?.name || ''}
          companyReachOutEmail={organization?.companyReachOutEmail || ''}
        />

        <div
          className="flex flex-col items-center justify-center space-y-4 p-8 text-center"
          style={{
            background: organization?.color,
          }}
        >
          <div
            className="max-w-2xl space-y-1 text-2xl font-bold lg:text-4xl"
            style={{
              color: getContrastColorFromBackgroundColor(organization?.color),
            }}
          >
            <p>{t('title')}</p>
            <p className="text-sm lg:text-lg">{interviewDetails?.title}</p>
          </div>

          <div className="flex flex-col gap-2">
            <PhoneInput
              value={phone}
              disabled
              buttonStyle={{
                display: 'none',
              }}
              disableInitialCountryGuess={!phoneIsValid}
              inputProps={{
                className: cn(
                  `flex w-full rounded-md border-none bg-background 
                pl-14 py-2 text-sm ring-offset-background file:border-0 
                file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground 
                focus-visible:outline-none focus-visible:ring-2
                focus-visible:ring-ring focus-visible:ring-offset-2 
                disabled:cursor-not-allowed
                text-2xl md:text-3xl lg:text-4xl font-semibold px-6 p-4 text-center`
                ),
              }}
              containerClass={cn({
                'border-2 border-red-500 text-red-500 rounded-md max-w-[450px] mx-auto':
                  !phoneIsValid,
              })}
            />
            {!phoneIsValid && (
              <Alert
                variant="ghost"
                severity="error"
                description={t('phone_number_invalid_message')}
                descriptionClassName="text-base"
              />
            )}
          </div>

          {hasResume ? (
            confirmed ? (
              <Button
                variant="outline"
                className="pointer-events-none border-none py-8 text-lg font-semibold text-green-600 md:text-2xl"
              >
                <PhoneCall className="mr-2 size-6 text-center" />
                {t('calling')}
              </Button>
            ) : (
              <div className="flex flex-col gap-2">
                <Button
                  onClick={() => {
                    if (!phoneIsValid) {
                      setIsUpdatePhoneDialogOpen(true);
                    } else {
                      setConfirmed(true);
                    }
                  }}
                  className="mx-auto bg-black text-lg font-semibold text-white hover:bg-gray-800 md:py-8 md:text-2xl"
                >
                  {phoneIsValid && <Phone className="mr-2 size-4 md:size-6" />}
                  {!phoneIsValid
                    ? t('update_your_phone_number')
                    : t('call_me_now')}
                </Button>

                {phoneIsValid && (
                  <div
                    className="flex items-center"
                    style={{
                      color: getContrastColorFromBackgroundColor(
                        organization?.color
                      ),
                    }}
                  >
                    <p className="text-lg">{t('wrong_number')}</p>
                    <Button
                      variant="link"
                      onClick={() => setIsUpdatePhoneDialogOpen(true)}
                      className="pl-1 text-lg font-normal text-inherit underline"
                    >
                      {t('click_here_to_update')}
                    </Button>
                  </div>
                )}
              </div>
            )
          ) : (
            <UploadInput
              buttonOnly
              buttonOnlyText={t('upload_resume')}
              isLoading={isMutating > 0}
              className="bg-black py-8 text-lg hover:bg-black md:text-2xl"
              iconClassName="size-6"
              mimeType={MIME_TYPE.PDF}
              onChange={async (event) => {
                const file = event.target.files?.[0];

                if (!file) {
                  console.log('no file');
                  return;
                }

                await update({
                  interviewUUid,
                  resume: file,
                });

                setHasResume(true);
              }}
            />
          )}
        </div>
        <InterviewGuideline />

        <a
          href="https://veton.ai"
          target="_blank"
          className="sticky bottom-0 left-0 flex h-[60px] w-full items-center justify-center border-t border-gray-100 bg-white p-4 text-lg"
        >
          Powered by{' '}
          <img
            alt="powered by veton.ai"
            src={logo}
            style={{ height: 25 }}
            className="ml-1 flex"
          />
        </a>
      </div>
    </>
  );
}
