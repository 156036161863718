import React from 'react';
import { Button } from '../ui/button';
import { useSidebar } from '../ui/sidebar';

type StickyActionBarProps = {
  isSaveLoading?: boolean;
  isSaveDisabled?: boolean;
  saveButtonText?: string;
  onSave?: () => void;
  additionalActions?: React.ReactNode[];
};

const StickyActionBar = ({
  saveButtonText = 'Save',
  isSaveLoading,
  isSaveDisabled,
  onSave,
  additionalActions = [],
}: StickyActionBarProps) => {
  const { open, isMobile } = useSidebar();
  return (
    <div
      className="sticky-action-bar fixed bottom-0 left-0 right-0 z-20 ml-auto flex border-t bg-white py-4 shadow-sm"
      style={{
        width: isMobile ? '100vw' : `calc(100vw - ${open ? 256 : 48}px)`,
      }}
    >
      <div className="mx-auto flex w-full max-w-4xl justify-end gap-2 pr-4 xl:pr-0">
        <Button
          variant="default"
          type={onSave ? 'button' : 'submit'}
          isLoading={isSaveLoading}
          disabled={isSaveLoading || isSaveDisabled}
          onClick={onSave}
        >
          {saveButtonText}
        </Button>
        {additionalActions.map((action, index) => (
          <React.Fragment key={index}>{action}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default StickyActionBar;
