import {
  createRootRouteWithContext,
  ErrorComponent,
  Outlet,
  ScrollRestoration,
} from '@tanstack/react-router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { lazy, Suspense } from 'react';
import { RouterContext } from '@/main';
import { Toaster } from '@/components/ui/sonner';
import { Loader } from '@/components/core/loader';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        import('@tanstack/router-devtools').then((m) => ({
          default: m.TanStackRouterDevtools,
        }))
      );

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  errorComponent: ({ error }) => {
    console.log('error @__root', error);
    return <ErrorComponent error={error} />;
  },
});

function RootComponent() {
  return (
    <Suspense fallback={<Loader />}>
      <ScrollRestoration />

      <Outlet />

      <Toaster visibleToasts={1} />

      <ReactQueryDevtools buttonPosition="bottom-right" />
      <TanStackRouterDevtools />
    </Suspense>
  );
}
