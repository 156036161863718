import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { snakeCase, isArray, isObject, camelCase } from 'lodash';
import { TPosition } from '@/fetchers/usePosition';
import { toast } from 'sonner';
import { Language } from '@/i18n';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type ConvertToSnakeCase<T> = {
  [K in keyof T as K extends string
    ? ConvertToSnakeCase<K>
    : K]: T[K] extends Array<infer U>
    ? ConvertToSnakeCase<U>[]
    : T[K] extends object
      ? ConvertToSnakeCase<T[K]>
      : T[K];
};
interface ConversionOptions {
  ignore?: string[];
}

export function convertToSnakeCase<T extends object, V = T[keyof T]>(
  obj: T,
  options: ConversionOptions = {}
): ConvertToSnakeCase<T> {
  const { ignore = [] } = options;

  if (isArray(obj)) {
    return obj.map((item) =>
      convertToSnakeCase(item, options)
    ) as unknown as ConvertToSnakeCase<T>;
  } else if (isObject(obj)) {
    const convertedObj: Record<string, V> = {};
    Object.keys(obj).forEach((key) => {
      const convertedKey = ignore.includes(key) ? key : snakeCase(key);
      // @ts-ignore
      convertedObj[convertedKey] = convertToSnakeCase(obj[key], options);
    });
    return convertedObj as ConvertToSnakeCase<T>;
  }
  return obj as ConvertToSnakeCase<T>;
}

export function convertToCamelCase<T extends object, V = T[keyof T]>(
  obj: T,
  options: ConversionOptions = {}
): ConvertToSnakeCase<T> {
  const { ignore = [] } = options;

  if (isArray(obj)) {
    return obj.map((item) =>
      convertToCamelCase(item, options)
    ) as unknown as ConvertToSnakeCase<T>;
  } else if (isObject(obj)) {
    const convertedObj: Record<string, V> = {};
    Object.keys(obj).forEach((key) => {
      const convertedKey = ignore.includes(key) ? key : camelCase(key);
      // @ts-ignore
      convertedObj[convertedKey] = convertToCamelCase(obj[key], options);
    });
    return convertedObj as ConvertToSnakeCase<T>;
  }
  return obj as ConvertToSnakeCase<T>;
}

export const alphaNumericRegex = /^[\da-z]+$/i;

export function getOrganizationSlugIfSubdomain() {
  const hostname = window.location.hostname.split('.');
  const isLocalhost = hostname?.[0] === 'localhost';
  if (isLocalhost) return undefined;
  const isApp = hostname?.[0] === 'app' && hostname?.[1] === 'veton';
  if (isApp) return undefined;

  const organizationSlug = hostname?.[0];
  return organizationSlug;
}

export const parseSalaryToDisplay = (salary: number, currency: string) => {
  if (!salary) return '';
  if (!currency) return '';
  return salary.toLocaleString(Language.EN_US, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export function getSubtitleFromPosition(position: TPosition) {
  let subtitle = '';
  if (position?.location) {
    subtitle += `${position.location}`;
  }
  if (position?.state) {
    subtitle += ` | ${position.state}`;
  }
  if (position?.city) {
    subtitle += ` | ${position.city}`;
  }
  if (position?.remote) {
    subtitle += ` | Remote`;
  }

  if (
    position?.salaryMin &&
    position?.salaryMax &&
    position?.salaryCurrency &&
    position?.salaryType
  ) {
    // const parseSalaryToDisplay = (salary: number) => {
    //   return salary.toLocaleString("en-US", {
    //     style: "currency",
    //     currency: position.salaryCurrency,
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   });
    // };
    if (position.salaryMin && position.salaryMax) {
      subtitle += ` | from ${parseSalaryToDisplay(position.salaryMin, position.salaryCurrency)} up to ${parseSalaryToDisplay(position.salaryMax, position.salaryCurrency)} ${position.salaryType}`;
    } else if (position.salaryMin && !position.salaryMax) {
      subtitle += ` | from ${parseSalaryToDisplay(position.salaryMin, position.salaryCurrency)} ${position.salaryType}`;
    } else if (!position.salaryMin && position.salaryMax) {
      subtitle += ` | up to ${parseSalaryToDisplay(position.salaryMax, position.salaryCurrency)} ${position.salaryType}`;
    }
  }

  if (position?.internalId) {
    subtitle = `${position.internalId}`;
  }

  return subtitle;
}

export function getDominantColor(backgroundColor?: string) {
  if (!backgroundColor) return 'white';
  const bgColor = backgroundColor;
  if (bgColor && bgColor.startsWith('linear-gradient')) {
    // get the first hex color of the gradient
    const dominantColor = bgColor.match(/#([0-9a-f]{6})/i)?.[0];
    if (dominantColor) {
      return dominantColor;
    }
  }
  return bgColor;
}
export function getContrastColorFromBackgroundColor(
  backgroundColor?: string,
  opacity = 1
) {
  if (!backgroundColor) return 'white';
  let bgColor = backgroundColor;
  if (bgColor && bgColor.startsWith('linear-gradient')) {
    // get the first hex color of the gradient
    const dominantColor = bgColor.match(/#([0-9a-f]{6})/i)?.[0];
    if (dominantColor) {
      bgColor = dominantColor?.[1];
      return adjustColorBrightness({ bgColor: dominantColor, opacity });
    }
  }
  // Set the background color of the button

  // Calculate the brightness of the background color
  return adjustColorBrightness({ bgColor, opacity });
}

function adjustColorBrightness({
  bgColor,
  opacity,
}: {
  bgColor: string;
  opacity: number;
}) {
  const isBright = getIsBright(bgColor);

  // Set the text color based on the brightness
  const textColor = isBright
    ? `rgba(0, 0, 0, ${opacity})`
    : `rgba(255, 255, 255, ${opacity})`;
  return textColor;
}

export function getBrightness(bgColor: string) {
  const color = bgColor.substring(1); // Remove the #
  const rgb = parseInt(color, 16); // Convert hex to RGB
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Calculate brightness

  return brightness;
}

export function getIsBright(bgColor: string) {
  const brightness = getBrightness(bgColor);

  return brightness > 148;
}

export const colorShade = (col: string, amt: number) => {
  try {
    col = col.replace(/^#/, '');
    if (col.length === 3)
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

    // @ts-ignore
    let [r, g, b] = col.match(/.{2}/g);
    [r, g, b] = [
      parseInt(r, 16) + amt,
      parseInt(g, 16) + amt,
      parseInt(b, 16) + amt,
    ];

    r = Math.max(Math.min(255, r), 0).toString(16);
    g = Math.max(Math.min(255, g), 0).toString(16);
    b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (r.length < 2 ? '0' : '') + r;
    const gg = (g.length < 2 ? '0' : '') + g;
    const bb = (b.length < 2 ? '0' : '') + b;

    return `#${rr}${gg}${bb}`;
  } catch {
    return col;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleQueryError = (error: any) => {
  const detail = error?.response?.data?.detail;
  const message = typeof detail === 'string' ? detail : 'Something went wrong';
  toast.error(message);
};

export function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAPIError(error: any) {
  try {
    const detail = error?.response?.data?.detail;

    if (typeof detail === 'string') {
      return detail;
    }

    if (detail?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return error?.response?.data?.detail?.map((e: any) => e?.msg).join(', ');
    }
  } catch (error) {
    console.log('error parsing error', error);
    return 'Something went wrong';
  }
}
