import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { RequiredStar } from '../common/RequiredStar';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

const connectionSchema = z.object({
  username: z.string().min(1, { message: 'Email is required' }),
  password: z.string().min(1, { message: 'Password is required' }),
});

export type BreezyConnectionFormValues = z.infer<typeof connectionSchema>;

interface BreezyConnectionProps {
  onSubmit: (data: BreezyConnectionFormValues) => void;
}

export function BreezyConnection({ onSubmit }: BreezyConnectionProps) {
  const form = useForm<BreezyConnectionFormValues>({
    resolver: zodResolver(connectionSchema),
  });

  function onSubmitHandler(data: BreezyConnectionFormValues) {
    onSubmit(data);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandler)}
        className="w-full space-y-4"
      >
        <div className="w-full space-y-2">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel aria-required>
                  Email
                  <RequiredStar />
                </FormLabel>
                <FormDescription></FormDescription>
                <FormControl>
                  <Input placeholder="Email" {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="w-full space-y-2">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="">
                <FormLabel aria-required>
                  Password
                  <RequiredStar />
                </FormLabel>
                <FormDescription></FormDescription>
                <FormControl>
                  <Input placeholder="Password" type="password" {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="self-end">
          <Button className="w-full">Connect</Button>
        </div>
      </form>
    </Form>
  );
}
