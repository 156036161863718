import { z } from 'zod';

import { useForm, UseFormProps } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  applicationEmailEnglish,
  disqualifiedEmailEnglish,
  interviewCompletedEmailEnglish,
  afterApplicationSubjectEnglish,
  afterInterviewSubjectEnglish,
  disqualifiedSubjectEnglish,
} from '@/components/core/position/communication/email-templates/en';
import { TCommunicationForm } from '@/fetchers/usePosition';
import DOMPurify from 'dompurify';
import { Language } from '@/i18n';
import {
  AutoSendOption,
  CommunicationMethod,
  InterviewDelay,
  InterviewType,
} from '@/components/core/position/communication/types';

export const positionCommunicationFormSchema = z.object({
  language: z.nativeEnum(Language).optional().default(Language.EN_US),
  selectedMethod: z.nativeEnum(CommunicationMethod),
  afterApplication: z.string().min(1, 'Required'),
  afterApplicationSubject: z
    .string()
    .min(1, 'Required')
    .default('New interview'),
  afterInterview: z.string().min(1, 'Required'),
  afterInterviewSubject: z
    .string()
    .min(1, 'Required')
    .default('Interview completed'),
  autoSendOption: z.nativeEnum(AutoSendOption).optional(),
  disqualified: z.string().optional(),
  disqualifiedSubject: z
    .string()
    .optional()
    .default('Application status update'),
  disqualifiedToggle: z.enum(['enabled', 'disabled']).optional(),
  autoInterview: z.boolean().optional().default(false),
  interviewType: z.nativeEnum(InterviewType),
  interviewDelay: z.nativeEnum(InterviewDelay),
  isInitialized: z.boolean().default(false).optional(),
});

export type PositionCommunicationFormValues = z.infer<
  typeof positionCommunicationFormSchema
>;

// This can come from your database or API.
export const positionCommunicationFormDefaultValues: PositionCommunicationFormValues =
  {
    language: Language.EN_US,
    selectedMethod: CommunicationMethod.EMAIL,
    interviewType: InterviewType.PHONE,
    interviewDelay: InterviewDelay.NO_DELAY,
    autoSendOption: AutoSendOption.SEND_ALL,
    afterApplicationSubject: afterApplicationSubjectEnglish,
    afterInterviewSubject: afterInterviewSubjectEnglish,
    disqualifiedSubject: disqualifiedSubjectEnglish,
    afterApplication: applicationEmailEnglish,
    afterInterview: interviewCompletedEmailEnglish,
    disqualified: disqualifiedEmailEnglish,
    disqualifiedToggle: 'disabled',
    autoInterview: true,
  };

export const handleMapFormValuesToData = (
  values: PositionCommunicationFormValues
): Partial<TCommunicationForm> => ({
  ...values,
  interviewDelay: values.autoInterview
    ? values.interviewDelay
    : InterviewDelay.NO_DELAY,
  autoSendOption: values.autoInterview
    ? values.autoSendOption
    : AutoSendOption.SEND_ALL,
  afterApplication: DOMPurify.sanitize(values.afterApplication),
  afterInterview: DOMPurify.sanitize(values.afterInterview),
  disqualified:
    values.disqualifiedToggle === 'disabled'
      ? ''
      : DOMPurify.sanitize(values.disqualified ?? ''),
  disqualifiedSubject:
    values.disqualifiedToggle === 'disabled' ? '' : values.disqualifiedSubject,
});

export const usePositionCommunicationForm = (
  options?: UseFormProps<PositionCommunicationFormValues>
) => {
  return useForm<PositionCommunicationFormValues>({
    resolver: zodResolver(positionCommunicationFormSchema),
    defaultValues: {
      ...positionCommunicationFormDefaultValues,
      ...options?.defaultValues,
    },
    ...options,
  });
};
