import AlertModal from '@/components/core/common/AlertModal';
import { useResetInterviewMutation } from '@/fetchers/useInterview';
import { useState } from 'react';
import { toast } from 'sonner';

const useResetInterviewModal = () => {
  const [open, setOpen] = useState(false);
  const [interviewId, setInterviewId] = useState<number | undefined>(undefined);
  const resetInterview = useResetInterviewMutation();

  async function handleResetInterview() {
    if (!interviewId) return;
    await resetInterview({
      interviewId,
    }).then(() => {
      toast.success('Interview reset successfully');
    });
  }

  const handleOpenModal = (interviewId: number) => {
    setInterviewId(interviewId);
    setOpen(true);
  };

  const ResetInterviewModal = (
    <AlertModal
      open={!!open}
      onClose={() => setOpen(false)}
      title="You are about to reset the interview for this applicant"
      description={`Are you sure you want to reset the interview for this applicant? This action cannot be undone. Once reset, the applicant will receive an email notification.`}
      confirmText="Reset interview"
      onProceed={handleResetInterview}
    />
  );

  return {
    handleOpenResetInterviewModal: handleOpenModal,
    ResetInterviewModal,
  };
};

export default useResetInterviewModal;
