import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { Link, useRouterState } from '@tanstack/react-router';

interface NavItem {
  href: string;
  title: string;
  disabled?: boolean;
  items?: NavItem[];
}

interface InnerSidebarProps extends React.HTMLAttributes<HTMLElement> {
  items: NavItem[];
  checkDisabled?: (item: NavItem) => boolean;
}

const NavLink = ({
  item,
  level = 0,
  pathname,
  checkDisabled,
}: {
  item: NavItem;
  level?: number;
  pathname: string;
  checkDisabled?: (item: NavItem) => boolean;
}) => {
  const hasSubItems = item.items && item.items.length > 0;

  return (
    <div className="flex w-full flex-col">
      {hasSubItems ? (
        // Parent item with sub-items rendered as a header
        <div
          className={cn('px-3 py-2 text-sm font-medium', {
            'pl-8': level > 0,
            'pointer-events-none text-gray-400':
              item.disabled || checkDisabled?.(item),
          })}
        >
          {item.title}
        </div>
      ) : (
        // Clickable link for items without sub-items
        <Link
          key={item.href}
          disabled={checkDisabled && item.href.includes('create')}
          // @ts-ignore
          to={item.href}
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            'w-full justify-start',
            pathname === item.href ? 'bg-muted' : 'hover:bg-muted'
          )}
        >
          {item.title}
        </Link>
      )}

      {hasSubItems && (
        <div className="flex flex-col gap-1">
          {(item.items ?? []).map((subItem) => (
            <NavLink
              key={subItem.href}
              item={subItem}
              level={level + 1}
              pathname={pathname}
              checkDisabled={checkDisabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export function InnerSidebar({
  className,
  items,
  checkDisabled,
  ...props
}: InnerSidebarProps) {
  const router = useRouterState();
  const pathname = router.location.pathname;

  return (
    <nav
      className={cn('flex space-y-1 overflow-x-auto lg:flex-col', className)}
      {...props}
    >
      {items.map((item) => (
        <NavLink
          key={item.href}
          item={item}
          pathname={pathname}
          checkDisabled={checkDisabled}
        />
      ))}
    </nav>
  );
}

export default InnerSidebar;
