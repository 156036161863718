import { cn } from '@/lib/utils';
import * as ShadcnAlert from '../../ui/alert';
import {
  CheckCircle,
  CircleAlert,
  Info,
  LucideIcon,
  TriangleAlert,
} from 'lucide-react';
import { Button } from '../../ui/button';
import { forwardRef } from 'react';

import './styles.css';

type AlertSeverity = 'error' | 'info' | 'success' | 'warning';

export type AlertProps = {
  show?: boolean;
  action?: React.ReactNode;
  title?: string;
  description: React.ReactNode;
  severity: AlertSeverity;
  icon?: React.ReactNode;
  className?: string;
  variant?: 'default' | 'outline' | 'ghost';
  titleClassName?: string;
  descriptionClassName?: string;
  iconClassName?: string;
  dismissable?: boolean;
  onDismiss?: () => void;
};

const stylesBySeverity: Record<
  AlertSeverity,
  {
    default: string;
    outline: string;
    ghost: string;
  }
> = {
  success: {
    default: 'bg-green-100 text-green-900',
    outline: 'border-green-600 text-green-600',
    ghost: 'text-green-600',
  },
  info: {
    default: 'bg-sky-100 text-sky-900',
    outline: 'border-sky-600 text-sky-600',
    ghost: 'text-sky-600',
  },
  warning: {
    default: 'bg-orange-100 text-orange-900',
    outline: 'border-orange-600 text-orange-600',
    ghost: 'text-orange-600',
  },
  error: {
    default: 'bg-red-100 text-red-900',
    outline: 'border-red-600 text-red-600',
    ghost: 'text-red-600',
  },
};

const iconBySeverity: Record<AlertSeverity, LucideIcon> = {
  success: CheckCircle,
  info: Info,
  warning: TriangleAlert,
  error: CircleAlert,
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      show = true,
      title,
      description,
      action = null,
      severity,
      icon,
      className,
      titleClassName,
      descriptionClassName,
      iconClassName,
      variant = 'default',
      dismissable = false,
      onDismiss,
    },
    ref
  ) => {
    const Icon = iconBySeverity[severity];

    if (!show) return null;

    return (
      <ShadcnAlert.Alert
        ref={ref}
        className={cn(
          stylesBySeverity[severity][variant],
          'relative flex flex-col gap-2 rounded-md md:flex-row [&>svg+div]:translate-y-[0px] [&>svg]:static [&>svg~*]:pl-3',
          variant === 'default' && 'border-none',
          variant !== 'default' && 'bg-transparent',
          variant === 'ghost' && 'border-none p-0',
          className
        )}
      >
        <div className="flex items-center">
          {icon ?? (
            <Icon
              className={cn(
                'mr-2 size-4 flex-shrink-0 self-center',
                title && 'self-start',
                iconClassName
              )}
              style={{
                color: 'currentcolor',
              }}
            />
          )}
          <div className="flex flex-col items-start">
            {title && (
              <ShadcnAlert.AlertTitle className={titleClassName}>
                {title}
              </ShadcnAlert.AlertTitle>
            )}
            <ShadcnAlert.AlertDescription className={descriptionClassName}>
              {description}
            </ShadcnAlert.AlertDescription>
          </div>
        </div>
        {action && (
          <div className="action-container flex gap-2 border-inherit md:ml-auto [&>a]:h-[30px] [&>a]:text-xs [&>button]:h-[30px] [&>button]:text-xs">
            {action}
            {dismissable && (
              <Button variant="link" className="text-xs" onClick={onDismiss}>
                Dismiss
              </Button>
            )}
          </div>
        )}
      </ShadcnAlert.Alert>
    );
  }
);

export default Alert;
