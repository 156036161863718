import { Users } from 'lucide-react';

type TimeSavedBreakdownItemProps = {
  category: string;
  applicantCount: number;
  timeSaved: string;
};

export const TimeSavedBreakdownItem = ({
  category,
  applicantCount,
  timeSaved,
}: TimeSavedBreakdownItemProps) => {
  return (
    <div className="flex items-start gap-4">
      <div className="flex-1 space-y-1">
        <p className="text-sm font-medium leading-none">{category}</p>
        <div className="flex items-center gap-1 text-muted-foreground">
          <Users className="size-4" />
          <span className="text-sm">
            {applicantCount} applicant{applicantCount > 1 ? 's' : ''}
          </span>
        </div>
      </div>
      <span className="text-sm font-medium">{timeSaved || '-'}</span>
    </div>
  );
};
