import { PositionCommunicationFormValues } from '@/lib/usePositionCommunicationForm';
import {
  afterApplicationSubjectGerman,
  disqualifiedEmailGerman,
  interviewCompletedEmailGerman,
  applicationEmailGerman,
  disqualifiedSubjectGerman,
  afterInterviewSubjectGerman,
} from './de';
import {
  afterApplicationSubjectEnglish,
  afterInterviewSubjectEnglish,
  applicationEmailEnglish,
  disqualifiedEmailEnglish,
  disqualifiedSubjectEnglish,
  interviewCompletedEmailEnglish,
} from './en';
import {
  afterApplicationSubjectSpanish,
  afterInterviewSubjectSpanish,
  applicationEmailSpanish,
  disqualifiedEmailSpanish,
  disqualifiedSubjectSpanish,
  interviewCompletedEmailSpanish,
} from './es';
import {
  afterApplicationSubjectFrench,
  afterInterviewSubjectFrench,
  applicationEmailFrench,
  disqualifiedEmailFrench,
  disqualifiedSubjectFrench,
  interviewCompletedEmailFrench,
} from './fr';
import {
  afterApplicationSubjectDutch,
  afterInterviewSubjectDutch,
  applicationEmailDutch,
  disqualifiedEmailDutch,
  disqualifiedSubjectDutch,
  interviewCompletedEmailDutch,
} from './nl';
import {
  afterApplicationSubjectTurkish,
  afterInterviewSubjectTurkish,
  applicationEmailTurkish,
  disqualifiedEmailTurkish,
  disqualifiedSubjectTurkish,
  interviewCompletedEmailTurkish,
} from './tr';
import { Language } from '@/i18n';

export const templatesByLocale: Record<
  Language,
  Pick<
    PositionCommunicationFormValues,
    | 'afterApplicationSubject'
    | 'afterInterviewSubject'
    | 'disqualifiedSubject'
    | 'afterApplication'
    | 'afterInterview'
    | 'disqualified'
  >
> = {
  [Language.DE]: {
    afterApplicationSubject: afterApplicationSubjectGerman,
    afterInterviewSubject: afterInterviewSubjectGerman,
    disqualifiedSubject: disqualifiedSubjectGerman,
    afterApplication: applicationEmailGerman,
    afterInterview: interviewCompletedEmailGerman,
    disqualified: disqualifiedEmailGerman,
  },
  [Language.EN_US]: {
    afterApplicationSubject: afterApplicationSubjectEnglish,
    afterInterviewSubject: afterInterviewSubjectEnglish,
    disqualifiedSubject: disqualifiedSubjectEnglish,
    afterApplication: applicationEmailEnglish,
    afterInterview: interviewCompletedEmailEnglish,
    disqualified: disqualifiedEmailEnglish,
  },
  [Language.ES]: {
    afterApplicationSubject: afterApplicationSubjectSpanish,
    afterInterviewSubject: afterInterviewSubjectSpanish,
    disqualifiedSubject: disqualifiedSubjectSpanish,
    afterApplication: applicationEmailSpanish,
    afterInterview: interviewCompletedEmailSpanish,
    disqualified: disqualifiedEmailSpanish,
  },
  [Language.FR]: {
    afterApplicationSubject: afterApplicationSubjectFrench,
    afterInterviewSubject: afterInterviewSubjectFrench,
    disqualifiedSubject: disqualifiedSubjectFrench,
    afterApplication: applicationEmailFrench,
    afterInterview: interviewCompletedEmailFrench,
    disqualified: disqualifiedEmailFrench,
  },
  [Language.NL]: {
    afterApplicationSubject: afterApplicationSubjectDutch,
    afterInterviewSubject: afterInterviewSubjectDutch,
    disqualifiedSubject: disqualifiedSubjectDutch,
    afterApplication: applicationEmailDutch,
    afterInterview: interviewCompletedEmailDutch,
    disqualified: disqualifiedEmailDutch,
  },
  [Language.TR]: {
    afterApplicationSubject: afterApplicationSubjectTurkish,
    afterInterviewSubject: afterInterviewSubjectTurkish,
    disqualifiedSubject: disqualifiedSubjectTurkish,
    afterApplication: applicationEmailTurkish,
    afterInterview: interviewCompletedEmailTurkish,
    disqualified: disqualifiedEmailTurkish,
  },
};
