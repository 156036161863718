import { CreateParentOrganizationForm } from '@/components/core/parent-organization/create-parent-organization-form';
import { createRoute } from '@tanstack/react-router';
import { Route as adminPanelRoute } from './admin-panel';

export const Route = createRoute({
  path: '/create-organization',
  getParentRoute: () => adminPanelRoute,
  component: () => <AdminCreateOrganizationPage />,
});

function AdminCreateOrganizationPage() {
  return (
    <div>
      <CreateParentOrganizationForm />
    </div>
  );
}
