import { useFormContext } from 'react-hook-form';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Separator } from '@/components/ui/separator';

import { PositionApplicationFormValues } from '@/lib/usePositionApplicationForm';
import { Loader } from '../../loader';

export function PositionApplicationForm() {
  const { control, watch } = useFormContext<PositionApplicationFormValues>();

  const isInitialized = watch('isInitialized');

  if (!isInitialized) {
    return <Loader className="static h-[50vh] bg-transparent" />;
  }

  return (
    <>
      <h2 className="text-xl font-bold">Personal Information</h2>
      <FormField
        control={control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Email Address</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="phone"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Phone Number</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="address"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Physical Address</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                    <TabsTrigger value="optional">Optional</TabsTrigger>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="linkedinProfile"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Linkedin Profile URL</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                    <TabsTrigger value="optional">Optional</TabsTrigger>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />

      <Separator />

      <h2 className="text-xl font-bold">Experience</h2>
      <FormField
        control={control}
        name="resume"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Resume</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="education"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Education</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                    <TabsTrigger value="optional">Optional</TabsTrigger>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="coverLetter"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Cover Letter</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                    <TabsTrigger value="optional">Optional</TabsTrigger>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="desiredSalary"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Desired Salary</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                    <TabsTrigger value="optional">Optional</TabsTrigger>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="additionalInfo"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Additional Information</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="required">Required</TabsTrigger>
                    <TabsTrigger value="optional">Optional</TabsTrigger>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
