import { useSidebar } from '@/components/ui/sidebar';
import { ReactNode } from 'react';

const LayoutContainer = ({ children }: { children: ReactNode }) => {
  const { open, isMobile } = useSidebar();

  return (
    <div
      className={`flex flex-col p-4`}
      style={{
        width: isMobile ? '100vw' : `calc(100vw - ${open ? 256 : 48}px)`,
        height: isMobile ? 'calc(100vh - 48px)' : '100vh',
      }}
    >
      {children}
    </div>
  );
};

export default LayoutContainer;
