import { Markdown } from '@/components/core/common/Markdown';
import { Loader } from '@/components/core/loader';

import {
  ApplicationDetail,
  useGetApplication,
} from '@/fetchers/useApplication';
import {
  cn,
  getContrastColorFromBackgroundColor,
  parseSalaryToDisplay,
} from '@/lib/utils';
import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { useEffect, useRef } from 'react';
import { User2Icon } from 'lucide-react';
import generatePDF from 'react-to-pdf';
import logo from '@/assets/svg/logo-whitebg.svg';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useGetOrganizations } from '@/fetchers/useOrganization';
import { Route as RootRoute } from './__root';
import { Language } from '@/i18n';
import {
  interviewVerdictValues,
  resumeVerdictValues,
} from '@/components/core/applications/constants';

export const Route = createRoute({
  path: '/o/$organizationId/positions/print/$slug/$step/$id',
  getParentRoute: () => RootRoute,
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CandidateInfo,
});

export function CandidateInfo() {
  const { id } = Route.useParams();
  const { selectedOrganization } = useUserDetailStore();

  const { data: application, isFetching } = useGetApplication({
    applicationId: Number(id),
  });
  const interview = application?.interviews?.[0];

  const { isFetching: isFetcthingOrganizations } = useGetOrganizations({
    enabled: true,
  });
  // const navigate = useNavigate()
  const reportRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getApplicationStatus(application: any) {
    const interview = application?.interviews?.[0];

    if (interview?.finalVerdict) {
      return { status: 'completed', label: 'COMPLETED' };
    } else if (!interview?.finalVerdict && interview?.completedAt) {
      return { status: 'processesing', label: 'PROCESSING INTERVIEW' };
    } else if (!interview?.completedAt && interview?.startedAt) {
      return { status: 'in_progress', label: 'INTERVIEW IN PROGRESS' };
    } else if (interview && !interview?.startedAt) {
      return { status: 'interview_sent', label: 'INTERVIEW SENT' };
    } else if (!interview?.startedAt && application?.createdAt) {
      return { status: 'applied', label: 'APPLIED' };
    }
  }

  useEffect(() => {
    if (isFetching || isFetcthingOrganizations) return;
    if (!selectedOrganization) return;
    if (!selectedOrganization?.color) return;
    if (!selectedOrganization?.logoUrl) return;
    if (!selectedOrganization?.name) return;
    if (!application) return;

    // document.body.style.backgroundColor = selectedOrganization?.color || "#70e2ff"
    // document.body.style.color = getContrastColorFromBackgroundColor(selectedOrganization?.color || "#70e2ff")

    generatePDF(reportRef, {
      filename: 'page.pdf',
      page: {
        format: 'a4',
        orientation: 'portrait',
      },
    });

    setTimeout(() => {
      // navigate({
      //   to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
      //   params: {
      //     organizationId: `${selectedOrganization?.id || ""}`,
      //     // @ts-ignore
      //     slug: application?.position.slug,
      //     // @ts-ignore
      //     id: application?.id,
      //     step: "applications"
      //   }
      // })
      // close tab
      window.close();
    }, 1000);
  }, [isFetching, isFetcthingOrganizations, selectedOrganization, application]);

  if (isFetching || isFetcthingOrganizations) {
    return <Loader />;
  }

  const title = `${application?.position.title} position at ${selectedOrganization?.name}`;
  // const bgColor = selectedOrganization?.color || "#70e2ff"
  return (
    <>
      {/* <img src={image} alt="screenshot" /> */}

      <div
        ref={reportRef}
        className="min-h-[100vh] overflow-visible"
        style={
          {
            // background: bgColor,
            // color: getContrastColorFromBackgroundColor(bgColor)
          }
        }
      >
        <Header
          bgColor={selectedOrganization?.color || '#70e2ff'}
          logo={selectedOrganization?.logoUrl || ''}
          title={title}
        />

        <div className="h-full space-y-12 p-8">
          <div className="rounded-lg p-8 shadow-lg">
            <div className="mb-6 flex items-center justify-between">
              <div className="flex flex-col xl:flex-row xl:items-center">
                <p className="text-3xl font-bold">{application?.name}</p>
                <div className="ml-2 mt-1">
                  <Badge className="mt-6">
                    {getApplicationStatus(application)?.label}
                  </Badge>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {application?.createdAt && (
                  <p className="text-right text-sm text-gray-700">
                    Applied{' '}
                    {new Date(application?.createdAt).toLocaleDateString(
                      Language.EN_US,
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )}
                  </p>
                )}
              </div>
            </div>

            <Avatar className="size-[100px] text-4xl text-gray-400">
              {application?.userAvatar && (
                <AvatarImage src={application.userAvatar} alt="User Avatar" />
              )}
              <AvatarFallback>
                <User2Icon className="size-12" />{' '}
              </AvatarFallback>
            </Avatar>

            <InformationTable application={application} />
          </div>

          {interview?.analysisSummary && (
            <div className="rounded-lg p-8 shadow-lg">
              <div className="mb-6 flex items-center justify-between">
                <h2 className="text-2xl font-bold tracking-widest">
                  Interview Summary
                </h2>

                {interview?.finalVerdict && (
                  <Badge className="uppercase">
                    {interviewVerdictValues[interview?.finalVerdict]}
                  </Badge>
                )}
              </div>
              <p>{interview?.analysisSummary}</p>
            </div>
          )}

          {application?.resumeAnalysis && (
            <div className="rounded-lg p-8 shadow-lg">
              <div className="mb-6 flex items-center justify-between">
                <h2 className="text-2xl font-bold tracking-widest">
                  Resume Analysis
                </h2>
                <Badge className="uppercase">
                  {resumeVerdictValues[application?.resumeAnalysisVerdict]}
                </Badge>
              </div>
              <p>{application?.resumeAnalysis}</p>
            </div>
          )}

          {interview?.analysis && (
            <div
              className="flex h-full flex-col rounded-lg p-8 shadow-lg"
              style={{
                height: 'fit-content',
              }}
            >
              <h2 className="mb-4 text-2xl font-bold tracking-widest">
                Interview Analysis
              </h2>
              <Markdown content={interview?.analysis} />
            </div>
          )}

          <div className="rounded-lg p-8 shadow-lg">
            {/* <h2 className='text-2xl font-bold mb-4'>Additional Questions</h2> */}
            {interview?.additionalQuestionsAndAnswers && (
              <div className="prose">
                {interview?.additionalQuestionsAndAnswers.map(
                  (question, idx) => (
                    <div key={idx} className="mb-4">
                      <h3 className="text-lg font-bold tracking-widest">
                        {question.question}
                      </h3>
                      <p>{question.answer}</p>
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          <div className="z-10 mb-4 mt-6 block w-full">
            <a
              href="https://veton.ai"
              target="_blank"
              className="sticky bottom-0 left-0 flex h-[60px] w-full items-center justify-center border-t border-gray-100 p-4 text-lg"
            >
              Powered by{' '}
              <img
                alt="powered by veton.ai"
                src={logo}
                style={{ height: 25 }}
                className="ml-1 mt-4"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

function Badge({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'relative flex h-[26px] items-center justify-center rounded-full border border-black px-2',
        className
      )}
    >
      <div className="pb-4">{children}</div>
    </div>
  );
}

function InformationTable({
  application,
}: {
  application?: ApplicationDetail;
}) {
  if (!application) return null;
  return (
    <div className="mt-8 w-full space-y-8 break-all">
      {application?.email && (
        <div className="grid grid-cols-2">
          <p>
            <span className="mr-4 font-semibold">Email</span>
          </p>
          <p>{handleUndefinedValue(application?.email)}</p>
        </div>
      )}

      {application?.phone && (
        <div className="grid grid-cols-2">
          <p>
            <span className="mr-4 font-semibold">Phone</span>
          </p>
          <p>{handleUndefinedValue(application?.phone)}</p>
        </div>
      )}

      {application?.linkedinProfile && (
        <div className="grid grid-cols-2">
          <p>
            <span className="mr-4 font-semibold">Linkedin Profile</span>
          </p>
          <p>{handleUndefinedValue(application?.linkedinProfile)}</p>
        </div>
      )}

      {application?.additionalInfo && (
        <div className="grid grid-cols-2">
          <p>
            <span className="mr-4 font-semibold">Additional Information</span>
          </p>
          <p
            style={{
              wordBreak: 'break-word',
            }}
          >
            {handleUndefinedValue(application?.additionalInfo)}
          </p>
        </div>
      )}

      {application?.desiredSalary && application?.salaryCurrency && (
        <div className="grid grid-cols-2">
          <p>
            <span className="mr-4 font-semibold">Desired Salary</span>
          </p>
          {application?.desiredSalary === 'undefined' ? (
            <p>Blank</p>
          ) : (
            <p>
              {parseSalaryToDisplay(
                Number(application?.desiredSalary),
                application?.salaryCurrency
              )}{' '}
              / {application?.salaryType}
            </p>
          )}
        </div>
      )}

      {application?.education && (
        <div className="grid grid-cols-2">
          <p>
            <span className="mr-4 font-semibold">Education</span>
          </p>
          <p>{handleUndefinedValue(application?.education)}</p>
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleUndefinedValue(value: any) {
  if (value === 'undefined') {
    return 'Blank';
  } else {
    return value;
  }
}

function Header({
  logo,
  title,
  bgColor = '#70e2ff',
}: {
  bgColor: string;
  logo: string;
  title: string;
}) {
  const color = getContrastColorFromBackgroundColor(bgColor);
  return (
    <div
      className="sticky top-0 z-10 mt-6 block w-full py-4 tracking-widest"
      style={{
        background: bgColor,
        color: color,
      }}
    >
      <div className="container flex w-full flex-col justify-between py-2 md:flex-row">
        <div className="flex items-center justify-center">
          <div className="overflow-hidden rounded rounded-xl">
            <img
              src={logo}
              // className="object-fit"
              alt="Company Logo"
              style={{
                // width: "60px",
                height: '80px',
                // objectFit: "fill",
                aspectRatio: 1,
              }}
            />
          </div>

          <div className="ml-4 flex flex-col items-center justify-center">
            <p className="pb-4 text-2xl font-bold">{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
