import { Button } from '@/components/ui/button';

import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { RequiredStar } from '../common/RequiredStar';
import { useState } from 'react';
import { useParams } from '@tanstack/react-router';
import {
  ATSConnectionAuthMethod,
  ATSConnectionId,
} from '@/components/core/ats/types';

export function ZohoRecruitConnection() {
  const { organizationId } = useParams({ strict: false });
  const [region, setRegion] = useState('com');

  return (
    <>
      <div className="w-full space-y-4">
        <div>
          <Label htmlFor="datacenter">
            Datacenter Region
            <RequiredStar />
          </Label>
          <Select defaultValue={region} onValueChange={(val) => setRegion(val)}>
            <SelectTrigger>
              <SelectValue placeholder="Select your datacenter" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="com">
                <span className="font-medium">US</span>{' '}
                <span className="text-muted-foreground">(.com)</span>
              </SelectItem>
              <SelectItem value="eu">
                <span className="font-medium">Europe</span>{' '}
                <span className="text-muted-foreground">(.eu)</span>
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="self-end">
        <Button
          onClick={() => {
            const redirectUri = `${window.location.origin}/organizations`;

            const state = encodeURIComponent(
              JSON.stringify({
                organizationId,
                integrationType: ATSConnectionId.ZOHO_RECRUIT,
                authMethod: ATSConnectionAuthMethod.OAUTH2,
                redirectUri,
              })
            );

            const url = `https://accounts.zoho.${region}/oauth/v2/auth?scope=ZohoRecruit.modules.READ,ZohoRecruit.modules.CREATE,ZohoRecruit.modules.UPDATE,ZohoRecruit.settings.ALL&client_id=1000.8WA9R94QIT2GYNZ0BUII1W2SS547ED&response_type=code&access_type=offline&redirect_uri=${redirectUri}&state=${state}`;
            window.open(url, '_blank');
          }}
          className="w-full"
        >
          Connect
        </Button>
      </div>
    </>
  );
}
