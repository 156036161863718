import {
  FinalVerdict,
  ResumeAnalysisVerdict,
} from '@/components/core/applications/types';
import { ApplicationFormValues } from '@/components/core/careers/single/application-form';
import { useUpdateApplicationsCache } from '@/hooks/useUpdateApplicationsCache';
import { fetcher } from '@/lib/fetcher';
import {
  convertToCamelCase,
  convertToSnakeCase,
  handleQueryError,
  parseAPIError,
} from '@/lib/utils';
import { RouterParams } from '@/main';
import { useAuth } from '@clerk/clerk-react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { toast } from 'sonner';
import { PhoneLineType } from './types';

export type TApplication = {
  name: string;
  email: string;
  phone: string;
  address?: string | null;
  linkedinProfile?: string | null;
  desiredSalary?: string | null;
  education?: string | null;
  additionalInfo?: string | null;
  resume: File;
  coverLetter?: File | null;
  archived?: boolean;
};

export function useApplyMutation() {
  const mutation = useMutation({
    mutationFn: async ({
      data,
      slug,
    }: {
      data: Partial<ApplicationFormValues>;
      slug: string;
    }) => {
      const formData = new FormData();
      const { resume, coverLetter, ...rest } = data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const snakePayload: any = convertToSnakeCase(rest);

      if (resume) {
        formData.append('resume', resume);
      }
      if (coverLetter) {
        formData.append('cover_letter', coverLetter);
      }

      Object.keys(snakePayload).forEach((key) => {
        formData.append(key, snakePayload[key]);
      });

      return await fetcher
        .post(`/applications/positions/${slug}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => ({
          data: convertToCamelCase(r.data) as unknown as TApplication,
          error: null,
        }))
        .catch((e) => {
          toast.error('Something went wrong!', {
            description: parseAPIError(e),
            position: 'top-right',
          });
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
  });

  return mutation.mutateAsync;
}

export type ApplicantInterview = {
  id: number;
  finalVerdict: FinalVerdict | null;
  assistantType: string;
  createdAt: string;
  startedAt: string | null;
  completedAt: string | null;
  analysisSummary: string | null;
  lastReminderSentAt: string | null;
  reminderCount: number;
};

export type Application = {
  id: number;
  name: string;
  email: string;
  phone: string;
  phoneIsValid: boolean;
  phoneLineType: PhoneLineType | null;
  interviews: ApplicantInterview[];
  user: {
    id: string;
    username: string;
  };
  resumeAnalysisVerdict: ResumeAnalysisVerdict;
  resumeAnalysis: string | null;
  archived: boolean;
  shortlisted: boolean;
};

export function useGetApplications({ slug }: { slug: string }) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['applications', 'admin', slug],
    queryFn: async () => {
      return await fetcher
        .get(`/admin/applications/positions/${slug}`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((r) => convertToCamelCase(r.data) as unknown as Application[]);
    },
    enabled: !!slug,
  });

  return query;
}

export function useUpdateApplicationMutation() {
  const { getToken } = useAuth();
  const updateApplicationsCache = useUpdateApplicationsCache();
  const mutation = useMutation({
    onSuccess: (application) => {
      updateApplicationsCache(application.id, application);
    },
    mutationFn: async ({
      data,
      applicationId,
    }: {
      data: { archived?: boolean; deleted?: boolean };
      applicationId: number;
    }) => {
      return await fetcher
        .patch(
          `/admin/applications/${applicationId}`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data) as unknown as Application)
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to update application');
        });
    },
  });

  return mutation.mutateAsync;
}

export type ApplicationDetailInterview = ApplicantInterview & {
  analysis: string | null;
  audioRecordingUrl: string | null;
  videoRecordingUrl: string | null;
  interviewUrl: string | null;
  additionalQuestionsAndAnswers?: {
    question: string;
    answer: string;
  }[];
  analysisSummary: string | null;
};
export type ApplicationDetail = {
  id: number;
  name: string;
  email: string;
  phone: string;
  phoneIsValid: boolean;
  phoneLineType: PhoneLineType;
  linkedinProfile: string | null;
  desiredSalary: string | null;
  education: string | null;
  additionalInfo: string | null;
  createdAt: string;
  resumeUrl: string;
  coverLetterUrl: string | null;
  salaryType: string | null;
  salaryCurrency: string | null;
  interviews: ApplicationDetailInterview[];
  userAvatar: string | null;
  resumeAnalysis: string | null;
  resumeAnalysisVerdict: ResumeAnalysisVerdict;
  position: {
    slug: number;
    title: string;
  };
};

export function useGetApplication({
  applicationId,
}: {
  applicationId: number;
}) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['application', 'admin', applicationId],
    queryFn: async () => {
      return await fetcher
        .get(`/admin/applications/${applicationId}`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then(
          (r) => convertToCamelCase(r.data) as unknown as ApplicationDetail
        );
    },
    enabled: !!applicationId,
  });

  return query;
}

export function useGetApplicationMessages({
  applicationId,
  enabled,
}: {
  applicationId: number;
  enabled?: boolean;
}) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['application', 'admin', 'messages', applicationId],
    queryFn: async () => {
      return await fetcher
        .get(`/admin/applications/${applicationId}/messages`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then(
          (r) =>
            convertToCamelCase(r.data) as unknown as {
              id: string;
              content: string;
              role: string;
              createdAt: string;
            }[]
        );
    },
    enabled: !!applicationId && !!enabled,
  });

  return query;
}

export function useGetMessageResponseRecording({
  messageId,
  applicationId,
}: {
  messageId: string;
  applicationId: number;
}) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['message', 'admin', 'recording', applicationId, messageId],
    queryFn: async () => {
      return await fetcher
        .get(
          `/admin/applications/${applicationId}/messages/${messageId}/recording`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data) as unknown as { url: string })
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to get recording');
        });
    },
    enabled: !!messageId,
  });

  return query;
}

export function useSendReminderMutation() {
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({
      positionSlug,
      candidates,
    }: {
      positionSlug: string;
      candidates: number[];
    }) => {
      return await fetcher
        .post(
          `/admin/applications/interviews/positions/${positionSlug}/remind`,
          { candidates },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(
          (r) => convertToCamelCase(r.data) as unknown as { message: string }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to send reminder');
        });
    },
  });

  return mutation.mutateAsync;
}

export function useGetWebGeneralApplication({
  enabled,
  storyOptions,
}: {
  enabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storyOptions?: any;
}) {
  const query = useQuery({
    queryKey: ['web', 'general', 'application', JSON.stringify(storyOptions)],
    queryFn: async () => {
      return await fetcher
        .get(
          `/applications/interviews/general_application/web?story_options=${JSON.stringify(storyOptions)}`,
          {}
        )
        .then(
          (r) =>
            convertToCamelCase(r.data) as unknown as {
              assistantType: string;
              openaiAssistantId: string;
              openaiThreadId: string;
              voiceModel: string;
            }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to get recording');
        });
    },
    enabled: !!enabled,
  });

  return query;
}

export function useSendBulkInterviewMutation() {
  const { getToken } = useAuth();

  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const updateApplicationsCache = useUpdateApplicationsCache();
  const mutation = useMutation({
    onMutate: ({ applicationIds }) => {
      applicationIds.forEach((applicationId) => {
        updateApplicationsCache(applicationId, {
          // create a temporary interview to show the interview in the "Interview Sent" pipeline
          interviews: [{ id: 0 } as unknown as ApplicantInterview],
        });
      });
      toast.success('Interview successfully sent to selected applicants');
    },
    mutationFn: async ({ applicationIds }: { applicationIds: number[] }) => {
      return await fetcher
        .post(
          `/admin/applications/bulk/interviews`,
          {
            application_ids: applicationIds,
            organization_id: Number(organizationId),
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(
          (r) => convertToCamelCase(r.data) as unknown as { message: string }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to send interview');
        });
    },
  });

  return mutation.mutateAsync;
}

export function useSendInterviewMutation() {
  const { getToken } = useAuth();
  const updateApplicationsCache = useUpdateApplicationsCache();
  const mutation = useMutation({
    onSuccess: (interview, { applicationId }) => {
      // update the application with the actual interview
      updateApplicationsCache(applicationId, {
        interviews: [interview],
      });
    },
    onError: (_, { applicationId }) => {
      toast.error('Failed to send interview');
      // put the application back to the original state
      updateApplicationsCache(applicationId, {
        interviews: [],
      });
    },
    onMutate: ({ applicationId }) => {
      // create a temporary interview to show the interview in the "Interview Sent" pipeline
      updateApplicationsCache(applicationId, {
        interviews: [{ id: 1 } as ApplicantInterview],
      });
    },
    mutationFn: async ({ applicationId }: { applicationId: number }) => {
      return await fetcher
        .post<ApplicantInterview>(
          `/admin/applications/${applicationId}/interviews`,
          {},
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(({ data }) => {
          return convertToCamelCase(data) as ApplicantInterview;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to send reminder');
        });
    },
  });

  return mutation.mutateAsync;
}

export function useInviteCandidate() {
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({
      email,
      message,
      subject,
      organizationId,
      positionSlug,
    }: {
      email: string;
      message?: string;
      subject: string;
      organizationId: number;
      positionSlug: string;
    }) => {
      return fetcher
        .post(
          `/admin/applications/invite`,
          {
            email,
            message,
            subject,
            organization_id: organizationId,
            position_slug: positionSlug,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(() => {
          return 'ok';
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useBulkUploadApplicants({ slug }: { slug: string }) {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['applications', 'admin', slug],
      });
    },
    mutationFn: async ({
      file,
      slug,
      organizationId,
    }: {
      file: File;
      slug: string;
      organizationId: string;
    }) => {
      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      }

      return await fetcher
        .post(
          `/admin/applications/positions/${slug}/bulk-upload?organization_id=${organizationId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(() => {
          toast('Applicants uploaded successfully');
        })
        .catch((e) => {
          toast.error('Something went wrong!', {
            description: parseAPIError(e),
            position: 'top-right',
          });
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
  });

  return mutation.mutateAsync;
}

export function useBulkUploadResume() {
  const { getToken } = useAuth();
  // const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: () => {
      // queryClient.invalidateQueries({
      //   queryKey: ["applications", "admin", slug],
      // });
    },
    mutationFn: async ({
      files,
      slug,
      organizationId,
    }: {
      files: FileList;
      slug: string;
      organizationId: string;
    }) => {
      const formData = new FormData();

      if (files) {
        Array.from(files).forEach((file) => {
          formData.append('files', file);
        });
      }

      return await fetcher
        .post(
          `/admin/applications/positions/${slug}/bulk-upload-resumes?organization_id=${organizationId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        // .then(() => {
        //   toast("Applicants uploaded successfully")
        // })
        .catch((e) => {
          toast.error('Something went wrong!', {
            description: parseAPIError(e),
            position: 'top-right',
          });
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
  });

  return mutation.mutateAsync;
}

export function useBulkDisqualifyMutation() {
  const { getToken } = useAuth();

  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const updateApplicationsCache = useUpdateApplicationsCache();
  const mutation = useMutation({
    onMutate: ({ applicationIds }) => {
      applicationIds.forEach((applicationId) => {
        updateApplicationsCache(applicationId, {
          interviews: [
            {
              finalVerdict: FinalVerdict.DISQUALIFIED,
            } as unknown as ApplicantInterview,
          ],
        });
      });
      toast.success('Selected applicants disqualified successfully');
    },
    mutationFn: async ({ applicationIds }: { applicationIds: number[] }) => {
      return await fetcher
        .post(
          `/admin/applications/bulk/disqualify`,
          {
            application_ids: applicationIds,
            organization_id: Number(organizationId),
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(
          (r) => convertToCamelCase(r.data) as unknown as { message: string }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to disqualify applicants');
        });
    },
  });

  return mutation.mutateAsync;
}

export function useBulkShortlistMutation() {
  const { getToken } = useAuth();

  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const updateApplicationsCache = useUpdateApplicationsCache();
  const mutation = useMutation({
    onMutate: ({ applicationIds, shortlisted }) => {
      applicationIds.forEach((applicationId) => {
        updateApplicationsCache(applicationId, {
          shortlisted,
        });
      });
      toast.success(
        `Selected applicants are ${shortlisted ? 'shortlisted' : 'reverted'}`
      );
    },
    onError: (_, { applicationIds, shortlisted }) => {
      applicationIds.forEach((applicationId) => {
        updateApplicationsCache(applicationId, {
          shortlisted: !shortlisted,
        });
      });
    },
    mutationFn: async ({
      applicationIds,
      shortlisted,
    }: {
      applicationIds: number[];
      shortlisted: boolean;
    }) => {
      return await fetcher
        .post(
          `/admin/applications/bulk/shortlist`,
          {
            application_ids: applicationIds,
            organization_id: Number(organizationId),
            shortlisted,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(
          (r) => convertToCamelCase(r.data) as unknown as { message: string }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to shortlist applicants');
        });
    },
  });

  return mutation.mutateAsync;
}
