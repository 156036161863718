import { MessageCircle, Star, Clock } from 'lucide-react';

import { Card, CardHeader, CardContent } from '@/components/ui/card';

import { useTranslation } from 'react-i18next';
import { Disclaimer } from '../interview/disclaimer';

export function InterviewGuideline() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });

  return (
    <div className="mx-auto max-w-2xl p-4">
      <Card className="mb-4">
        <CardHeader>
          <h2 className="text-center text-2xl font-bold">
            {t('guideline_title')}
          </h2>
        </CardHeader>
        <CardContent>
          <p className="mb-4 text-center">{t('guideline_description')}</p>
          <div className="flex justify-around text-center">
            <div>
              <MessageCircle size={32} className="mx-auto mb-2 text-blue-500" />
              <p className="text-sm">{t('share_your_story')}</p>
            </div>
            <div>
              <Star size={32} className="mx-auto mb-2 text-yellow-500" />
              <p className="text-sm">{t('highlight_your_skills')}</p>
            </div>
            <div>
              <Clock size={32} className="mx-auto mb-2 text-green-500" />
              <p className="text-sm">{t('take_your_time')}</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <Disclaimer buttonText={t('call_me_now')} />
    </div>
  );
}
