import { RouterParams } from '@/main';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { Application } from '@/fetchers/useApplication';

export const useUpdateApplicationsCache = () => {
  const queryClient = useQueryClient();
  const { slug } = useParams({ strict: false }) as RouterParams;

  return (applicationId: number, newApplicationData: Partial<Application>) => {
    queryClient.setQueryData(
      ['applications', 'admin', slug],
      (prevApplications: Application[]) => {
        return prevApplications.map((application) => {
          if (application.id !== applicationId) return application;
          return {
            ...application,
            ...newApplicationData,
          };
        });
      }
    );
  };
};
