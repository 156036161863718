// import UploadImage from "~/assets/images/upload.svg";

import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { FileText, FileUpIcon, Loader2 } from 'lucide-react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export const MIME_TYPE = {
  PDF: 'application/pdf',
  IMG: 'image/*',
  DOC: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TEXT: 'text/plain',
  ALL: '*',
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

type TProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mimeType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: File | string | any;
  name?: string;
  buttonOnly?: boolean;
  buttonOnlyText?: string;
  isLoading?: boolean;
  multiple?: boolean;
  className?: string;
  iconClassName?: string;
};
export function UploadInput({
  onChange,
  mimeType = MIME_TYPE.ALL,
  defaultValue,
  name = 'file',
  buttonOnly = false,
  buttonOnlyText = 'Upload a file',
  multiple = false,
  className = '',
  iconClassName = '',
  isLoading = false,
}: Readonly<TProps>) {
  const hasImage = mimeType === MIME_TYPE.IMG && defaultValue;
  const imageUrl =
    defaultValue instanceof File
      ? URL.createObjectURL(defaultValue)
      : defaultValue;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    onChange({
      target: {
        files: acceptedFiles,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  let Icon = FileText;
  if (mimeType === MIME_TYPE.IMG) {
    Icon = PhotoIcon;
  }

  if (buttonOnly) {
    return (
      <label
        htmlFor={`${name}-upload`}
        className={cn(
          buttonVariants({
            size: 'lg',
          }),
          'text-md cursor-pointer',
          className
        )}
      >
        <div className="flex items-center space-x-2">
          {isLoading ? (
            <Loader2 className="size-5 animate-spin" />
          ) : (
            <FileUpIcon className={cn('size-5', iconClassName)} />
          )}
          <span>
            {defaultValue?.name ? 'Upload another file' : buttonOnlyText}
          </span>
        </div>
        <input
          disabled={isLoading}
          id={`${name}-upload`}
          name={`${name}-upload`}
          accept={mimeType}
          onChange={onChange}
          type="file"
          className="sr-only"
          multiple={multiple}
        />
      </label>
    );
  }

  return (
    <div className="relative w-full" {...getRootProps()}>
      <input {...getInputProps()} />

      {hasImage && (
        <div className="absolute inset-0 z-0 h-full w-full overflow-hidden">
          <img className="z-0 h-full w-full object-cover" src={imageUrl} />
        </div>
      )}

      <div
        className={cn(
          'relative z-20 mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10',
          hasImage && 'bg-black/50 opacity-0 hover:opacity-100'
        )}
      >
        <div className="text-center">
          <Icon
            className={cn(
              'mx-auto size-12',
              defaultValue ? 'text-green-700/75' : 'text-gray-300'
            )}
          />
          <p className={'text-green-700/75'}>{defaultValue?.name}</p>
          <div
            className={cn(
              'mt-4 flex items-center text-sm leading-6',
              hasImage ? 'text-gray-200' : 'text-gray-600'
            )}
          >
            <label
              htmlFor={`${name}-upload`}
              className="relative cursor-pointer rounded-md bg-white p-1 font-semibold text-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2 hover:text-gray-500"
            >
              <span>
                {defaultValue?.name
                  ? 'Upload another file'
                  : multiple
                    ? 'Upload files'
                    : 'Upload a file'}
              </span>

              <input
                id={`${name}-upload`}
                name={`${name}-upload`}
                accept={mimeType}
                onChange={onChange}
                type="file"
                disabled={isLoading}
                className="sr-only"
                multiple={multiple}
              />
            </label>
            {!defaultValue?.name && <p className="pl-1">or drag and drop</p>}
          </div>
          {!defaultValue?.name && (
            <p
              className={cn(
                'text-xs leading-5',
                hasImage ? 'text-gray-200' : 'text-gray-600'
              )}
            >
              up to 10MB
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
