import StickyActionBarComponent from '@/components/core/sticky-action-bar';
import { TPosition } from '@/fetchers/usePosition';
import { getIsPositionDraft } from '@/lib/getIsPositionDraft';
import { RouterParams } from '@/main';
import { useIsMutating } from '@tanstack/react-query';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import useOpenPosition from './useOpenPosition';
import { Button } from '@/components/ui/button';
import { useCallback, useMemo } from 'react';
import { getIsPositionClosed } from '@/lib/getIsPositionClosed';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';

type Options = {
  isFormDirty?: boolean;
  position?: TPosition;
  isPublishDisabled?: boolean;
  publishDisabledReason?: string;
  onSave: () => Promise<void>;
};

export const usePositionManageStickyActionBar = ({
  isFormDirty,
  position,
  isPublishDisabled,
  publishDisabledReason,
  onSave,
}: Options) => {
  const { slug, organizationId } = useParams({
    strict: false,
  }) as RouterParams;
  const isMutating = useIsMutating();
  const handleOpenPosition = useOpenPosition();
  const { creating } = useSearch({ strict: false });
  const isPositionClosed = getIsPositionClosed(position);
  const isPositionDraft = getIsPositionDraft(position);
  const navigate = useNavigate();

  const handleOpenPositionClick = useCallback(async () => {
    if (isFormDirty) {
      await onSave();
    }
    await handleOpenPosition();
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: { organizationId, slug, step: 'applications' },
      ignoreBlocker: true,
    });
  }, [handleOpenPosition, isFormDirty, navigate, onSave, organizationId, slug]);

  const additionalActions: React.ReactNode[] = useMemo(() => {
    if (creating) return [];

    if (isPositionDraft) {
      const button = (
        <Button
          variant="outline"
          onClick={handleOpenPositionClick}
          disabled={isMutating > 0 || isPublishDisabled}
          type="button"
        >
          Publish
        </Button>
      );

      if (isPublishDisabled && !!publishDisabledReason) {
        return [
          <Tooltip>
            <TooltipTrigger asChild>{button}</TooltipTrigger>
            <TooltipContent>{publishDisabledReason}</TooltipContent>
          </Tooltip>,
        ];
      }
      return [button];
    }

    if (isPositionClosed) {
      return [
        <Button
          variant="secondary"
          onClick={handleOpenPositionClick}
          disabled={isMutating > 0}
          type="button"
        >
          Open position
        </Button>,
      ];
    }

    return [];
  }, [
    creating,
    handleOpenPositionClick,
    isMutating,
    isPositionClosed,
    isPositionDraft,
    isPublishDisabled,
    publishDisabledReason,
  ]);

  const StickyActionBar = useMemo(
    () => (
      <StickyActionBarComponent
        isSaveLoading={isMutating > 0}
        isSaveDisabled={isMutating > 0 || (!!slug && !creating && !isFormDirty)}
        saveButtonText={!!slug && !creating ? 'Save' : 'Next'}
        additionalActions={additionalActions}
      />
    ),
    [additionalActions, creating, isFormDirty, isMutating, slug]
  );

  return { StickyActionBar };
};
