import { Form } from '@/components/ui/form';
import { toast } from 'sonner';
import {
  TPosition,
  useApplicationForm,
  useUpdateApplicationFormMutation,
} from '@/fetchers/usePosition';
import { useNavigate, useParams } from '@tanstack/react-router';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import {
  usePositionApplicationForm,
  PositionApplicationFormValues,
} from '@/lib/usePositionApplicationForm';
import { PositionApplicationForm } from './application-form';
import { useEffect } from 'react';
import { RouterParams } from '@/main';
import { usePositionManageStickyActionBar } from '@/hooks/usePositionManageStickyActionBar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';

export function ApplicationFormContainer({
  position,
}: {
  position?: TPosition;
}) {
  const { organizationId, slug } = useParams({ strict: false }) as RouterParams;
  const updateApplicationForm = useUpdateApplicationFormMutation();
  const { creating } = usePositionManageRouteSearch();
  const navigate = useNavigate();
  const { data: positionApplicationFormData } = useApplicationForm({
    slug,
  });

  const form = usePositionApplicationForm({
    defaultValues: positionApplicationFormData,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (positionApplicationFormData) {
      reset({
        ...positionApplicationFormData,
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionApplicationFormData]);

  async function onSubmit(data: PositionApplicationFormValues) {
    const { error, data: updatedForm } = await updateApplicationForm({
      //@ts-ignore
      data,
      slug,
      organizationId,
    });
    if (error) {
      toast.error('Error updating application form');
      return;
    }
    toast.success('Position updated successfully!');

    if (updatedForm) {
      reset({
        ...updatedForm,
        isInitialized: true,
      });
    }

    if (creating) {
      navigate({
        to: '/organizations/$organizationId/positions/manage/$slug/$step',
        params: {
          organizationId,
          slug,
          step: 'communication',
        },
        search: {
          creating: true,
        },
        ignoreBlocker: true,
      });
    }
  }

  const { StickyActionBar } = usePositionManageStickyActionBar({
    isFormDirty: isDirty,
    position,
    onSave: handleSubmit(onSubmit),
  });

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <PositionApplicationForm />
          {isFormInitialized && StickyActionBar}
        </form>
      </Form>
    </>
  );
}
