import { createRoute } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { useAudioStream } from '@/lib/useAudioStream';
import { Route as RootRoute } from './__root';

export const Route = createRoute({
  path: '/audio-stream',
  getParentRoute: () => RootRoute,
  component: AudioStream,
});

function AudioStream(): JSX.Element {
  const { data, isStarted, setIsStarted } = useAudioStream({});

  return (
    <div className="flex flex-col items-center justify-center space-y-4 pt-20">
      <h2 className="text-2xl">General Application Sample Interview</h2>
      {isStarted ? (
        <p>Interviewing with {data?.voiceModel} voice model</p>
      ) : (
        <Button onClick={() => setIsStarted(true)}>Start Interview</Button>
      )}
      <audio controls className="hidden"></audio>
    </div>
  );
}
