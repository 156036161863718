import { useGetOrganizationCommunicationFormConfig } from '@/fetchers/useOrganization';
import { RouterParams } from '@/main';
import { useParams } from '@tanstack/react-router';

export function useOrgDefaultCommunicationLanguage() {
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const { data: communicationFormData, isFetching } =
    useGetOrganizationCommunicationFormConfig({
      organizationId,
    });
  return {
    orgDefaultCommunicationLanguage: communicationFormData?.language,
    isFetchingOrgDefaultCommunicationLanguage: isFetching,
  };
}
