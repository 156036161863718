import { Loader } from '@/components/core/loader';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import { createRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { Route as RootRoute } from './__root';

export const Route = createRoute({
  path: '/logout',
  getParentRoute: () => RootRoute,
  component: LogoutPage,
});

function LogoutPage() {
  const { auth } = Route.useRouteContext();
  const { closeChat } = useIntercomMessenger();

  useEffect(() => {
    auth.signOut();
    closeChat();
  }, []);

  return <Loader />;
}
