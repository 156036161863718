import { TooltipProvider } from '@/components/ui/tooltip';
import { SidebarProvider } from '@/components/ui/sidebar';
import { Sidebar } from './sidebar';
import { CustomSidebarTrigger } from './sidebar-trigger';
import LayoutContainer from './layout-container';
import { AccountSwitcher } from './account-switcher';
import {
  useGetMemberships,
  useOrganizationStore,
} from '@/fetchers/useOrganization';
import { useUserDetails } from '@/fetchers/useUserDetails';
import { UpdateParentOrganizationPopup } from '../parent-organization/update-parent-organization-popup';
import { GlobalAlert } from '../global-alert';
import { useBillingAlert } from '@/hooks/useBillingAlert';

interface TProps {
  children: React.ReactNode;
}

export function Layout({ children }: TProps) {
  useBillingAlert();
  const { selectedParentOrganization } = useOrganizationStore();
  const { data: userDetails, isFetching: isFetchingUserDetails } =
    useUserDetails();
  const { data: memberships } = useGetMemberships({
    enabled: !isFetchingUserDetails && !!userDetails?.id,
  });

  const accountSwitcher = (
    <>
      <AccountSwitcher organizations={memberships || []} />
      {selectedParentOrganization?.memberRole === 'admin' && (
        <UpdateParentOrganizationPopup />
      )}
    </>
  );

  return (
    <TooltipProvider delayDuration={0}>
      <div className="flex flex-col">
        <GlobalAlert />
        <div className="flex">
          <SidebarProvider>
            <Sidebar />
            <main className="relative flex flex-1 flex-col">
              <div className="flex items-center justify-between md:hidden">
                <CustomSidebarTrigger />
                <div className="flex items-center gap-2">{accountSwitcher}</div>
              </div>
              <div
                className={
                  'absolute right-4 top-4 hidden items-center gap-2 md:flex'
                }
              >
                {accountSwitcher}
              </div>
              <LayoutContainer>{children}</LayoutContainer>
            </main>
          </SidebarProvider>
        </div>
      </div>
    </TooltipProvider>
  );
}
