import AlertModal from '@/components/core/common/AlertModal';
import { useBlocker } from '@tanstack/react-router';

export const useUnsavedChangesAlertModal = ({
  show,
  onSave,
  onDiscard,
}: {
  show: boolean;
  onSave: () => Promise<void>;
  onDiscard?: () => void;
}) => {
  const { proceed, reset, status } = useBlocker({
    condition: show,
  });

  const Modal = (
    <AlertModal
      open={status === 'blocked'}
      title="Unsaved changes"
      content="You have unsaved changes on this page. Would you like to save before exiting?"
      confirmText="Save changes"
      cancelText="Discard changes"
      onClose={reset}
      onSecondaryActionClick={() => {
        onDiscard?.();
        proceed();
      }}
      onProceed={async () => {
        await onSave();
        reset();
      }}
    />
  );

  return {
    UnsavedChangesAlertModal: Modal,
  };
};
