import { Form } from '@/components/ui/form';
import { toast } from 'sonner';
import {
  TPosition,
  useAIConfigForm,
  useUpdateAIConfigFormMutation,
} from '@/fetchers/usePosition';
import { useNavigate, useParams } from '@tanstack/react-router';

import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import {
  handleMapAIConfigDataToFormValues,
  PositionAIConfigFormValues,
  usePositionAiConfigForm,
} from '@/lib/usePositionAiConfigForm';
import { PositionAIConfigForm } from './aiconfig-form';
import { useEffect } from 'react';
import { RouterParams } from '@/main';
import { usePositionManageStickyActionBar } from '@/hooks/usePositionManageStickyActionBar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';
import { usePositionCommunicationLanguage } from '@/hooks/usePositionCommunicationLanguage';

export function PositionAIConfigFormContainer({
  position,
}: {
  position?: TPosition;
}) {
  const { organizationId, slug } = useParams({ strict: false }) as RouterParams;
  const { creating } = usePositionManageRouteSearch();
  const navigate = useNavigate();
  const updateAiConfigFormMutation = useUpdateAIConfigFormMutation();
  const { data: positionAiConfigFormData } = useAIConfigForm({
    slug,
  });
  const {
    positionCommunicationLanguage,
    isFetchingPositionCommunicationLanguage,
  } = usePositionCommunicationLanguage();

  const form = usePositionAiConfigForm();
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (positionAiConfigFormData) {
      reset({
        ...handleMapAIConfigDataToFormValues(positionAiConfigFormData),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionAiConfigFormData]);

  async function onSubmit(data: PositionAIConfigFormValues) {
    const additionalInfo = data?.additionalQuestions
      ?.map((question) => question.value)
      .filter(Boolean);
    if (!slug) {
      return toast.error('Position is missing');
    }
    const hasAdditionalInfo = additionalInfo && additionalInfo.length > 0;
    const restrictQuestions =
      hasAdditionalInfo && data.restrictQuestions === 'enabled';
    const { error, data: updatedForm } = await updateAiConfigFormMutation({
      slug,
      organizationId,
      data: {
        aiVoiceModelId: data.aiVoiceModelId,
        additionalQuestions: additionalInfo,
        restrictQuestions,
      },
    });

    if (error) {
      toast.error('Error updating AI config form');
      return;
    }

    toast.success('Position updated successfully!');

    if (updatedForm) {
      reset({
        ...handleMapAIConfigDataToFormValues(updatedForm),
        isInitialized: true,
      });
    }

    if (creating) {
      navigate({
        to: '/organizations/$organizationId/positions/manage/$slug/$step',
        params: {
          organizationId,
          slug,
          step: 'hiring-team',
        },
        search: {
          creating: true,
        },
        ignoreBlocker: true,
      });
    }
  }

  const { StickyActionBar } = usePositionManageStickyActionBar({
    isFormDirty: isDirty,
    position,
    onSave: handleSubmit(onSubmit),
  });

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty && !creating,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <PositionAIConfigForm
            language={positionCommunicationLanguage}
            isFetchingLanguage={isFetchingPositionCommunicationLanguage}
          />
          {isFormInitialized && StickyActionBar}
        </form>
      </Form>
    </>
  );
}
