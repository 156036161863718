import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Link, ToPathOption, useParams } from '@tanstack/react-router';
import { Home } from 'lucide-react';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { RouterParams } from '@/main';

export type BreadcrumbItem = {
  title: string;
  href?: ToPathOption;
};

const BreadcrumbNav = ({ items = [] }: { items?: BreadcrumbItem[] }) => {
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const { selectedOrganization } = useUserDetailStore();

  return (
    <Breadcrumb className="mb-4">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to="/">
              <Home className="h-4 w-4" />
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {selectedOrganization && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              {items.length ? (
                <BreadcrumbLink asChild>
                  <Link
                    to={`/organizations/$organizationId/positions/list`}
                    params={{ organizationId }}
                  >
                    {selectedOrganization.name}
                  </Link>
                </BreadcrumbLink>
              ) : (
                <BreadcrumbPage>{selectedOrganization.name}</BreadcrumbPage>
              )}
            </BreadcrumbItem>
          </>
        )}
        {items.map((crumb, index) => (
          <React.Fragment key={crumb.title}>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              {index === items.length - 1 ? (
                <BreadcrumbPage className="capitalize">
                  {crumb.title}
                </BreadcrumbPage>
              ) : (
                <BreadcrumbLink asChild>
                  <Link
                    //@ts-ignore
                    to={crumb.href}
                    className="capitalize"
                  >
                    {crumb.title}
                  </Link>
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default BreadcrumbNav;
