import { AutoSendOption, InterviewDelay } from './types';

export const interviewDelaySelectOptions: Record<InterviewDelay, string> = {
  [InterviewDelay.NO_DELAY]: 'No Delay',
  [InterviewDelay.FIFTEEN_MINUTES]: '15 Minutes',
  [InterviewDelay.THIRTY_MINUTES]: '30 Minutes',
  [InterviewDelay.ONE_HOUR]: '1 Hour',
  [InterviewDelay.ONE_DAY]: '1 Day',
  [InterviewDelay.TWO_DAYS]: '2 Days',
  [InterviewDelay.THREE_DAYS]: '3 Days',
};

export const autoSendSelectOptions: Record<AutoSendOption, string> = {
  [AutoSendOption.SEND_ALL]: 'Send All',
  [AutoSendOption.GOOD_FIT]: 'Good Fits Only',
  [AutoSendOption.MAYBE_AND_UP]: 'Maybe and Up',
};
