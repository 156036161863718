import { Separator } from '@/components/ui/separator';
import { DescriptionForm } from './description-form';
import { TPosition } from '@/fetchers/usePosition';

export default function DescriptionFormView({
  position,
}: {
  position?: TPosition;
}) {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Position Description</h3>
        <p className="text-sm text-muted-foreground">
          A compelling job description is key to attract suitable applicants.
        </p>
      </div>
      <Separator />
      <DescriptionForm position={position} />
    </>
  );
}
