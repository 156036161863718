import { createRoute } from '@tanstack/react-router';
import { TeamSettings } from '@/components/core/team-settings';
import { Separator } from '@/components/ui/separator';
import { Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { InviteDialog } from '@/components/core/team-settings/InviteModal';
import { useUserDetails } from '@/fetchers/useUserDetails';
import {
  useGetMemberships,
  useOrganizationStore,
} from '@/fetchers/useOrganization';
import { ParentOgranizationCreationView } from '@/components/core/parent-organization';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { Loader } from '@/components/core/loader';
import { Layout } from '@/components/core/dashboard/layout';
import { Route as RootRoute } from './__root';

export const Route = createRoute({
  path: '/team-management',
  getParentRoute: () => RootRoute,
  component: TeamManagementComponent,
});

function TeamManagementComponent() {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: userDetails, isFetching: isFetchingUserDetails } =
    useUserDetails();
  const { data: memberships, isFetching: isFetchingMemberships } =
    useGetMemberships({
      enabled: !isFetchingUserDetails && !!userDetails?.id,
    });
  const { selectedParentOrganization } = useOrganizationStore();

  if (isFetchingMemberships || isFetchingUserDetails) {
    return <Loader />;
  }

  if (!memberships || memberships.length === 0) {
    return <ParentOgranizationCreationView />;
  }

  return (
    <Layout>
      <Breadcrumb
        items={[
          {
            title: 'Team Settings',
          },
        ]}
      />
      <div className="mx-auto h-screen w-screen max-w-5xl space-y-6 overflow-auto p-5 md:w-full md:p-10 lg:overflow-hidden lg:pb-16">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">Team Settings</h2>
          <p className="text-muted-foreground">
            Manage your team members and their roles.
          </p>
        </div>
        <Separator className="my-6" />

        <div className="flex items-center justify-between">
          {selectedParentOrganization?.memberRole === 'admin' && (
            <InviteDialog />
          )}

          <div className="relative w-full max-w-xs">
            <Search className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search"
              className="pl-8"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="pt-4">
          <TeamSettings searchTerm={searchTerm} />
        </div>
      </div>
    </Layout>
  );
}
