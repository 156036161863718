import { create } from 'zustand';

type DescriptionFormStore = {
  isValid: boolean;
  setIsValid: (isValid: boolean) => void;
};

export const useDescriptionFormStore = create<DescriptionFormStore>()(
  (set) => ({
    isValid: false,
    setIsValid: (isValid) => set({ isValid }),
  })
);
