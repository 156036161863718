import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { CheckboxProps } from '@radix-ui/react-checkbox';

const BulkSelectCheckbox = ({ className, ...props }: CheckboxProps) => {
  return (
    <Checkbox
      className={cn(
        'mr-1.5 border-foreground data-[state=checked]:border-primary',
        className
      )}
      {...props}
    />
  );
};

export default BulkSelectCheckbox;
