import {
  ParentOrganization,
  useOrganizationStore,
} from '@/fetchers/useOrganization';
import { useNavigate } from '@tanstack/react-router';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import {
  Select,
  SelectTrigger,
  SelectGroup,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';

type AccountSwitcherProps = {
  organizations: ParentOrganization[];
};

export function AccountSwitcher({ organizations }: AccountSwitcherProps) {
  const { selectedParentOrganization, setSelectedParentOrganization } =
    useOrganizationStore();

  const { setSelectedOrganization } = useUserDetailStore();
  const navigate = useNavigate();

  const organizationContent = (
    <>
      <img width={24} height={24} src={selectedParentOrganization?.logoUrl} />
      <div className="mx-2 hidden md:block">
        {selectedParentOrganization?.name}
      </div>
    </>
  );

  if (organizations.length < 2) {
    return (
      <div className="flex items-center text-sm">{organizationContent}</div>
    );
  }
  return (
    <Select
      value={
        selectedParentOrganization?.id
          ? selectedParentOrganization.id.toString()
          : ''
      }
      onValueChange={(selectedOrganizationId) => {
        const selectedOrganization = organizations.find(
          (org) => org.id.toString() === selectedOrganizationId
        );
        if (!selectedOrganization) return;
        setSelectedParentOrganization(selectedOrganization);
        setSelectedOrganization();
        navigate({ to: '/organizations' });
      }}
    >
      <SelectTrigger className="h-fit border-none p-0">
        {selectedParentOrganization
          ? organizationContent
          : 'Select organization...'}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {organizations.map((organization) => (
            <SelectItem
              key={organization.id}
              value={organization.id.toString()}
            >
              <div className="flex items-center justify-start gap-2">
                <img width={24} height={24} src={organization.logoUrl} />
                {organization.name}
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
