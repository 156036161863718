import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import { forwardRef } from 'react';
import 'react-quill/dist/quill.snow.css';

type Props = Pick<
  ReactQuillProps,
  'value' | 'onChange' | 'placeholder' | 'theme'
>;

const toolBarOptions = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

const TextEditor = forwardRef(function TextEditor(
  props: Props,
  ref: React.Ref<ReactQuill>
) {
  return (
    <ReactQuill
      {...props}
      onChange={(value, _delta, source) => {
        // prevent quill from removing empty lines from the content and breaking isDirty behavior of the forms
        if (source === 'api') return;

        //@ts-ignore
        props.onChange?.(value);
      }}
      modules={toolBarOptions}
      ref={(quillRef) => {
        if (quillRef) {
          quillRef.getEditor().clipboard.addMatcher('IMG', () => {
            const Delta = Quill.import('delta');
            return new Delta().insert('');
          });
        }
        //@ts-ignore
        ref = quillRef;
        return ref;
      }}
      preserveWhitespace
    />
  );
});

export { TextEditor };
