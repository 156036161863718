import { CareersView } from '@/components/core/careers';
import { createRoute, Outlet, useParams } from '@tanstack/react-router';
import { Route as RootRoute } from './__root';
import { RouterParams } from '@/main';

export const Route = createRoute({
  path: '/careers/$organizationId',
  getParentRoute: () => RootRoute,
  component: View,
});

function View() {
  const { jobId } = useParams({ strict: false }) as RouterParams;
  return jobId ? <Outlet /> : <CareersView />;
}
