import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { PlusIcon, Search } from 'lucide-react';
import { useMemo, useState } from 'react';
import creatingSvg from '@/assets/svg/creating.svg';
import { useNavigate } from '@tanstack/react-router';
import { Organization } from '@/fetchers/useUserDetails';
import { OrganizationCard } from './organization-card';
import { Separator } from '@/components/ui/separator';

import './styles.css';

type OrganizationsProps = {
  organizations: Organization[];
};

export function Organizations({ organizations }: OrganizationsProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const filteredItems = useMemo(() => {
    return organizations.filter((item) => {
      const matchesTitle =
        item.name.toLowerCase().split(searchTerm.toLowerCase()).length > 1;
      const matchesSubtitle =
        item.companyDescription.toLowerCase().split(searchTerm.toLowerCase())
          .length > 1;
      // const matchesContent = item.content.toLowerCase().split(searchTerm.toLowerCase()).length > 1

      return matchesTitle || matchesSubtitle;
    });
  }, [searchTerm, organizations]);

  return (
    <div className="mx-auto flex h-screen w-full max-w-2xl flex-col lg:max-w-7xl">
      <div className="flex flex-col">
        <h1 className="text-3xl font-bold">Boards</h1>
        <p className="text-muted-foreground">
          Manage your boards separately without affecting each other
        </p>
        <Separator className="mb-2 mt-4" />
      </div>
      <div className="flex items-center justify-between bg-background/95 py-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <Button
          variant="secondary"
          onClick={() =>
            navigate({
              to: `/organizations/$organizationId/company/settings`,
              params: { organizationId: 'create' },
            })
          }
        >
          <PlusIcon className="mr-1 size-4" />
          New Board
        </Button>
        <div className="relative">
          <Search className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search"
            className="pl-8"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>{' '}
      {filteredItems.length === 0 ? (
        <EmptyView
          title={
            organizations?.length === 0
              ? 'No boards found'
              : 'No boards found with the search criteria'
          }
          ctaText={
            organizations?.length === 0
              ? 'Create your first board'
              : 'Create a new board'
          }
          subTitle={
            organizations?.length === 0
              ? 'Create your first board to get started'
              : 'Create a new board that matches the search criteria to get started'
          }
          onClick={() =>
            navigate({
              to: `/organizations/$organizationId/company/settings`,
              params: { organizationId: 'create' },
            })
          }
        />
      ) : (
        <div className="mb-4 h-full overflow-y-auto pb-16 pr-1 md:pb-4">
          <div className="boards-container md:pr-0.5">
            {filteredItems.map((organization) => (
              <OrganizationCard
                key={organization.id}
                organization={organization}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function EmptyView({
  onClick,
  title,
  ctaText,
  subTitle,
}: {
  ctaText: string;
  subTitle: string;
  title: string;
  onClick: () => void;
}) {
  return (
    <div className="my-12 flex w-full flex-col items-center justify-center space-y-8">
      <h1 className="max-w-sm text-center text-3xl font-bold">{title}</h1>
      <img
        src={creatingSvg}
        alt="Create a new position svg"
        style={{
          width: '20%',
          height: 'auto',
        }}
      />
      <Button onClick={onClick}>
        <PlusIcon className="mr-2 h-4 w-4" />
        {ctaText}
      </Button>
      <p className="max-w-md text-center">{subTitle}</p>
    </div>
  );
}
