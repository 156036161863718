import { Button } from '@/components/ui/button';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';

import { ATSConnection } from '@/components/core/ats/types';
import threeDotsIcon from '@/assets/images/ats/small/threedots.svg';
import { ATS_CONNECTIONS } from './constants';

export type ATSConnectionListProps = {
  onSelectIntegration: (integration: ATSConnection) => void;
};

export function ATSConnectionList({
  onSelectIntegration,
}: ATSConnectionListProps) {
  const { showNewMessage } = useIntercomMessenger();

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-3 gap-2">
        {ATS_CONNECTIONS.map((connection) => (
          <Button
            variant={'outline'}
            key={connection.id}
            className="flex h-36 flex-col"
            onClick={() => {
              if (connection.active) {
                onSelectIntegration(connection);
              } else {
                showNewMessage(
                  'Hi! I would like to connect to ' + connection.name
                );
              }
            }}
          >
            <img
              src={connection.imageUrl}
              alt={connection.name}
              className="mb-2 size-8 object-cover"
            />
            <p>{connection.name}</p>
          </Button>
        ))}
        <Button
          variant="outline"
          className="h-36 flex-col"
          onClick={() => {
            showNewMessage('Hi! I would like to connect to another ATS');
          }}
        >
          <img
            src={threeDotsIcon}
            alt="Others"
            className="size-8 object-cover"
          />
          <p>Others</p>
        </Button>
      </div>
    </div>
  );
}
