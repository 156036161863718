import { cn } from '@/lib/utils';
import { ComponentProps } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Badge } from '@/components/ui/badge';
import { ItemActions } from './item-actions';
import { ItemAction } from '@/pages/dashboard/components/item-list';
import { sanitizeHtml } from '@/lib/sanitizeHtml';

type TProps = {
  id: number;
  draggable?: boolean;
  title: string;
  titleJSX?: () => JSX.Element;
  completedAt?: string | null;
  subtitle: string;
  tags: string[];
  onClick: () => void;
  content?: string | null;
  // handleOnEdit?: () => void
  // handleTogglePublishPosition?: () => void
  isOpen: boolean;
  disabled?: boolean;
  // displayActions: boolean,
  dropdownActions?: ItemAction[];
  applicantCount?: number;
};

export function ItemPreview({
  title = '',
  completedAt,
  subtitle = '',
  tags = [],
  titleJSX,
  onClick,
  content = '',
  isOpen = true,
  // handleOnEdit,
  // handleTogglePublishPosition,
  // displayActions = false
  dropdownActions = [],
  disabled = false,
  applicantCount = 0,
}: Readonly<TProps>) {
  return (
    <div className={cn('relative w-full', disabled && 'pointer-events-none')}>
      {dropdownActions.length > 0 && (
        <div className="absolute right-2 top-1 flex items-center">
          <div className={cn('ml-auto text-xs')}>
            {completedAt &&
              dropdownActions.length === 0 &&
              formatDistanceToNow(new Date(completedAt), {
                addSuffix: true,
              })}
          </div>
          <ItemActions dropdownActions={dropdownActions} />
        </div>
      )}

      <button
        className={cn(
          'flex w-full flex-col items-start gap-2 rounded-sm border p-3 text-left text-sm transition-all hover:bg-accent'
        )}
        onClick={onClick}
      >
        <div className="flex w-full flex-col gap-1 pr-10">
          <div className="flex items-center">
            <div className="flex w-full items-center gap-2">
              <div className="w-full font-semibold">
                {titleJSX ? titleJSX() : title}{' '}
                {applicantCount > 0 && (
                  <span className="text-xs font-normal text-gray-600">
                    {' | '}
                    {applicantCount} applicant{applicantCount > 1 ? 's' : ''}
                  </span>
                )}
              </div>
              {!isOpen && <Badge className="-mt-1 self-start">Closed</Badge>}
            </div>
            <div
              className={cn(
                'ml-auto text-xs',
                (!completedAt || dropdownActions.length > 0) && 'hidden'
              )}
            >
              {completedAt &&
                formatDistanceToNow(new Date(completedAt), {
                  addSuffix: true,
                })}
            </div>
          </div>
          <div className="text-xs font-medium">{subtitle}</div>
        </div>
        {content && (
          <div className="line-clamp-3 text-xs text-muted-foreground">
            {sanitizeHtml(content)}
          </div>
        )}
        {tags.length ? (
          <div className="flex items-center gap-2">
            {tags.map((tag) => (
              <Badge key={tag} variant={getBadgeVariantFromLabel(tag)}>
                {tag}
              </Badge>
            ))}
          </div>
        ) : null}
      </button>
    </div>
  );
}

function getBadgeVariantFromLabel(
  label: string
): ComponentProps<typeof Badge>['variant'] {
  if (['work'].includes(label.toLowerCase())) {
    return 'default';
  }

  if (['personal'].includes(label.toLowerCase())) {
    return 'outline';
  }

  return 'secondary';
}
