import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { RequiredStar } from '../common/RequiredStar';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

const connectionSchema = z.object({
  api_key: z.string().min(1, { message: 'API Key is required' }),
});

export type ATSConnectionAPIKeyFormValues = z.infer<typeof connectionSchema>;

interface ATSConnectionAPIKeyFormProps {
  onSubmit: (data: ATSConnectionAPIKeyFormValues) => void;
}

export function ATSConnectionAPIKeyForm({
  onSubmit,
}: ATSConnectionAPIKeyFormProps) {
  const form = useForm<ATSConnectionAPIKeyFormValues>({
    resolver: zodResolver(connectionSchema),
  });

  function onSubmitHandler(data: ATSConnectionAPIKeyFormValues) {
    onSubmit(data);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandler)}
        className="flex w-full flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="api_key"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel aria-required>
                API Key
                <RequiredStar />
              </FormLabel>
              <FormDescription></FormDescription>
              <FormControl>
                <Input placeholder="Your API Key" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="self-end">Connect</Button>
      </form>
    </Form>
  );
}
