import { PositionStatus, TPosition } from '@/fetchers/usePosition';
import { cn, getSubtitleFromPosition } from '@/lib/utils';
import { ItemActions } from '../management/item-actions';
import { Badge, BadgeProps } from '@/components/ui/badge';
import { useNavigate } from '@tanstack/react-router';
import { Route as PositionsRoute } from '@/routes/organizations.$organizationId.positions.list';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { positionStatusTextMap } from './positionStatusTextMap';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { getIsPositionDraft } from '@/lib/getIsPositionDraft';
import useCloneAlertModal from '@/hooks/useCloneAlertModal';
import { ItemAction } from '@/pages/dashboard/components/item-list';
import usePositionStatusUpdateModal from '@/hooks/usePositionStatusUpdateModal';
import { sanitizeHtml } from '@/lib/sanitizeHtml';

type PositionListItemProps = {
  position: TPosition;
};

function getBadgeVariantFromLabel(label: string): BadgeProps['variant'] {
  if (label.toLowerCase().includes('work')) {
    return 'default';
  }

  if (label.toLowerCase().includes('personal')) {
    return 'outline';
  }

  return 'secondary';
}

const statusStyles: Record<PositionStatus, string> = {
  [PositionStatus.Draft]: 'bg-white border-gray-600 text-gray-600 border',
  [PositionStatus.Open]: 'bg-white border-green-600 text-green-600 border',
  [PositionStatus.Closed]: 'bg-white border-red-600 text-red-600 border',
};

const PositionListItem = ({ position }: PositionListItemProps) => {
  const { title, applicantCount = 0, tags, content, status, slug } = position;
  const isPositionOpen = getIsPositionOpen(position);
  const isPositionDraft = getIsPositionDraft(position);
  const { organizationId } = PositionsRoute.useParams();
  const navigate = useNavigate();
  const { selectedOrganization } = useUserDetailStore();
  const { handleOpenCloneAlertModal, CloneAlertModal } = useCloneAlertModal({
    positionId: position.id,
  });
  const { PositionStatusUpdateModal, setIsPositionStatusUpdateModalOpen } =
    usePositionStatusUpdateModal(position);

  function handleOnEdit() {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: {
        organizationId,
        slug,
        step: selectedOrganization?.integrationName
          ? 'communication'
          : 'details',
      },
    });
  }

  function handleOnClick() {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: {
        organizationId: organizationId,
        slug,
        step: 'applications',
      },
    });
  }

  const dropdownActions: ItemAction[] = [
    {
      title: 'Edit',
      onClick: handleOnEdit,
    },
    {
      title: 'Clone',
      onClick: () => handleOpenCloneAlertModal(`Copy of ${title}`),
      hidden: !!selectedOrganization?.integrationName,
    },
    {
      title: isPositionOpen
        ? 'Close position'
        : isPositionDraft
          ? 'Publish position'
          : 'Open position',
      onClick: () => {
        setIsPositionStatusUpdateModalOpen(true);
      },
      customClasses: cn('cursor-pointer', isPositionOpen && 'text-red-500'),
    },
  ];

  return (
    <>
      {CloneAlertModal}
      {PositionStatusUpdateModal}
      <div className="relative w-full">
        {dropdownActions.length > 0 && (
          <div className="absolute right-3 top-2 flex items-center gap-2">
            <Badge
              className={cn(
                'pointer-events-none ml-auto flex w-16 justify-center',
                statusStyles[status]
              )}
            >
              {positionStatusTextMap[status]}
            </Badge>
            <ItemActions dropdownActions={dropdownActions} />
          </div>
        )}

        <button
          className="flex w-full flex-col items-start gap-2 rounded-sm border p-3 text-left text-sm transition-all hover:bg-accent"
          onClick={handleOnClick}
        >
          <div className="flex w-full flex-col gap-1 pr-10">
            <div className="flex w-full items-center gap-2 font-semibold">
              {title}
              {applicantCount > 0 && (
                <span className="text-xs font-normal text-gray-600">
                  {' | '}
                  {applicantCount} applicant{applicantCount > 1 ? 's' : ''}
                </span>
              )}
            </div>
            <div className="text-xs font-medium">
              {getSubtitleFromPosition(position)}
            </div>
          </div>
          {content && (
            <div className="line-clamp-3 text-xs text-muted-foreground">
              {sanitizeHtml(content)}
            </div>
          )}
          {tags?.length ? (
            <div className="flex items-center gap-2">
              {tags?.map((tag) => (
                <Badge key={tag} variant={getBadgeVariantFromLabel(tag)}>
                  {tag}
                </Badge>
              ))}
            </div>
          ) : null}
        </button>
      </div>
    </>
  );
};

export default PositionListItem;
