import { Input } from '@/components/ui/input';
import { forwardRef } from 'react';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const SlugInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <div className="mt-2 flex rounded-md shadow-sm">
      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-200 px-3 text-gray-500 sm:text-sm">
        https://
      </span>
      <Input
        className="z-10 w-32 rounded-none text-right"
        placeholder="companyslug"
        ref={ref}
        {...props}
      />
      <span className="z-0 inline-flex items-center rounded-r-md border border-l-0 border-gray-200 bg-gray-100 px-3 text-gray-500 sm:text-sm">
        .veton.ai
      </span>
    </div>
  );
});

export default SlugInput;
