import { Trans, useTranslation } from 'react-i18next';

export function AnalysisView({ positionName }: { positionName: string }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex">
        <h1 className="mt-2 text-3xl font-bold">
          <Trans
            t={t}
            i18nKey="interview_completed"
            components={{
              completedTag: <span className="underline decoration-sky-500" />,
            }}
          />
        </h1>
      </div>
      <p className="text-lg">{t('company_has_been_notified')}</p>

      <div className="container mb-8 mt-4 px-4">
        <div className="mt-4 h-[75vh] overflow-auto rounded-lg border-2 border-black/50 bg-black/10 p-4 pb-8 shadow-xl">
          <div className="mx-auto mt-2 max-w-screen-md px-4 lg:py-6">
            <p className="prose-slate whitespace-pre-line">
              {t('thank_you_message', { positionName })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
