import { useEffect, useRef, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Play, Square } from 'lucide-react';

const generateBarsFromDuration = (duration: number) => {
  const barsPerSecond = 7; // Number of bars per second
  const totalBars = duration * barsPerSecond;
  return Array.from({ length: totalBars }, (_, i) => {
    // Create a wave pattern that repeats every 6 bars
    const position = i % 7;
    const heights = [60, 40, 70, 50, 80, 30];
    return heights[position];
  });
};

type AudioPlayerProps = {
  audio: HTMLAudioElement;
};

const AudioPlayer = ({ audio }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const audioRef = useRef<HTMLAudioElement>(audio);

  const bars = generateBarsFromDuration(Math.floor(audio.duration));

  useEffect(() => {
    const handlePlaying = () => {
      const otherAudios = document.querySelectorAll('audio');
      Array.from(otherAudios)
        .filter((otherAudio) => otherAudio.src !== audioRef.current?.src)
        .forEach((otherAudio) => {
          otherAudio.pause();
          otherAudio.currentTime = 0;
        });
      setIsPlaying(true);
      timerRef.current = setInterval(() => {
        setCurrentTime((prev) =>
          prev < audioRef.current?.duration ? prev + 0.1 : 0
        );
      }, 100);
    };
    const handlePause = () => {
      setIsPlaying(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
      setCurrentTime(0);
    };
    audioRef.current?.addEventListener('playing', handlePlaying);
    audioRef.current?.addEventListener('pause', handlePause);
    return () => {
      audioRef.current?.removeEventListener('playing', handlePlaying);
      audioRef.current?.removeEventListener('pause', handlePause);
    };
  }, []);

  const getBarColor = (index: number) => {
    const progressPercentage = (currentTime / audio.duration) * 100;
    const barPosition = (index / bars.length) * 100;
    return barPosition < progressPercentage ? 'bg-primary' : 'bg-gray-200';
  };

  const handleToggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    } else {
      audioRef.current.play();
    }
  };

  return (
    <>
      <audio ref={audioRef} src={audio.src} />
      <div className="mx-auto flex w-[75%] items-center gap-2">
        <Button
          className="size-5 shrink-0 rounded-full"
          size="icon"
          onClick={(e) => {
            e.stopPropagation();
            handleToggleAudio();
          }}
          type="button"
        >
          {isPlaying ? (
            <Square className="size-2.5" />
          ) : (
            <Play className="size-2.5 fill-white" />
          )}
        </Button>

        <div className="flex h-5 flex-1 items-center">
          {bars.map((height, index) => (
            <div key={index} className="flex h-full flex-1 items-center">
              <div
                className={`w-0.5 rounded-full ${getBarColor(index)}`}
                style={{
                  height: `${height}%`,
                  minHeight: '4px',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AudioPlayer;
