export function parseISODuration(isoDuration: string) {
  const regex =
    /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:([\d.]+)S)?/;
  const matches = isoDuration.match(regex);

  if (!matches) {
    throw new Error('Invalid duration format');
  }

  const [, years, months, weeks, days, hours, minutes, seconds] = matches;

  return {
    years: years ? parseInt(years) : 0,
    months: months ? parseInt(months) : 0,
    weeks: weeks ? parseInt(weeks) : 0,
    days: days ? parseInt(days) : 0,
    hours: hours ? parseInt(hours) : 0,
    minutes: minutes ? parseInt(minutes) : 0,
    seconds: seconds ? parseFloat(seconds) : 0,
  };
}
