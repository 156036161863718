import AlertModal from '@/components/core/common/AlertModal';
import { GenericTable } from '@/components/core/common/Table';
import { Loader } from '@/components/core/loader';
import {
  PLAN_LIST_PRIVATE,
  useAdminGetAllParentOrganizations,
  useAdminParentOrganizationPlanUpdate,
  useAdminTeamMemberUpdate,
} from '@/fetchers/useOrganization';
import { createRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { Route as adminPanelRoute } from './admin-panel';

export const Route = createRoute({
  path: '/organizations',
  getParentRoute: () => adminPanelRoute,
  component: AdminOrganizationsPage,
});

function AdminOrganizationsPage() {
  const [updatePlanModalOpen, setUpdatePlanModalOpen] = useState(false);
  const { data, isFetching } = useAdminGetAllParentOrganizations({
    enabled: true,
  });

  const updateTeam = useAdminTeamMemberUpdate();
  const updatePlan = useAdminParentOrganizationPlanUpdate();

  if (isFetching) {
    return <Loader />;
  }

  function handleUpdatePlan() {
    updatePlan({
      // @ts-ignore
      parentOrganizationId: updatePlanModalOpen?.id,
      // @ts-ignore
      stripeCustomerId: document.getElementById('stripeCustomerId')?.value,
      // @ts-ignore
      plan: document.getElementById('plan')?.value,
    });
  }

  return (
    <>
      <AlertModal
        open={!!updatePlanModalOpen}
        onClose={() => setUpdatePlanModalOpen(false)}
        title="You are about to send reminders to all applicants"
        description={` Are you sure you want to send reminders to all applicants? The interview email will be send to all applicants who have not started their interviews.`}
        content={
          <div>
            <input
              // @ts-ignore
              defaultValue={updatePlanModalOpen?.stripeCustomerId}
              id="stripeCustomerId"
            />

            <select
              // @ts-ignore
              defaultValue={updatePlanModalOpen?.plan}
              id="plan"
              onChange={(e) => console.log('item', e.target.value)}
            >
              {PLAN_LIST_PRIVATE.map((option) => (
                <option value={option.plan}>{option.name}</option>
              ))}
            </select>
          </div>
        }
        confirmText="Send"
        onProceed={handleUpdatePlan}
      />

      <div>
        <GenericTable
          tableHeaders={[
            'id',
            'Name',
            'Plan',
            'Stripe Customer Id',
            'Total Interview Count',
          ]}
          items={data || []}
          itemKeyOrder={[
            'id',
            'name',
            'plan',
            'stripeCustomerId',
            'completedInterviewCount',
          ]}
          title="Organizations"
          description="Organizations"
          tableActions={[
            {
              title: 'Join',
              onClick: (item) => {
                updateTeam({
                  parentOrganizationId: item.id,
                  action: 'add',
                });
              },
            },
            {
              title: 'Leave',
              onClick: (item) => {
                updateTeam({
                  parentOrganizationId: item.id,
                  action: 'remove',
                });
              },
            },

            {
              title: 'Update Plan',
              onClick: (item) => {
                setUpdatePlanModalOpen(item);
              },
            },
          ]}
        />
      </div>
    </>
  );
}
