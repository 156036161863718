import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuGroup,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { MoreHorizontal } from 'lucide-react';
import { PipelineBulkAction } from './types';
import { cn } from '@/lib/utils';

type BulkActionMenuProps = {
  actions: PipelineBulkAction[];
};

const BulkActionMenu = ({ actions }: BulkActionMenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="ml-auto flex h-fit items-center p-1">
          <MoreHorizontal className="size-6" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          {actions.map((action, idx) => (
            <DropdownMenuItem
              key={idx}
              onClick={action.onClick}
              role="button"
              className={cn('cursor-pointer', action.className)}
            >
              {action.title}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default BulkActionMenu;
