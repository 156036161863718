import {
  Building2Icon,
  Inbox,
  Receipt,
  BookUser,
  User2,
  BellDotIcon,
  HelpCircle,
  LogOut,
  ChevronLeft,
  ChevronRight,
  Settings,
  Home,
} from 'lucide-react';

import {
  Sidebar as ShadcnSidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuSkeleton,
  useSidebar,
} from '@/components/ui/sidebar';
import { useUserDetails, useUserDetailStore } from '@/fetchers/useUserDetails';
import { Link, useRouterState } from '@tanstack/react-router';
import vetonLogo from '@/assets/svg/logo-whitebg.svg';
import vetonLogoSmall from '@/assets/svg/logo-small.svg';
import { AcademicCapIcon } from '@heroicons/react/24/solid';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { CustomSidebarMenuItem, TSidebarMenuItem } from './sidebar-menu-item';
import { useGlobalAlertStore } from '@/lib/useGlobalAlertStore';

export const Sidebar = () => {
  const {
    location: { pathname },
  } = useRouterState();
  const { data: userDetails, isFetching: isFetchingUserDetails } =
    useUserDetails();
  const { selectedOrganization, setSelectedOrganization } =
    useUserDetailStore();
  const alertHeight = useGlobalAlertStore((store) => store.alertHeight);

  const { openChat } = useIntercomMessenger();
  const { open, toggleSidebar, isMobile } = useSidebar();

  const isNewPosition =
    pathname === `/organizations/${selectedOrganization?.id}/positions/create`;

  const generalLinks: TSidebarMenuItem[] = [
    {
      title: 'Boards',
      icon: Home,
      isActive: pathname === `/organizations`,
      isCollapsibleOpen: pathname.includes(
        `/organizations/${selectedOrganization?.id}`
      ),
      to: `/organizations`,
      onClick: () => {
        setSelectedOrganization();
      },
      subItems: selectedOrganization
        ? [
            {
              title: selectedOrganization?.name ?? '',
              icon: Building2Icon,
            },
            {
              title: 'Positions',
              icon: Inbox,
              isActive:
                !isNewPosition &&
                pathname.includes(
                  `organizations/${selectedOrganization?.id}/positions`
                ),
              to: `/organizations/${selectedOrganization?.id}/positions/list`,
            },
            {
              title: 'Board Settings',
              icon: Settings,
              isActive: pathname.includes(
                `/organizations/${selectedOrganization?.id}/company`
              ),
              to: `/organizations/${selectedOrganization?.id}/company/settings`,
            },
          ]
        : [],
    },
    {
      title: 'Team Settings',
      icon: BookUser,
      isActive: pathname.includes(`/team-management`),
      to: `/team-management`,
    },
    {
      title: 'Billing',
      icon: Receipt,
      isActive: pathname.includes(`/billing`),
      to: `/billing`,
    },
  ].filter(
    (link) =>
      !(selectedOrganization?.integrationName && link.title === 'Careers page')
  );

  const userLinks: TSidebarMenuItem[] = [
    {
      title:
        userDetails?.firstName && userDetails?.lastName
          ? `${userDetails?.firstName} ${userDetails?.lastName}`
          : userDetails?.email || 'Profile',
      icon: User2,
      isActive: pathname === `/user/profile`,
      to: `/user/profile`,
    },
    {
      title: 'Notifications',
      icon: BellDotIcon,
      isActive: pathname === `/user/notifications`,
      to: `/user/notifications`,
    },

    {
      title: 'Support',
      icon: HelpCircle,
      onClick: () => {
        openChat();
      },
    },
    {
      title: 'Logout',
      icon: LogOut,
      to: `/logout`,
    },
  ];

  if (userDetails?.email.endsWith('@veton.ai')) {
    userLinks.push({
      title: 'Admin Panel',
      icon: AcademicCapIcon,
      isActive: pathname === `/admin`,
      to: `/admin-panel`,
    });
  }

  return (
    <ShadcnSidebar
      collapsible="icon"
      id="sidebar"
      style={
        !isMobile
          ? {
              marginTop: alertHeight,
            }
          : {}
      }
    >
      <Button
        variant="outline"
        className="absolute right-[-12px] top-[50%] z-10 hidden size-6 -translate-y-1/2 rounded-full shadow-sm md:flex"
        size="icon"
        onClick={toggleSidebar}
      >
        {open ? (
          <ChevronLeft className="size-4" />
        ) : (
          <ChevronRight className="size-4" />
        )}
      </Button>
      <SidebarHeader>
        <Link to="/organizations">
          <img
            src={open ? vetonLogo : vetonLogoSmall}
            alt="veton.ai logo"
            className="mx-auto w-40"
          />
        </Link>
      </SidebarHeader>
      <SidebarContent>
        {isFetchingUserDetails ? (
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                {Array.from({ length: 6 }).map((_, index) => (
                  <SidebarMenuItem key={index}>
                    <SidebarMenuSkeleton />
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        ) : (
          <>
            <SidebarGroup>
              <SidebarGroupLabel className="text-lg text-sidebar-primary">
                Management
              </SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {generalLinks.map((link) =>
                    !open ? (
                      <Tooltip key={link.title} delayDuration={0}>
                        <TooltipTrigger>
                          <CustomSidebarMenuItem link={link} />
                        </TooltipTrigger>
                        <TooltipContent side="right">
                          {link.title}
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <CustomSidebarMenuItem key={link.title} link={link} />
                    )
                  )}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
            <SidebarGroup>
              <SidebarGroupLabel className="text-lg text-sidebar-primary">
                User
              </SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {userLinks.map((link) =>
                    !open ? (
                      <Tooltip delayDuration={0} key={link.title}>
                        <TooltipTrigger>
                          <CustomSidebarMenuItem link={link} />
                        </TooltipTrigger>
                        <TooltipContent side="right">
                          {link.title}
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <CustomSidebarMenuItem key={link.title} link={link} />
                    )
                  )}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          </>
        )}
      </SidebarContent>
    </ShadcnSidebar>
  );
};
