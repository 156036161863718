import { AlertProps } from '@/components/core/alert';
import { create } from 'zustand';

type GlobalAlertStore = {
  globalAlert: AlertProps | null;
  setGlobalAlert: (options: AlertProps) => void;
  clearGlobalAlert: () => void;
  alertHeight: number;
  setAlertHeight: (height: number) => void;
};

export const useGlobalAlertStore = create<GlobalAlertStore>()((set) => ({
  globalAlert: null,
  setGlobalAlert: (alert) => set({ globalAlert: alert }),
  clearGlobalAlert: () => set({ globalAlert: null, alertHeight: 0 }),
  alertHeight: 0,
  setAlertHeight: (height) => set({ alertHeight: height }),
}));
