import { LayersLoader } from './layers';
import './loader.css';

export function Loader(
  {
    variant = 'layers',
    className,
  }: { variant?: 'ball' | 'layers'; className?: string } = {
    variant: 'layers',
  }
) {
  if (variant === 'layers') {
    return <LayersLoader className={className} />;
  }

  return (
    <div className="h-[75vh]">
      <div className="flex h-full w-full items-center justify-center bg-gray-200">
        <div />
      </div>
    </div>
  );
}
