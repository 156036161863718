import { AlertProps } from '@/components/core/alert';
import { buttonVariants } from '@/components/ui/button';
import { BillingStanding, useGetBilling } from '@/fetchers/useOrganization';
import { useGlobalAlertStore } from '@/lib/useGlobalAlertStore';
import { Link } from '@tanstack/react-router';
import { useEffect } from 'react';

const alertsByStanding: Partial<Record<BillingStanding, AlertProps>> = {
  headsup: {
    description: 'You are approaching the Interview limit of your plan.',
    severity: 'info',
  },
  warning: {
    description:
      "You have exceeded the Interview limit of your plan. Don't worry, we have added an additional 10% capacity not to interrupt your interviews.",
    severity: 'warning',
  },
  critical: {
    description:
      'You have exceeded the Interview limit of your plan. You will no longer be able to send interview invites.',
    severity: 'error',
  },
};
export const useBillingAlert = () => {
  const { data: billing } = useGetBilling({ enabled: true });
  const setGlobalAlert = useGlobalAlertStore((store) => store.setGlobalAlert);

  useEffect(() => {
    const standing = billing?.standing;
    if (!standing) return;

    const alert = alertsByStanding[standing];
    if (!alert) return;
    setGlobalAlert({
      ...alert,
      action: (
        <Link to="/billing" className={buttonVariants({ variant: 'outline' })}>
          Manage
        </Link>
      ),
      dismissable: standing !== 'critical',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing]);
};
