export const afterApplicationSubjectSpanish =
  'Nueva entrevista para {{position_name}} en {{company_name}}';

export const afterInterviewSubjectSpanish =
  '{{company_name}} | Entrevista para {{position_name}}';

export const disqualifiedSubjectSpanish =
  'Actualización del estado de la solicitud para {{position_name}} en {{company_name}}';

export const applicationEmailSpanish = `<p>Estimado/a {{candidate_name}},</p><p><br/></p><p>Muchas gracias por postular al puesto de {{position_name}} en {{company_name}}. ¡Estamos listos para tu entrevista!</p><p><br/></p><p>Cuando estés listo/a, por favor haz clic en el enlace de abajo para comenzar tu entrevista</p>`;

export const interviewCompletedEmailSpanish = `<p>Estimado/a {{candidate_name}},</p><p><br/></p><p>Gracias por completar la entrevista para el puesto de {{position_name}} en {{company_name}}. Revisaremos tu entrevista y nos pondremos en contacto contigo. Si tienes alguna pregunta, no dudes en escribirnos a {{company_email}}.</p><p><br/></p><p>¡Valoramos tus comentarios! Por favor, tómate un momento para completar nuestra breve encuesta sobre tu experiencia en la entrevista: https://sprw.io/stt-eG-cv.</p><p><br/></p><p>Estamos emocionados por la posibilidad de trabajar juntos y esperamos poder estar en contacto pronto.</p><p><br/></p><p>Atentamente,<br/>{{company_name}}</p>`;

export const disqualifiedEmailSpanish = `<p>Estimado/a {{candidate_name}},</p><p><br/></p><p>Lamentamos informarle que ha sido descalificado/a del proceso de selección para el puesto de {{position_name}}. Si tiene alguna pregunta, no dude en ponerse en contacto con nosotros en {{company_email}}.</p><p><br/></p><p>Atentamente,<br/>{{company_name}}</p>`;
