import { TimeSaved } from '@/fetchers/types';
import { parseISODuration } from './parseISODuration';

const formatTimeString = (timeString: string) => {
  const timeStringWithoutZeroes = timeString.replace(/\b0h\b|\b0m\b/g, '');

  return timeStringWithoutZeroes
    .replace(/h/g, ' hr')
    .replace(/m/g, ' min')
    .trim();
};

const getIsLessThanOneMinute = (
  hours: number,
  minutes: number,
  seconds: number
) => {
  return hours === 0 && minutes === 0 && seconds > 0 && seconds < 60;
};

const LESS_THAN_ONE_MINUTE = '< 1 min';

export const useParsedTimeSaved = (data?: TimeSaved): TimeSaved | null => {
  if (!data) return null;

  const {
    years: resumeAnalysisYears,
    months: resumeAnalysisMonths,
    weeks: resumeAnalysisWeeks,
    days: resumeAnalysisDays,
    hours: resumeAnalysisHours,
    minutes: resumeAnalysisMinutes,
    seconds: resumeAnalysisSeconds,
  } = parseISODuration(data.resumeAnalysisDuration);

  const {
    years: interviewYears,
    months: interviewMonths,
    weeks: interviewWeeks,
    days: interviewDays,
    hours: interviewHours,
    minutes: interviewMinutes,
    seconds: interviewSeconds,
  } = parseISODuration(data.interviewDuration);

  const {
    years: interviewAnalysisYears,
    months: interviewAnalysisMonths,
    weeks: interviewAnalysisWeeks,
    days: interviewAnalysisDays,
    hours: interviewAnalysisHours,
    minutes: interviewAnalysisMinutes,
    seconds: interviewAnalysisSeconds,
  } = parseISODuration(data.interviewAnalysisDuration);

  const {
    years: totalYears,
    months: totalMonths,
    weeks: totalWeeks,
    days: totalDays,
    hours: totalHours,
    minutes: totalMinutes,
  } = parseISODuration(data.totalDuration);

  // Convert years, months, weeks, days to hours
  const totalResumeHours =
    resumeAnalysisHours +
    resumeAnalysisDays * 24 +
    resumeAnalysisWeeks * 168 +
    resumeAnalysisMonths * 730 +
    resumeAnalysisYears * 8760;

  const totalInterviewHours =
    interviewHours +
    interviewDays * 24 +
    interviewWeeks * 168 +
    interviewMonths * 730 +
    interviewYears * 8760;

  const totalInterviewAnalysisHours =
    interviewAnalysisHours +
    interviewAnalysisDays * 24 +
    interviewAnalysisWeeks * 168 +
    interviewAnalysisMonths * 730 +
    interviewAnalysisYears * 8760;

  const totalOverallHours =
    totalHours +
    totalDays * 24 +
    totalWeeks * 168 +
    totalMonths * 730 +
    totalYears * 8760;

  const isResumeAnalysisLessThanOneMinute = getIsLessThanOneMinute(
    totalResumeHours,
    resumeAnalysisMinutes,
    resumeAnalysisSeconds
  );
  const isInterviewLessThanOneMinute = getIsLessThanOneMinute(
    totalInterviewHours,
    interviewMinutes,
    interviewSeconds
  );
  const isInterviewAnalysisLessThanOneMinute = getIsLessThanOneMinute(
    totalInterviewAnalysisHours,
    interviewAnalysisMinutes,
    interviewAnalysisSeconds
  );

  const formattedResumeAnalysisDuration = isResumeAnalysisLessThanOneMinute
    ? LESS_THAN_ONE_MINUTE
    : formatTimeString(`${totalResumeHours}h ${resumeAnalysisMinutes}m`);

  const formattedInterviewDuration = isInterviewLessThanOneMinute
    ? LESS_THAN_ONE_MINUTE
    : formatTimeString(`${totalInterviewHours}h ${interviewMinutes}m`);

  const formattedInterviewAnalysisDuration =
    isInterviewAnalysisLessThanOneMinute
      ? LESS_THAN_ONE_MINUTE
      : formatTimeString(
          `${totalInterviewAnalysisHours}h ${interviewAnalysisMinutes}m`
        );

  return {
    resumeAnalysisDuration: formattedResumeAnalysisDuration,
    interviewDuration: formattedInterviewDuration,
    interviewAnalysisDuration: formattedInterviewAnalysisDuration,
    applicantCount: data.applicantCount,
    interviewedApplicantCount: data.interviewedApplicantCount,
    totalDuration: formatTimeString(`${totalOverallHours}h ${totalMinutes}m`),
  };
};
