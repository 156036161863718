export const afterApplicationSubjectEnglish =
  'New interview for {{position_name}} at {{company_name}}';

export const afterInterviewSubjectEnglish =
  '{{company_name}} | {{position_name}} Interview';

export const disqualifiedSubjectEnglish =
  'Application status update for {{position_name}} at {{company_name}}';

export const applicationEmailEnglish = `<p>Dear {{candidate_name}},</p><p><br/></p><p>Thank you very much for applying to the {{position_name}} at {{company_name}}. We are ready for your interview!</p><p><br/></p><p>Whenever you are ready, please click on the link below to start your interview.</p>`;

export const interviewCompletedEmailEnglish = `<p>Dear {{candidate_name}},</p><p><br/></p><p>Thank you for completing the interview for {{position_name}} at {{company_name}}. We will review your interview and get back to you. If you have any questions, please feel free to contact us at {{company_email}}.</p><p><br/></p><p>We value your feedback! Please take a moment to complete our brief survey about your interview experience: https://sprw.io/stt-eG-cv</p><p><br/></p><p>We're excited about the possibility of working together and look forward to being in touch soon.</p><p><br/></p><p>All the best,<br/>{{company_name}}</p>`;

export const disqualifiedEmailEnglish = `<p>Dear {{candidate_name}},</p><p><br/></p><p>We regret to inform you that you have been disqualified from the application process for {{position_name}}. If you have any questions, please feel free to contact us at {{company_email}}.</p><p><br/></p><p>All the best,<br/>{{company_name}}</p>`;
