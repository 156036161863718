import { TInterviewConfirmation } from '@/fetchers/useInterview';
import InterviewConfirmationHeader from '../interview-confirmation/interview-confirmation-header';
import { getContrastColorFromBackgroundColor } from '@/lib/utils';
import { Trans, useTranslation } from 'react-i18next';

type InterviewNoLongerActiveProps = {
  interviewDetails: TInterviewConfirmation;
};

const InterviewNoLongerActive = ({
  interviewDetails,
}: InterviewNoLongerActiveProps) => {
  const { organization, positionName } = interviewDetails;
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  return (
    <div className="min-h-screen overflow-y-auto">
      <InterviewConfirmationHeader
        logo={organization.logoUrl}
        companyReachOutEmail={organization.companyReachOutEmail ?? ''}
        title={organization.name}
      />
      <div
        className="flex min-h-[max(300px,30vh)] flex-col items-center justify-center px-4 text-center sm:px-0"
        style={{
          background: organization.color,
          color: getContrastColorFromBackgroundColor(organization.color, 0.8),
        }}
      >
        <span className="text-2xl font-bold">
          {positionName} at {organization.name}
        </span>
        <span>{t('interview_no_longer_active_title')}</span>
      </div>
      <div className="px-4 sm:px-0">
        <div className="mx-auto mt-10 flex max-w-2xl flex-col gap-2 break-words rounded-lg border p-6">
          <p className="font-bold">
            <Trans
              t={t}
              components={{
                emailTag: (
                  <a
                    href={`mailto:${organization.companyReachOutEmail}`}
                    className="text-blue-500"
                  />
                ),
              }}
              values={{
                companyReachOutEmail: organization.companyReachOutEmail,
              }}
            >
              {t('interview_no_longer_active_description')}
            </Trans>
          </p>
          {t('guideline_description')}
        </div>
      </div>
    </div>
  );
};

export default InterviewNoLongerActive;
