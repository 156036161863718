import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@/components/ui/card';
import AudioPlayer from '../../audio-player/audio-player';
import { useEffect, useState } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { AIInterviewer } from '@/fetchers/useOrganization';
import { cn } from '@/lib/utils';

type InterviewerCardProps = {
  interviewer: AIInterviewer;
};

const InterviewerCard = ({ interviewer }: InterviewerCardProps) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isAudioLoading, setIsAudioLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const { name, type, imageUrl, sampleUrl } = interviewer;

  useEffect(() => {
    const audio = new Audio(sampleUrl);
    audio.addEventListener('loadedmetadata', (e) => {
      setAudio(e.currentTarget as HTMLAudioElement);
      setIsAudioLoading(false);
    });
  }, [sampleUrl]);

  return (
    <Card className="relative flex aspect-square cursor-pointer flex-col justify-center transition-colors hover:bg-accent">
      <CardHeader className="p-4">
        <div className="mx-auto h-24 w-24 overflow-hidden rounded-full">
          {isImageLoading && <Skeleton className="h-full w-full" />}
          <img
            src={imageUrl}
            alt={name}
            className={cn(
              'h-full w-full object-cover',
              isImageLoading && 'absolute opacity-0'
            )}
            onLoad={() => setIsImageLoading(false)}
          />
        </div>
      </CardHeader>
      <CardContent className="p-4 pt-0 text-center">
        <p className="text-sm font-medium">
          {name} ({type})
        </p>
        {isAudioLoading && <Skeleton className="mt-4 h-5" />}
      </CardContent>
      {audio && (
        <CardFooter className="pb-4">
          <AudioPlayer audio={audio} />
        </CardFooter>
      )}
    </Card>
  );
};

export default InterviewerCard;
