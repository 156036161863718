import {
  cn,
  getContrastColorFromBackgroundColor,
  getOrganizationSlugIfSubdomain,
  getSubtitleFromPosition,
} from '@/lib/utils';

import { Separator } from '@/components/ui/separator';

import { ItemList } from '@/pages/dashboard/components/item-list';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useGetPublicOrganization } from '@/fetchers/useOrganization';
import { Loader } from '../loader';
import { useGetPublicPositions } from '@/fetchers/usePosition';
import logo from '@/assets/svg/logo-whitebg.svg';

import { Button } from '@/components/ui/button';
import useDims from '@/lib/useDims';
import { ErrorView } from '../ErrorView';
import { RouterParams } from '@/main';

type THeaderProps = Readonly<{
  logo: string;
  title: string;
  organizationUrl?: string;
  openPositionCount: number;
}>;

function Header({
  logo,
  title,
  organizationUrl,
  openPositionCount = 0,
}: THeaderProps) {
  const { isMobile } = useDims();
  return (
    <div className="sticky top-0 z-10 mb-4 mt-6 w-full bg-white lg:mt-4">
      <div className="container flex w-full flex-col justify-between py-2 md:flex-row">
        <a
          className={cn('flex w-full items-center justify-start')}
          role={!organizationUrl ? 'div' : undefined}
          style={{
            cursor: organizationUrl ? 'pointer' : 'default',
          }}
          target="__blank"
          href={organizationUrl ?? ''}
        >
          <div className="size-[60px] overflow-hidden rounded">
            <img
              src={logo}
              // className="object-fit"
              alt="Company Logo"
              style={{
                width: '60px',
                height: '60px',
                // objectFit: "fill",
                aspectRatio: 1,
                cursor: organizationUrl ? 'pointer' : 'default',
              }}
            />
          </div>

          <div className="ml-4 flex flex-col text-left">
            <p className="text-2xl font-bold">{title}</p>
            <p className="text-sm text-gray-700">
              {openPositionCount} open positions
            </p>
          </div>
        </a>

        <div className="mt-2 text-center">
          {organizationUrl && (
            <a href={organizationUrl} target="__blank">
              <Button variant={isMobile ? 'link' : 'secondary'}>
                Company Website
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function Content({ description }: { description: string }) {
  return (
    <div
      className="w-full overflow-y-auto pb-12 text-center"
      style={{
        height: 'fit-content',
      }}
    >
      <p
        className="prose mx-auto w-full whitespace-pre-line"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ApplicationFormView({
  items,
  organzationSlug,
  organzationName,
}: {
  items: any;
  organzationSlug?: string;
  organzationName?: string;
}) {
  const navigate = useNavigate();
  const isSubdomain = getOrganizationSlugIfSubdomain();

  // if (items.length !== 0) {
  //   return (<div>
  //     No Open Positions Available
  //   </div>
  //   )
  // }

  return (
    <div
      id="application-form"
      style={{
        height: 'fit-content',
        width: '100%',
      }}
      className={cn(
        'rounded-lg lg:p-4',
        'lg:border-0',
        'h-full overflow-hidden',
        'mx-auto',
        'max-w-4xl'
        // "lg:overflow-auto",
      )}
    >
      <div className="mb-12 text-center">
        <p className="text-2xl font-bold">Our Openings</p>
        <p className="text-gray-700">
          {items?.length || 0} Open Position{items?.length === 1 ? '' : 's'}
        </p>
      </div>
      <ItemList
        layoutClasses=""
        onClick={(item) => {
          if (isSubdomain) {
            navigate({
              to: '/$jobId',
              params: { jobId: item?.slug },
            });
          } else {
            navigate({
              to: `/careers/$organizationId/$jobId`,
              params: {
                organizationId: `${organzationSlug}`,
                jobId: item?.slug,
              },
            });
          }
        }}
        items={
          items && items.length > 0
            ? items
            : [
                {
                  slug: 'slug',
                  title: `${organzationName || ''} Positions`,
                  subtitle: 'Currently no open positions available',
                  content: '',
                  disabled: true,
                },
              ]
        }
      />
    </div>
  );
}

export function CareersView() {
  const { organizationId } = useParams({ strict: false }) as RouterParams;

  const organizationSlug = getOrganizationSlugIfSubdomain();

  const { data: organization, isFetching } = useGetPublicOrganization({
    organizationSlug: organizationSlug || organizationId,
  });

  const { data: positions } = useGetPublicPositions({
    organizationSlug: organization?.slug,
  });
  // fetch public positions
  // fetch public organization details

  if (isFetching) {
    return <Loader />;
  }

  if (!organization) {
    return <ErrorView />;
  }

  const items =
    positions?.map((position) => ({
      slug: position?.slug,
      title: position?.title,
      createdAt: position?.createdAt,
      subtitle: getSubtitleFromPosition(position),
      tags: position.tags,
      content: position.content || '',
    })) || [];

  return (
    <div className="mx-auto flex h-screen w-screen flex-col overflow-y-auto overflow-x-hidden">
      <Header
        logo={organization?.logoUrl ?? ''}
        title={organization?.name ?? ''}
        openPositionCount={items?.length}
        organizationUrl={organization?.parentOrganizationWebUrl}
      />

      <div
        className="flex h-[40vh] min-h-[40vh] flex-col items-center justify-center space-y-6 p-8 text-center"
        style={{
          background: organization?.color,
          color: getContrastColorFromBackgroundColor(organization?.color),
        }}
      >
        <p className="text-3xl font-bold md:text-4xl">
          Join {organization?.name || 'us'}!
        </p>
        <Button
          onClick={() => {
            const openings = document.getElementById('application-form');
            openings?.scrollIntoView({ behavior: 'smooth' });
          }}
          className="p-6 md:text-xl"
        >
          View Openings
        </Button>
      </div>

      <div className="mt-12 flex flex-col justify-between">
        <Content description={organization?.companyDescription || ''} />
        <ApplicationFormView
          items={items}
          organzationSlug={organization?.slug}
          organzationName={organization?.name}
        />

        <Separator />
        <a
          href="https://veton.ai"
          target="_blank"
          className="sticky bottom-0 left-0 flex h-[60px] w-full items-center justify-center border-t border-gray-100 bg-white p-4 text-lg"
        >
          Powered by{' '}
          <img
            alt="powered by veton.ai"
            src={logo}
            style={{ height: 25 }}
            className="ml-1"
          />
        </a>
      </div>
    </div>
  );
}
