import {
  PositionStatus,
  useUpdatePositionMutation,
} from '@/fetchers/usePosition';
import { RouterParams } from '@/main';
import { useParams } from '@tanstack/react-router';
import { toast } from 'sonner';

const useOpenPosition = () => {
  const { organizationId, slug } = useParams({ strict: false }) as RouterParams;
  const updatePositionMutation = useUpdatePositionMutation();
  return async () => {
    const { error } = await updatePositionMutation({
      data: {
        status: PositionStatus.Open,
      },
      organizationId: Number(organizationId),
      slug,
    });

    if (error) {
      toast.error('Failed to publish position');
    } else {
      toast.success('Position published successfully!');
    }
  };
};

export default useOpenPosition;
