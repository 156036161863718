import { AdminDashboardComponent } from '@/components/core/admin/dashboard';
import { Loader } from '@/components/core/loader';
import { useGetAdminDashboardOrganization } from '@/fetchers/useOrganization';
import { createRoute } from '@tanstack/react-router';
import { Route as adminPanelOrganizationsRoute } from './admin-panel.organizations';

export const Route = createRoute({
  path: '/$id',
  getParentRoute: () => adminPanelOrganizationsRoute,
  component: AdminOrganizationsPage,
});

function AdminOrganizationsPage() {
  const { id } = Route.useParams();
  const { data, isFetching } = useGetAdminDashboardOrganization({
    enabled: true,
    organizationId: Number(id),
  });

  if (isFetching) {
    return <Loader />;
  }

  return (
    <div>
      <AdminDashboardComponent
        items={data || []}
        title="Dashboard"
        description="Latest Interviews"
      />
    </div>
  );
}
