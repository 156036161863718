import { useGlobalAlertStore } from '@/lib/useGlobalAlertStore';
import { Alert } from './alert';
import { useEffect, useRef } from 'react';
import { useShallow } from 'zustand/shallow';
import Cookies from 'js-cookie';

const COOKIE_NAME = 'dismissed-global-alert';

export const GlobalAlert = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { globalAlert, clearGlobalAlert, setAlertHeight } = useGlobalAlertStore(
    useShallow((store) => ({
      globalAlert: store.globalAlert,
      clearGlobalAlert: store.clearGlobalAlert,
      setAlertHeight: store.setAlertHeight,
    }))
  );

  useEffect(() => {
    const checkDismissalStatus = () => {
      const dismissedUntil = Cookies.get(COOKIE_NAME);
      if (dismissedUntil) {
        const dismissedTime = parseInt(dismissedUntil);
        if (Date.now() < dismissedTime) {
          clearGlobalAlert();
        } else {
          Cookies.remove(COOKIE_NAME);
        }
      }
    };

    checkDismissalStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDismiss = () => {
    // Set dismissal for 24 hours
    const dismissUntil = Date.now() + 24 * 60 * 60 * 1000;
    Cookies.set(COOKIE_NAME, dismissUntil.toString(), {
      expires: 1, // 1 day
      sameSite: 'strict',
      secure: process.env.NODE_ENV === 'production',
    });
    clearGlobalAlert();
  };

  useEffect(() => {
    if (ref.current) {
      setAlertHeight(ref.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAlert]);

  if (!globalAlert) return null;

  return (
    <Alert
      ref={ref}
      className="z-50 rounded-none"
      onDismiss={handleDismiss}
      show={!!globalAlert}
      {...globalAlert}
    />
  );
};
