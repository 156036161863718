import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@clerk/clerk-react';
import { fetcher } from '@/lib/fetcher';
import { Organization, useUserDetailStore } from './useUserDetails';
import {
  convertToCamelCase,
  convertToSnakeCase,
  handleQueryError,
  parseAPIError,
} from '@/lib/utils';
import { useParams } from '@tanstack/react-router';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  HiringMember,
  TAIConfigForm,
  TApplicationForm,
  TCommunicationForm,
} from './usePosition';
import { toast } from 'sonner';
import { RouterParams } from '@/main';
import { TimeSaved } from './types';
import { ATS_CONNECTIONS } from '@/components/core/ats/constants';
import {
  ATSConnectionAuthMethod,
  ATSConnectionId,
} from '@/components/core/ats/types';

export const PLAN_LIST_PUBLIC: Record<Plan, string> = {
  demo: 'Free Trial',
  free: 'Free Trial',
  admin: 'Admin Plan',
  // v1 monthly
  basic: 'Basic Plan',
  standard: 'Standard Plan',
  premium: 'Premium Plan',
  // v1 annual
  basic_annual: 'Basic - Annual Plan',
  standard_annual: 'Standard - Annual Plan',
  premium_annual: 'Premium - Annual Plan',
  // v2 monthly
  basic_v2: 'Basic Plan',
  standard_v2: 'Standard Plan',
  premium_v2: 'Premium Plan',
  // v2 annual
  basic_annual_v2: 'Basic - Annual Plan',
  standard_annual_v2: 'Standard - Annual Plan',
  premium_annual_v2: 'Premium - Annual Plan',
};

export enum Plan {
  FREE = 'free',
  DEMO = 'demo',
  ADMIN = 'admin',
  // v1 monthly
  BASIC = 'basic',
  STANDARD = 'standard',
  PREMIUM = 'premium',
  // v1 annual
  BASIC_ANNUAL = 'basic_annual',
  STANDARD_ANNUAL = 'standard_annual',
  PREMIUM_ANNUAL = 'premium_annual',
  // v2 monthly
  BASIC_V2 = 'basic_v2',
  STANDARD_V2 = 'standard_v2',
  PREMIUM_V2 = 'premium_v2',
  // v2 annual
  BASIC_ANNUAL_V2 = 'basic_annual_v2',
  STANDARD_ANNUAL_V2 = 'standard_annual_v2',
  PREMIUM_ANNUAL_V2 = 'premium_annual_v2',
}

export const PLAN_LIST_PRIVATE = [
  { plan: Plan.FREE, name: 'Free' },
  { plan: Plan.ADMIN, name: 'Free' },

  { plan: Plan.BASIC_V2, name: 'Basic' },
  { plan: Plan.STANDARD_V2, name: 'Standard' },
  { plan: Plan.PREMIUM_V2, name: 'Premium' },

  { plan: Plan.BASIC_ANNUAL_V2, name: 'Basic Annual' },
  { plan: Plan.STANDARD_ANNUAL_V2, name: 'Standard Annual' },
  { plan: Plan.PREMIUM_ANNUAL_V2, name: 'Premium Annual' },

  { plan: Plan.BASIC, name: 'Legacy Basic Annual' },
  { plan: Plan.STANDARD, name: 'Legacy Standard Annual' },
  { plan: Plan.PREMIUM, name: 'Legacy Premium Annual' },

  { plan: Plan.BASIC_ANNUAL, name: 'Legacy Basic Annual' },
  { plan: Plan.STANDARD_ANNUAL, name: 'Legacy Standard Annual' },
  { plan: Plan.PREMIUM_ANNUAL, name: 'Legacy Premium Annual' },
];

export const ATS_CONNECTION_ALLOWED_PLANS = [
  Plan.DEMO,
  Plan.ADMIN,
  // v1 monthly
  Plan.STANDARD,
  Plan.PREMIUM,
  // v1 annual
  Plan.BASIC_ANNUAL,
  Plan.STANDARD_ANNUAL,
  Plan.PREMIUM_ANNUAL,
  // v2 monthly
  Plan.BASIC_V2,
  Plan.STANDARD_V2,
  Plan.PREMIUM_V2,
  // v2 annual
  Plan.BASIC_ANNUAL_V2,
  Plan.STANDARD_ANNUAL_V2,
  Plan.PREMIUM_ANNUAL_V2,
];

export type ParentOrganization = {
  id: number;
  name: string;
  logoUrl: string;
  memberId: number;
  memberRole: string;
  webUrl: string;
  plan: Plan;
};

export type TeamMember = {
  id: number;
  email: string;
  role: string;
  username: string;
  fullName?: string;
  imageUrl?: string;
};

export function isAtsAvailableForPlan(plan: Plan) {
  return ATS_CONNECTION_ALLOWED_PLANS.includes(plan);
}

export function useUpdateOrganization() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { setSelectedOrganization } = useUserDetailStore();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      parentOrganizationId,
      payload,
    }: {
      organizationId: number;
      parentOrganizationId: number;
      payload: {
        name: string;
        logo?: File;
        color?: string;
        companyDescription?: string;
        slug?: string;
        companyReachOutEmail: string;
      };
    }) => {
      const formData = new FormData();
      const { logo, ...rest } = payload;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const snakePayload: any = convertToSnakeCase(rest);

      if (logo) {
        formData.append('logo', logo);
      }
      Object.keys(snakePayload).forEach((key) => {
        formData.append(key, snakePayload[key]);
      });

      return await fetcher
        .post<Organization>(
          `/organizations/${organizationId}?parent_organization_id=${parentOrganizationId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(r.data) as unknown as Organization;
          setSelectedOrganization(data);
          queryClient.invalidateQueries({
            queryKey: ['user-details'],
          });
          queryClient.invalidateQueries({
            queryKey: ['admin', 'organizations'],
          });

          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useGetPublicOrganization({
  organizationName,
  organizationSlug,
}: {
  organizationName?: string;
  organizationSlug?: string;
}) {
  return useQuery({
    retry: 0,
    queryKey: ['public-organization', organizationName ?? organizationSlug],
    queryFn: async () => {
      let url = `/organizations?`;

      if (organizationName) {
        url += `organization_name=${organizationName}`;
      } else if (organizationSlug) {
        url += `organization_slug=${organizationSlug}`;
      }

      return fetcher
        .get<Organization>(url)
        .then((res) => {
          const organization = convertToCamelCase(res.data);

          return organization as unknown as Organization;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },

    enabled: !!organizationName || !!organizationSlug,
  });
}

export function useGetOrganizations({ enabled = true }: { enabled?: boolean }) {
  const { getToken } = useAuth();
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  // const { data: details } = useUserDetails();
  const { setSelectedOrganization } = useUserDetailStore();

  const { selectedParentOrganization, setSelectedParentOrganization } =
    useOrganizationStore();

  return useQuery({
    queryKey: ['admin', 'organizations', selectedParentOrganization?.memberId],
    queryFn: async () => {
      return fetcher
        .get<Organization[]>(
          `/admin/organizations?membership_id=${selectedParentOrganization?.memberId}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const organizations = res.data
            .map((organization) => convertToCamelCase(organization))
            .map((o) => ({
              ...o,
              integrationName: ATS_CONNECTIONS.find(
                (c) => c.id === o?.integrationName
              )?.name,
            })) as unknown as Organization[];

          if (organizationId) {
            const selectedOrganization = organizations.find(
              (org) => org.id === Number(organizationId)
            );
            selectedOrganization &&
              setSelectedOrganization(selectedOrganization);
          }

          return organizations;
        })
        .catch((error) => {
          setSelectedParentOrganization(undefined);
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled && !!selectedParentOrganization?.memberId,
  });
}

export function useGetMemberships({ enabled = true }: { enabled?: boolean }) {
  const { getToken } = useAuth();
  const { selectedParentOrganization, setSelectedParentOrganization } =
    useOrganizationStore();

  return useQuery({
    queryKey: ['parent', 'memberships'],
    queryFn: async () => {
      return fetcher
        .get<ParentOrganization[]>(`/parent/organizations/memberships`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const organizations = res.data.map((organization) =>
            convertToCamelCase(organization)
          ) as unknown as ParentOrganization[];

          if (!selectedParentOrganization) {
            setSelectedParentOrganization(organizations?.[0]);
          } else {
            const selected = organizations.find(
              (org) => org.id === selectedParentOrganization?.id
            );

            if (selected) {
              setSelectedParentOrganization(selected);
            }
          }

          return organizations;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled,
  });
}

export function useGetTeamList() {
  const { getToken } = useAuth();
  const { selectedParentOrganization } = useOrganizationStore();

  return useQuery({
    queryKey: ['parent', 'team-list', selectedParentOrganization?.id],
    queryFn: async () => {
      return fetcher
        .get<TeamMember[]>(
          `/parent/organizations/${selectedParentOrganization?.id}/memberships`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const organizations = res.data.map((organization) =>
            convertToCamelCase(organization)
          ) as unknown as TeamMember[];

          return organizations;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: !!selectedParentOrganization?.id,
  });
}

export function useInviteNewTeamMember() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { selectedParentOrganization } = useOrganizationStore();

  const mutation = useMutation({
    mutationFn: async ({
      email,
      role,
      message,
    }: {
      email: string;
      role: string;
      message?: string;
    }) => {
      return fetcher
        .post<TeamMember>(
          `/parent/organizations/${selectedParentOrganization?.id}/memberships`,
          {
            email: email?.toLowerCase(),
            role,
            message,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const member = convertToCamelCase(res.data) as unknown as TeamMember;

          queryClient.invalidateQueries({
            queryKey: ['parent', 'team-list', selectedParentOrganization?.id],
          });

          return member;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useToggleTeamMemberRole() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { selectedParentOrganization } = useOrganizationStore();

  const mutation = useMutation({
    mutationFn: async ({
      memberId,
      role,
      remove,
    }: {
      memberId: number;
      role?: string;
      remove?: boolean;
    }) => {
      return fetcher
        .patch<TeamMember>(
          `/parent/organizations/${selectedParentOrganization?.id}/memberships/${memberId}`,
          {
            role,
            remove,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const member = convertToCamelCase(res.data) as unknown as TeamMember;

          queryClient.invalidateQueries({
            queryKey: ['parent', 'team-list', selectedParentOrganization?.id],
          });

          return member;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useCreateParentOrganization() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      name,
      logo,
      webUrl,
    }: {
      name: string;
      logo?: File;
      webUrl?: string;
    }) => {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('web_url', webUrl || '');
      if (logo) {
        formData.append('logo', logo);
      }

      return fetcher
        .post<ParentOrganization>(`/parent/organizations/`, formData, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          const organization = convertToCamelCase(
            res.data
          ) as unknown as ParentOrganization;

          // setSelectedParentOrganization(organization);

          queryClient.invalidateQueries({
            queryKey: ['parent', 'memberships'],
          });

          return organization;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useUpdateParentOrganization() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { selectedParentOrganization } = useOrganizationStore();
  const mutation = useMutation({
    mutationFn: async ({
      name,
      logo,
      webUrl,
    }: {
      name: string;
      logo?: File;
      webUrl?: string;
    }) => {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('web_url', webUrl || '');

      if (logo) {
        formData.append('logo', logo);
      }

      return fetcher
        .post<ParentOrganization>(
          `/parent/organizations/${selectedParentOrganization?.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((res) => {
          const organization = convertToCamelCase(
            res.data
          ) as unknown as ParentOrganization;

          queryClient.invalidateQueries({
            queryKey: ['parent', 'memberships'],
          });

          queryClient.invalidateQueries({
            queryKey: [
              'admin',
              'organizations',
              selectedParentOrganization?.memberId,
            ],
          });

          return organization;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export const useOrganizationStore = create<{
  selectedParentOrganization?: ParentOrganization;
  setSelectedParentOrganization: (
    parentOrganization?: ParentOrganization
  ) => void;
}>()(
  persist(
    (set) => ({
      selectedParentOrganization: undefined,
      setSelectedParentOrganization: (
        parentOrganization?: ParentOrganization
      ) => set({ selectedParentOrganization: parentOrganization }),
    }),
    {
      name: 'organization-store',
    }
  )
);

export function useGetMergeToken({
  organizationId,
}: {
  organizationId: number;
}) {
  const { getToken } = useAuth();

  return useQuery({
    retry: 1,
    queryKey: ['merge-token', organizationId],
    queryFn: async () => {
      const url = `/admin/organizations/${organizationId}/create-link-token`;

      return fetcher
        .get<{ token: string; integration_name: string }>(url, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const organization = convertToCamelCase(res.data);

          console.log('useGetMergeToken RES', res?.data, organization);

          return organization as unknown as {
            token: string;
            integrationName: string;
          };
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },

    enabled: !!organizationId,
  });
}

export function useUpdateMergeToken() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      mergePublicToken,
      integrationName,
    }: {
      organizationId: number;
      mergePublicToken?: string;
      integrationName?: string;
    }) => {
      return await fetcher
        .post<Organization>(
          `/admin/organizations/${organizationId}/merge-connect`,
          {
            token: mergePublicToken,
            integration_name: integrationName,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(r.data) as unknown as Organization;
          // queryKey: ["admin", "organizations", selectedParentOrganization?.memberId],
          queryClient.invalidateQueries({
            queryKey: ['admin', 'organizations'],
          });
          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

type IntegrationResponse = { message: string; integrationId: number };

export function useATSConnect() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      credentials,
      organizationId,
      authMethod,
      integrationType,
    }: {
      credentials: Record<string, string>;
      organizationId: string;
      authMethod: ATSConnectionAuthMethod;
      integrationType: ATSConnectionId;
    }) => {
      return await fetcher
        .post<IntegrationResponse>(
          `/ats/connect`,
          {
            organization_id: Number(organizationId),
            integration_type: integrationType,
            auth_method: authMethod,
            credentials: credentials,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(
            r.data
          ) as unknown as IntegrationResponse;
          queryClient.invalidateQueries({
            queryKey: ['admin', 'organizations'],
          });
          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useATSDisconnect() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      integrationType,
    }: {
      organizationId: string;
      integrationType?: string;
    }) => {
      if (!organizationId || !integrationType) {
        throw new Error('Invalid organizationId or integrationType');
      }
      return await fetcher
        .delete(
          `/ats/disconnect/${integrationType}?organization_id=${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => {
          const data = convertToCamelCase(
            r.data
          ) as unknown as IntegrationResponse;
          // queryKey: ["admin", "organizations", selectedParentOrganization?.memberId],
          queryClient.invalidateQueries({
            queryKey: ['admin', 'organizations'],
          });
          return data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export type BillingStanding = 'headsup' | 'warning' | 'critical' | 'normal';

type TBilling = {
  interviewTotal: number;
  applicationTotal: number;
  portalUrl?: string;
  currentPlan?: string;
  amount: number;
  isMonthly: boolean;
  maxInterviewAllowed: number;
  nextRenewalDate: string;
  atsIntegration: boolean;
  standing: BillingStanding;
  planStatus?: string;
};
export function useGetBilling({ enabled = true }: { enabled?: boolean }) {
  const { getToken } = useAuth();
  const { selectedParentOrganization } = useOrganizationStore();

  return useQuery({
    queryKey: ['parent', 'billing', selectedParentOrganization?.id],
    queryFn: async () => {
      return fetcher
        .get<TBilling>(
          `/parent/organizations/${selectedParentOrganization?.id}/billing`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(res.data) as unknown as TBilling;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: !!selectedParentOrganization?.id && enabled,
  });
}

export type TAdminDashboard = {
  id: number;
  applicationId: number;
  completedAt: string;
  organizationName: string;
  reminderCount: number;
  positionTitle: string;
  finalVerdict: string;
  applicantName: string;
  applicantEmail: string;
  organizationId: number;
  slug: string;
};

export function useGetAdminDashboard({
  enabled = true,
}: {
  enabled?: boolean;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['admin-panel', 'dashboard'],
    queryFn: async () => {
      return fetcher
        .get<TAdminDashboard>(`/admin/organizations/dashboard`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          return convertToCamelCase(res.data) as unknown as TAdminDashboard[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled,
  });
}

export function useGetAdminDashboardOrganization({
  enabled = true,
  organizationId: organizationId,
}: {
  enabled?: boolean;
  organizationId: number;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['admin-panel', 'dashboard', organizationId],
    queryFn: async () => {
      return fetcher
        .get<TAdminDashboard>(
          `/admin/organizations/dashboard/${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(res.data) as unknown as TAdminDashboard[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled && !!organizationId,
  });
}

type TAdminDashboardOrganization = {
  id: number;
  name: string;
  plan: Plan;
  completedInterviewCount: number;
  stripeCustomerId?: string;
};
export function useAdminGetAllParentOrganizations({
  enabled = true,
}: {
  enabled?: boolean;
}) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: ['admin-panel', 'dashboard', 'parent_organizations'],
    queryFn: async () => {
      return fetcher
        .get<TAdminDashboardOrganization>(
          `/admin/organizations/dashboard/parent_organizations`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(
            res.data
          ) as unknown as TAdminDashboardOrganization[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    retry: 1,
    enabled: enabled,
  });
}

export function useAdminTeamMemberUpdate() {
  const { getToken } = useAuth();
  const { setSelectedParentOrganization } = useOrganizationStore();

  const mutation = useMutation({
    mutationFn: async ({
      parentOrganizationId,
      action,
    }: {
      parentOrganizationId: number;
      action?: string;
    }) => {
      return fetcher
        .patch(
          `/admin/organizations/dashboard/parent/${parentOrganizationId}/team`,
          {
            action,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          setSelectedParentOrganization();
          window.location.reload();

          return res.data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useAdminParentOrganizationPlanUpdate() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      parentOrganizationId,
      plan,
      stripeCustomerId,
    }: {
      parentOrganizationId: number;
      stripeCustomerId?: string;
      plan?: string;
    }) => {
      return fetcher
        .patch(
          `/admin/organizations/dashboard/parent/${parentOrganizationId}/plan`,
          {
            plan,
            stripe_customer_id: stripeCustomerId,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          queryClient.invalidateQueries({
            queryKey: ['admin-panel', 'dashboard'],
          });

          return res.data;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useGetOrganizationApplicationFormConfig({
  enabled = true,
  organizationId,
}: {
  enabled?: boolean;
  organizationId: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['org-application-form', organizationId],
    queryFn: async () =>
      fetcher
        .get<TApplicationForm>(
          `/organizations/${organizationId}/application-form`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const aiConfigForm = convertToCamelCase(res.data);

          return aiConfigForm as unknown as TApplicationForm;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!organizationId,
  });
}

export function useUpdateOrganizationApplicationFormConfigMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
    }: {
      data: Partial<TApplicationForm>;
      organizationId: string;
    }) => {
      return await fetcher
        .patch(
          `/organizations/${organizationId}/application-form`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const applicationForm = convertToCamelCase(res.data);

          return {
            data: applicationForm as unknown as TApplicationForm,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({
        queryKey: ['org-application-form', organizationId],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useGetOrganizationCommunicationFormConfig({
  enabled = true,
  organizationId,
}: {
  enabled?: boolean;
  organizationId: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['org-communication-form', organizationId],
    queryFn: async () =>
      fetcher
        .get<TCommunicationForm>(
          `/organizations/${organizationId}/communication-form`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const aiConfigForm = convertToCamelCase(res.data);

          return aiConfigForm as unknown as TCommunicationForm;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!organizationId,
  });
}

export function useUpdateOrganizationCommunicationFormConfigMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
    }: {
      data: Partial<TCommunicationForm>;
      organizationId: string;
    }) => {
      return await fetcher
        .patch(
          `/organizations/${organizationId}/communication-form`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const communicationForm = convertToCamelCase(res.data);

          return {
            data: communicationForm as unknown as TCommunicationForm,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({
        queryKey: ['org-communication-form', organizationId],
      });
      queryClient.invalidateQueries({
        queryKey: ['org-ai-config', organizationId],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useGetOrganizationAIConfig({
  enabled = true,
  organizationId,
}: {
  enabled?: boolean;
  organizationId: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['org-ai-config', organizationId],
    queryFn: async () =>
      fetcher
        .get<TAIConfigForm>(`/organizations/${organizationId}/ai-config`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const aiConfigForm = convertToCamelCase(res.data);

          return aiConfigForm as unknown as TAIConfigForm;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!organizationId,
  });
}

export function useUpdateOrganizationAIConfigMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      data,
      organizationId,
    }: {
      data: Partial<TAIConfigForm>;
      organizationId: string;
    }) => {
      return await fetcher
        .patch(
          `/organizations/${organizationId}/ai-config`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          const aiConfig = convertToCamelCase(res.data);

          return {
            data: aiConfig as unknown as TAIConfigForm,
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({
        queryKey: ['org-ai-config', organizationId],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useGetOrganizationHiringTeam({
  enabled = true,
  organizationId,
}: {
  enabled?: boolean;
  organizationId: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['org-hiring-team', organizationId],
    queryFn: async () =>
      fetcher
        .get<HiringMember[]>(`/organizations/${organizationId}/hiring-team`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          const hiringTeam = convertToCamelCase(res.data);

          return hiringTeam as unknown as HiringMember[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!organizationId,
  });
}

export function useAddMemberToOrganizationHiringTeamMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      memberId,
      organizationId,
    }: {
      memberId: number;
      organizationId: string;
    }) => {
      return await fetcher
        .post(
          `/organizations/${organizationId}/hiring-team`,
          {
            member: memberId,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(() => {
          return {
            data: { success: true },
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({
        queryKey: ['org-hiring-team', organizationId],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useRemoveMemberFromOrganizationHiringTeamMutation() {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const mutation = useMutation({
    mutationFn: async ({
      organizationId,
      memberId,
    }: {
      organizationId: string;
      memberId: number;
    }) => {
      return await fetcher
        .delete(`/organizations/${organizationId}/hiring-team/${memberId}`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then(() => {
          return {
            data: { success: true },
            error: null,
          };
        })
        .catch((e) => {
          toast.error(e.response.data.detail || 'Something went wrong');

          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({
        queryKey: ['org-hiring-team', organizationId],
      });
    },
  });

  return mutation.mutateAsync;
}

export function useGetOrganizationTimeSaved({
  enabled = true,
  organizationId,
}: {
  enabled?: boolean;
  organizationId: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['org-time-saved', organizationId],
    queryFn: async () =>
      fetcher
        .get<TimeSaved>(`/organizations/${organizationId}/human-hours-saved`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((res) => {
          return convertToCamelCase(res.data) as TimeSaved;
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        }),
    enabled: !!enabled && !!organizationId,
  });
}

export type AIInterviewer = {
  id: number;
  name: string;
  type: string;
  sampleUrl: string;
  imageUrl: string;
};

export function useGetOrganizationAIInterviewers({
  enabled = true,
  organizationId,
  language,
}: {
  enabled?: boolean;
  organizationId: string;
  language?: string;
}) {
  const { getToken } = useAuth();
  return useQuery({
    queryKey: ['org-ai-interviewers', organizationId, language],
    queryFn: async () => {
      return fetcher
        .get<AIInterviewer[]>(
          `/organizations/${organizationId}/interviewers?language=${language}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((res) => {
          return convertToCamelCase(res.data) as AIInterviewer[];
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
    enabled: !!enabled && !!organizationId && !!language,
  });
}
