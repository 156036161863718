import AlertModal from '@/components/core/common/AlertModal';
import { RequiredStar } from '@/components/core/common/RequiredStar';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useClonePositionMutation } from '@/fetchers/usePosition';
import { RouterParams } from '@/main';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

const schema = z.object({
  title: z.string().min(1, 'Title is required'),
});

export type FormValues = z.infer<typeof schema>;

const useCloneAlertModal = ({ positionId }: { positionId: number }) => {
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const [isCloneAlertModalOpen, setIsCloneAlertModalOpen] = useState(false);
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: '',
    },
    mode: 'onChange',
  });
  const { control, handleSubmit, setValue } = form;
  const clonePosition = useClonePositionMutation();
  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    const { data } = await clonePosition({
      organizationId,
      positionId,
      title: values.title,
    });
    if (data?.slug) {
      toast.success('Position cloned successfully');
      navigate({
        to: `/organizations/${organizationId}/positions/manage/${data.slug}/details`,
      });
    }
  };

  const handleOpenCloneAlertModal = (positionTitle: string) => {
    setValue('title', positionTitle);
    setIsCloneAlertModalOpen(true);
  };

  const content = (
    <div className="flex flex-col">
      <FormField
        control={control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              Position Title
              <RequiredStar />
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );

  const CloneAlertModal = (
    <Form {...form}>
      <AlertModal
        open={isCloneAlertModalOpen}
        onClose={() => setIsCloneAlertModalOpen(false)}
        title="You are about to clone this position"
        content={content}
        confirmText="Clone"
        type="info"
        isForm
        onSubmit={handleSubmit(onSubmit)}
        noIcon
      />
    </Form>
  );

  return {
    isCloneAlertModalOpen,
    CloneAlertModal,
    handleOpenCloneAlertModal,
  };
};

export default useCloneAlertModal;
