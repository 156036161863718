import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';
import { useIsMutating } from '@tanstack/react-query';
import {
  handleMapFormValuesToData,
  PositionCommunicationFormValues,
  usePositionCommunicationForm,
} from '@/lib/usePositionCommunicationForm';
import { PositionCommunicationForm } from '@/components/core/position/communication/communication-form';
import {
  useGetOrganizationCommunicationFormConfig,
  useUpdateOrganizationCommunicationFormConfigMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { mergeDataWithDefaults } from '@/components/core/position/communication/form-defaults';
import StickyActionBar from '@/components/core/sticky-action-bar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/communication',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsCommunicationPage,
});

function CompanyPositionDefaultsCommunicationPage() {
  const { organizationId } = Route.useParams();
  const isMutating = useIsMutating();
  const form = usePositionCommunicationForm();
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const { data: organizationCommunicationFormConfig } =
    useGetOrganizationCommunicationFormConfig({
      organizationId,
    });
  const updateOrganizationCommunicationFormConfig =
    useUpdateOrganizationCommunicationFormConfigMutation();
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (organizationCommunicationFormConfig) {
      reset({
        ...mergeDataWithDefaults(organizationCommunicationFormConfig),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCommunicationFormConfig]);

  const onSubmit = async (values: PositionCommunicationFormValues) => {
    const { error, data: updatedForm } =
      await updateOrganizationCommunicationFormConfig({
        data: handleMapFormValuesToData(values),
        organizationId,
      });
    if (error) {
      toast.error(error);
    } else {
      toast.success('Communication form updated successfully!');
      if (updatedForm) {
        reset({
          ...mergeDataWithDefaults(updatedForm),
          isInitialized: true,
        });
      }
    }
  };

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8">
          <PositionCommunicationForm />
          {isFormInitialized && (
            <StickyActionBar
              isSaveLoading={isMutating > 0}
              isSaveDisabled={isMutating > 0 || !isDirty}
            />
          )}
        </form>
      </Form>
    </>
  );
}
