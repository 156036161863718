import { createRoute } from '@tanstack/react-router';
import { Notifications } from '@/components/core/notifications';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { Layout } from '@/components/core/dashboard/layout';
import { Route as RootRoute } from './__root';

export const Route = createRoute({
  path: '/user/notifications',
  getParentRoute: () => RootRoute,
  component: UserNotificationPage,
});

function UserNotificationPage() {
  return (
    <Layout>
      <Breadcrumb
        items={[
          {
            title: 'Notifications',
          },
        ]}
      />
      <Notifications />
    </Layout>
  );
}
