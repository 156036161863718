import { TCommunicationForm } from '@/fetchers/usePosition';
import {
  positionCommunicationFormDefaultValues,
  PositionCommunicationFormValues,
} from '@/lib/usePositionCommunicationForm';

export const mergeDataWithDefaults = (
  data?: TCommunicationForm
): PositionCommunicationFormValues => {
  return {
    language: data?.language || positionCommunicationFormDefaultValues.language,
    selectedMethod:
      data?.selectedMethod ||
      positionCommunicationFormDefaultValues.selectedMethod,

    afterApplication:
      data?.afterApplication ||
      positionCommunicationFormDefaultValues.afterApplication,
    afterApplicationSubject:
      data?.afterApplicationSubject ||
      positionCommunicationFormDefaultValues.afterApplicationSubject,
    afterInterview:
      data?.afterInterview ||
      positionCommunicationFormDefaultValues.afterInterview,
    afterInterviewSubject:
      data?.afterInterviewSubject ||
      positionCommunicationFormDefaultValues.afterInterviewSubject,

    interviewType:
      data?.interviewType ||
      positionCommunicationFormDefaultValues.interviewType,
    interviewDelay:
      data?.interviewDelay ||
      positionCommunicationFormDefaultValues.interviewDelay,

    autoSendOption:
      data?.autoSendOption ||
      positionCommunicationFormDefaultValues.autoSendOption,

    disqualified:
      data?.disqualified || positionCommunicationFormDefaultValues.disqualified,
    disqualifiedSubject:
      data?.disqualifiedSubject ||
      positionCommunicationFormDefaultValues.disqualifiedSubject,

    disqualifiedToggle: data?.disqualified ? 'enabled' : 'disabled',
    autoInterview: data?.autoInterview ?? false,
  };
};
