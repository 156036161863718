import { Toaster as Sonner } from 'sonner';
import './sonner.css';
type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme="light"
      className="toaster group"
      position="top-center"
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:shadow-lg h-[unset] min-h-[30px] py-2 rounded-sm border',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          success: 'border-green-600 text-green-600',
          info: 'border-sky-600 text-sky-600',
          warning: 'border-orange-600 text-orange-600',
          error: 'border-red-600 text-red-600',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
