import { Button } from '@/components/ui/button';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InsertVariables({
  customRef,
  onVariableClick,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customRef: any;
  onVariableClick?: (value: string) => void;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function insertVariable(ref: any, variable: string) {
    try {
      const editor = ref.current.getEditor();
      const cursorPosition = editor.getSelection().index;
      editor.insertText(cursorPosition, variable);
      editor.setSelection(cursorPosition + variable.length + 1);
    } catch {
      console.log('Error inserting variable');
    }
  }

  const variables = [
    { label: 'Company Name', value: ' {{company_name}} ' },
    { label: "Candidate's Name", value: ' {{candidate_name}} ' },
    { label: 'Position Name', value: ' {{position_name}} ' },
    // { label: "Interview url", value: " {{interview_url}} " },
    // { label: "Interview Date", value: " {{interview_date}} " },
    { label: 'Company Email', value: ' {{company_email}} ' },
  ];

  return (
    <div className="-mt-2 lg:-mt-6">
      <p>Available variables:</p>
      <div className="">
        {variables.map((variable) => (
          <Button
            key={variable.value}
            className="mb-1 mr-1 h-6 rounded-xl p-2 text-xs"
            onClick={(event) => {
              event.preventDefault();
              onVariableClick?.(variable.value);
              insertVariable(customRef, variable.value);
            }}
          >
            {variable.label}
          </Button>
        ))}
      </div>
    </div>
  );
}
