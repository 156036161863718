import { CareerView } from '@/components/core/careers/single';
import { Loader } from '@/components/core/loader';
import { useGetPublicOrganization } from '@/fetchers/useOrganization';
import { useGetPublicPosition } from '@/fetchers/usePosition';
import { createRoute, Navigate } from '@tanstack/react-router';
import { Route as careersRoute } from './careers.$organizationId';

export const Route = createRoute({
  path: '/$jobId',
  getParentRoute: () => careersRoute,
  component: View,
});

function View() {
  const { jobId, organizationId } = Route.useParams();
  const { data, isFetching: isFetchingPosition } = useGetPublicPosition({
    slug: jobId,
  });

  const { data: organization, isFetching } = useGetPublicOrganization({
    organizationSlug: organizationId,
  });

  if (isFetching || isFetchingPosition) {
    return <Loader />;
  }

  if (data?.organizationSlug !== organization?.slug) {
    return (
      <Navigate to="/careers/$organizationId" params={{ organizationId }} />
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <CareerView position={data} organization={organization} />
    </>
  );
}
