import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef, useState } from 'react';

import { ItemPreview } from '@/components/core/management/item-preview';
import { cn } from '@/lib/utils';
import { useDndContext } from '@dnd-kit/core';
import { LucideIcon } from 'lucide-react';

type TItem = {
  slug: string;
  title: string;
  // status: string;
  // type: string;
  subtitle: string;
  // sessionId: number;
  content: string | null;
  createdAt: string | null;
  tags: string[];
  isOpen: boolean;
  disabled?: boolean;
  // created_at: string;
  // started_at: string | null;
  // updated_at: string;
};

export type ItemAction = {
  title: string;
  onClick: () => void;
  customClasses?: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: LucideIcon;
};

export type ItemListProps = {
  dndEnabled?: boolean;
  layoutClasses?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[] | TItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (item: any | TItem) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdownActions?: (item: any) => ItemAction[];
  disableVirtualHeight?: boolean;
};

export function ItemList({
  items,
  layoutClasses,
  onClick,
  dropdownActions,
  dndEnabled = false,
  disableVirtualHeight,
}: Readonly<ItemListProps>) {
  // const { setPanelItem } = usePanelStore()
  const parentRef = useRef<HTMLDivElement>(null);
  const [itemSizes, setItemSizes] = useState<number[]>([]);
  const { active } = useDndContext();

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => (itemSizes[index] || 100) + 8, // Add 8px for margin
    overscan: 5,
    measureElement: (element) => {
      const height = element.getBoundingClientRect().height;
      setItemSizes((prev) => {
        const newSizes = [...prev];
        const index =
          rowVirtualizer.getVirtualItems()[0].index +
          parseInt((element as HTMLElement).dataset.index || '0');
        newSizes[index] = height;
        return newSizes;
      });
      return height + 8; // Add 16px for margin
    },
  });

  return (
    <div
      ref={parentRef}
      className={cn(
        'no-scrollbar w-full overflow-y-auto',
        active && 'overflow-y-visible',
        layoutClasses
      )}
    >
      <div
        style={{
          height: disableVirtualHeight
            ? undefined
            : `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const item = items[virtualItem.index];
          return (
            <div
              key={virtualItem.key}
              data-index={virtualItem.index}
              ref={rowVirtualizer.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
                marginBottom: '8px', // Add margin here,
              }}
            >
              <ItemPreview
                draggable={dndEnabled}
                id={item?.id}
                title={item?.title}
                titleJSX={item?.titleJSX}
                completedAt={item?.createdAt}
                subtitle={item?.subtitle}
                tags={item?.tags || []}
                onClick={() => onClick?.(item)}
                content={item?.content}
                dropdownActions={dropdownActions?.(item)}
                isOpen={item?.isOpen}
                disabled={item?.disabled}
                applicantCount={item?.applicantCount}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
