import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';

import { HiringTeamForm } from '@/components/core/position/hiring-team/hiring-team-form';
import { usePositionHiringTeamForm } from '@/lib/usePositionHiringTeamForm';
import {
  TeamMember,
  useAddMemberToOrganizationHiringTeamMutation,
  useGetOrganizationHiringTeam,
  useRemoveMemberFromOrganizationHiringTeamMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { HiringMember } from '@/fetchers/usePosition';
import { toast } from 'sonner';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/hiring-team',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsHiringTeamPage,
});

function CompanyPositionDefaultsHiringTeamPage() {
  const { organizationId } = Route.useParams();
  const form = usePositionHiringTeamForm();
  const { data: organizationHiringTeamData } = useGetOrganizationHiringTeam({
    organizationId,
  });
  const addMemberToOrganizationHiringTeamMutation =
    useAddMemberToOrganizationHiringTeamMutation();
  const removeMemberFromOrganizationHiringTeamMutation =
    useRemoveMemberFromOrganizationHiringTeamMutation();
  const { reset } = form;

  useEffect(() => {
    if (organizationHiringTeamData) {
      reset({
        hiringTeamMembers: organizationHiringTeamData,
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationHiringTeamData]);

  const handleRemoveMember = async (member: HiringMember) => {
    const res = await removeMemberFromOrganizationHiringTeamMutation({
      organizationId,
      memberId: member.user.id,
    });
    if (res.data?.success) {
      toast.success('Member has been removed successfully!');
    } else {
      toast.error(res.error);
    }
  };

  const handleAddMember = async (member: TeamMember) => {
    const res = await addMemberToOrganizationHiringTeamMutation({
      organizationId,
      memberId: member.id,
    });
    if (res.data?.success) {
      toast.success('Member has been added successfully!');
    } else {
      toast.error(res.error);
    }
  };

  const handleAddAllMembers = async () => {
    const res = await addMemberToOrganizationHiringTeamMutation({
      organizationId,
      memberId: -1,
    });
    if (res.data?.success) {
      toast.success('All members have been added successfully!');
    } else {
      toast.error(res.error);
    }
  };

  return (
    <Form {...form}>
      <form className="w-full space-y-8">
        <HiringTeamForm
          onAddMember={handleAddMember}
          onRemoveMember={handleRemoveMember}
          onAddAllMembers={handleAddAllMembers}
        />
      </form>
    </Form>
  );
}
