import { Loader } from '@/components/core/loader';
import {
  createRoute,
  ErrorComponent,
  Outlet,
  redirect,
} from '@tanstack/react-router';
import { Route as RootRoute } from './__root';
import { AdminSidebarLayout } from '@/components/core/position/layout';
import { Layout } from '@/components/core/dashboard/layout';

export const Route = createRoute({
  path: '/admin-panel',
  getParentRoute: () => RootRoute,
  beforeLoad: ({ context, location }) => {
    const isSignedIn = context?.auth?.isSignedIn;
    if (!isSignedIn) {
      throw redirect({
        to: '/signin',
        search: {
          redirect_url: location.href,
        },
      });
    }
  },

  errorComponent: ({ error }) => {
    console.log('error @organizations', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: AdminPanelComponent,
});

function AdminPanelComponent() {
  return (
    <Layout>
      <AdminSidebarLayout
        title="Veton Admin Panel"
        subtitle="With great power comes great responsibility"
        navLinks={[
          {
            title: 'Dashboard',
            href: `/admin-panel`,
          },
          {
            title: 'Organizations',
            href: '/admin-panel/organizations',
          },

          {
            title: 'Create Organization',
            href: '/admin-panel/create-organization',
          },
        ]}
      >
        <Outlet />
      </AdminSidebarLayout>
    </Layout>
  );
}
