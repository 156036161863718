import {
  interviewVerdictValues,
  resumeVerdictValues,
} from '@/components/core/applications/constants';
import {
  FinalVerdict,
  ResumeAnalysisVerdict,
} from '@/components/core/applications/types';
import { BadgeProps } from '@/components/ui/badge';
import {
  ApplicantInterview,
  Application,
  useGetApplications,
} from '@/fetchers/useApplication';
import { RouterParams } from '@/main';
import { useParams } from '@tanstack/react-router';

export type Applicant = {
  title: string;
  resumeVerdict: string;
  interviewVerdict: string;
  resumeBadgeVariant: BadgeProps['variant'];
  interviewBadgeVariant: BadgeProps['variant'];
  interview?: ApplicantInterview;
  application: Application;
  shortlisted: boolean;
};

export function useApplicants({ searchQuery }: { searchQuery: string }) {
  const { slug } = useParams({ strict: false }) as RouterParams;
  const { data: applications = [], isFetching } = useGetApplications({ slug });
  const allItems: Applicant[] = applications.map((application) => {
    const interview = application.interviews?.[0];

    const resumeBadgeVariant =
      application.resumeAnalysisVerdict ===
      ResumeAnalysisVerdict.INTERVIEW_RECOMMENDED
        ? 'default'
        : 'secondary';
    const interviewBadgeVariant =
      interview?.finalVerdict &&
      [FinalVerdict.GOOD_FIT].includes(interview?.finalVerdict)
        ? 'default'
        : 'secondary';

    const isInterviewInProgress =
      interview?.startedAt && !interview?.completedAt;

    return {
      title: application.name,
      interview,
      shortlisted: application.shortlisted,
      resumeVerdict: resumeVerdictValues[application.resumeAnalysisVerdict],
      interviewVerdict: isInterviewInProgress
        ? 'In Progress'
        : interview?.finalVerdict
          ? interviewVerdictValues[interview.finalVerdict]
          : '',
      resumeBadgeVariant,
      interviewBadgeVariant,
      application,
    };
  });

  const applicants =
    allItems?.filter((item) => {
      const matchesTitle =
        item.title.toLowerCase().split(searchQuery.toLowerCase()).length > 1;
      const matchesEmail =
        item.application.email.toLowerCase().split(searchQuery.toLowerCase())
          .length > 1;
      const matchesPhone =
        item.application.phone.toLowerCase().split(searchQuery.toLowerCase())
          .length > 1;

      return matchesTitle || matchesEmail || matchesPhone;
    }) ?? [];

  return { applicants, isFetching };
}
