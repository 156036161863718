import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './locales/de.json';
import en from './locales/en-US.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import nl from './locales/nl.json';
import tr from './locales/tr.json';

export enum Language {
  DE = 'de',
  EN_US = 'en-US',
  ES = 'es',
  FR = 'fr',
  NL = 'nl',
  TR = 'tr',
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      [Language.DE]: de,
      [Language.EN_US]: en,
      [Language.ES]: es,
      [Language.FR]: fr,
      [Language.NL]: nl,
      [Language.TR]: tr,
    },
    lng: Language.EN_US,
    fallbackLng: Language.EN_US,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
