import { cn } from '@/lib/utils';
import { Ellipsis } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { ItemAction } from '@/pages/dashboard/components/item-list';

export function ItemActions({
  dropdownActions,
}: {
  dropdownActions: ItemAction[];
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-6" size="icon">
          <Ellipsis />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          {dropdownActions.map((action, idx) => (
            <DropdownMenuItem
              key={idx}
              onClick={action.onClick}
              role="button"
              disabled={action.disabled}
              className={cn(
                'cursor-pointer',
                action.customClasses,
                action.hidden && 'hidden'
              )}
            >
              {action.icon && <action.icon className="mr-1 size-4" />}
              {action.title}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
