import AlertModal from '@/components/core/common/AlertModal';
import {
  PositionStatus,
  TPosition,
  useUpdatePositionMutation,
} from '@/fetchers/usePosition';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { RouterParams } from '@/main';
import { useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { toast } from 'sonner';

const statusUpdateModalWordingByStatus: Record<
  PositionStatus,
  {
    title: string;
    description: string;
    confirmText: string;
  }
> = {
  [PositionStatus.Draft]: {
    title: 'You are about to publish this position',
    description:
      'Once you publish this position, it will be visible to the public and you will start receiving applications for this position.',
    confirmText: 'Publish position',
  },
  [PositionStatus.Open]: {
    title: 'You are about to close this position',
    description:
      'Once you close this position, you will not be able to receive new applications for this position.',
    confirmText: 'Close position',
  },
  [PositionStatus.Closed]: {
    title: 'You are about to open this position',
    description:
      'Once you open this position, it will be visible to the public and you will start receiving applications for this position.',
    confirmText: 'Open position',
  },
};
const usePositionStatusUpdateModal = (position: TPosition) => {
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const [isPositionStatusUpdateModalOpen, setIsPositionStatusUpdateModalOpen] =
    useState(false);
  const isPositionOpen = getIsPositionOpen(position);
  const statusUpdateModalWording =
    statusUpdateModalWordingByStatus[position.status];
  const updatePositionMutation = useUpdatePositionMutation();
  async function handleUpdatePositionStatus() {
    const { data } = await updatePositionMutation({
      data: {
        status: isPositionOpen ? PositionStatus.Closed : PositionStatus.Open,
      },
      organizationId: Number(organizationId),
      slug: position.slug,
    });

    if (data) {
      toast.success(
        isPositionOpen
          ? 'Position closed successfully'
          : 'Position published successfully'
      );
    }
  }

  const PositionStatusUpdateModal = (
    <AlertModal
      open={isPositionStatusUpdateModalOpen}
      onClose={() => setIsPositionStatusUpdateModalOpen(false)}
      title={statusUpdateModalWording.title}
      content={statusUpdateModalWording.description}
      confirmText={statusUpdateModalWording.confirmText}
      onProceed={handleUpdatePositionStatus}
      type={isPositionOpen ? 'danger' : 'info'}
    />
  );

  return { PositionStatusUpdateModal, setIsPositionStatusUpdateModalOpen };
};

export default usePositionStatusUpdateModal;
