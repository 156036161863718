import { CareerView } from '@/components/core/careers/single';
import { Loader } from '@/components/core/loader';
import { useGetPublicOrganization } from '@/fetchers/useOrganization';
import { useGetPublicPosition } from '@/fetchers/usePosition';
import { getOrganizationSlugIfSubdomain } from '@/lib/utils';
import { Navigate, createRoute } from '@tanstack/react-router';
import { Route as RootRoute } from './__root';

export const Route = createRoute({
  path: '/$jobId',
  getParentRoute: () => RootRoute,
  component: View,
});

function View() {
  // const isCareersSubdomain = window.location.hostname.split('.')?.[1] === "careers"
  // const organizationName = window.location.hostname.split('.')[0]

  const organizationSlug = getOrganizationSlugIfSubdomain();

  const { jobId } = Route.useParams();
  const { data, isFetching: isFetchingPosition } = useGetPublicPosition({
    slug: jobId,
  });

  const { data: organization, isFetching } = useGetPublicOrganization({
    organizationSlug,
  });

  if (isFetching || isFetchingPosition) {
    return <Loader />;
  }

  if (data?.organizationSlug !== organization?.slug) {
    return <Navigate to="/" />;
  }

  if (organization) {
    return (
      // @ts-ignore
      <CareerView position={data} organization={organization} />
    );
  }

  return <Navigate to="/organizations" />;
}
