import { useDndContext, useDroppable } from '@dnd-kit/core';
import { useMemo, useRef, useState } from 'react';

import { cn } from '@/lib/utils';
import { Applicant } from '@/lib/useApplicants';
import { Check, LucideIcon } from 'lucide-react';
import { useVirtualizedList } from '@/hooks/useVirtualizedList';
import ApplicantPipelineItem from './ApplicantPipelineItem';
import {
  FinalVerdict,
  PhoneNumberStatus,
  PipelineBulkAction,
  PipelineFilterCategory,
  PipelineStage,
  ResumeAnalysisVerdict,
  SmsDeliverability,
} from './types';
import { PipelineFilter } from './types';
import {
  interviewVerdictValues,
  pipelineTitleByStage,
  resumeVerdictValues,
} from './constants';
import {
  ApplicantInterview,
  Application,
  useBulkShortlistMutation,
  useSendBulkInterviewMutation,
} from '@/fetchers/useApplication';
import { PhoneLineType } from '@/fetchers/types';
import Filters from './Filters';
import AlertModal from '../common/AlertModal';
import BulkSelectCheckbox from './BulkSelectCheckbox';
import { Button } from '@/components/ui/button';
import useBulkDisqualifyModal from '@/hooks/useBulkDisqualifyModal';
import useFilterStore from './useFilterStore';
import { useShallow } from 'zustand/shallow';
import { useParams } from '@tanstack/react-router';
import { RouterParams } from '@/main';
import BulkActionMenu from './BulkActionMenu';

type PipelineProps = {
  stage: PipelineStage;
  items: Applicant[];
  filters?: PipelineFilter[];
  Icon: LucideIcon;
  bulkActions?: PipelineBulkAction[];
  searchQuery: string;
  bulkSelectionActive?: boolean;
  onChangeBulkSelectionActive?: (active: boolean) => void;
};

const Pipeline = ({
  items,
  Icon,
  filters = [],
  stage,
  searchQuery,
  bulkSelectionActive = false,
  onChangeBulkSelectionActive,
}: PipelineProps) => {
  const { slug } = useParams({ strict: false }) as RouterParams;
  const title = pipelineTitleByStage[stage];
  const [selectedApplicants, setSelectedApplicants] = useState<Applicant[]>([]);

  const [sendBulkInterviewModalOpen, setSendBulkInterviewModalOpen] =
    useState(false);
  const [isBulkRevertModalOpen, setisBulkRevertModalOpen] = useState(false);
  const {
    selectedFilters: selectedStoreFilters,
    handleSelectFilterOption,
    clearStageFilters,
  } = useFilterStore(
    useShallow((state) => ({
      selectedFilters: state.selectedFilters,
      handleSelectFilterOption: state.handleSelectFilterOption,
      clearStageFilters: state.clearStageFilters,
    }))
  );
  const selectedFilters = selectedStoreFilters.filter(
    (filter) => filter.positionSlug === slug
  );
  const stageFilters = selectedFilters.filter(
    (filter) => filter.stage === stage
  );

  const handleDisableBulkSelection = () => {
    onChangeBulkSelectionActive?.(false);
    setSelectedApplicants([]);
  };

  const { handleOpenBulkDisqualifyModal, BulkDisqualifyModal } =
    useBulkDisqualifyModal({
      onSuccess: () => {
        handleDisableBulkSelection();
      },
    });
  const bulkShortlistMutation = useBulkShortlistMutation();
  const applicantsContainerRef = useRef<HTMLDivElement>(null);
  const { isOver, setNodeRef } = useDroppable({
    id: stage,
    disabled: [
      PipelineStage.APPLIED,
      PipelineStage.INTERVIEW_COMPLETED,
    ].includes(stage),
  });
  const { active } = useDndContext();
  const sendBulkInterviewMutation = useSendBulkInterviewMutation();

  async function handleBulkSendInterview() {
    await sendBulkInterviewMutation({
      applicationIds: selectedApplicants.map(
        (applicant) => applicant?.application?.id
      ),
    });
    handleDisableBulkSelection();
  }

  const handleFilterByResumeAnalysis = (
    filter: PipelineFilter,
    application: Application
  ) => {
    const resumeAnalysisVerdict = application.resumeAnalysisVerdict;

    if (!resumeAnalysisVerdict) {
      return filter.options.some(
        (option) =>
          option.isSelected &&
          option.label ===
            resumeVerdictValues[ResumeAnalysisVerdict.RESUME_NOT_PROVIDED]
      );
    }
    const resumeVerdict = resumeVerdictValues[resumeAnalysisVerdict];

    return filter.options.some(
      (option) => option.isSelected && option.label === resumeVerdict
    );
  };

  const handleFilterByInterviewAnalysis = (
    filter: PipelineFilter,
    interview?: ApplicantInterview
  ) => {
    if (!interview?.finalVerdict) return false;
    const interviewVerdict =
      interviewVerdictValues[interview.finalVerdict as FinalVerdict];

    return filter.options.some(
      (option) => option.isSelected && option.label === interviewVerdict
    );
  };

  const handleFilterByPhoneNumberStatus = (
    filter: PipelineFilter,
    application: Application
  ) => {
    return filter.options.some(
      (option) =>
        option.isSelected &&
        option.label ===
          (application.phoneIsValid
            ? PhoneNumberStatus.VALID
            : PhoneNumberStatus.INVALID)
    );
  };

  const handleFilterBySmsDeliverability = (
    filter: PipelineFilter,
    application: Application
  ) => {
    const isMobile = application.phoneLineType === PhoneLineType.MOBILE;
    return filter.options.some(
      (option) =>
        option.isSelected &&
        option.label ===
          (isMobile
            ? SmsDeliverability.MOBILE_PHONE
            : SmsDeliverability.NON_MOBILE_PHONE)
    );
  };
  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      if (stageFilters.length === 0) return true;

      const interview = item.interview;
      const application = item.application;

      return stageFilters.every((filter) => {
        switch (stage) {
          case PipelineStage.APPLIED:
            switch (filter.category) {
              case PipelineFilterCategory.RESUME_ANALYSIS:
                return handleFilterByResumeAnalysis(filter, application);
              case PipelineFilterCategory.PHONE_NUMBER_STATUS:
                return handleFilterByPhoneNumberStatus(filter, application);
              case PipelineFilterCategory.SMS_DELIVERABILITY:
                return handleFilterBySmsDeliverability(filter, application);
              default:
                return true;
            }

          case PipelineStage.INTERVIEW_SENT:
            switch (filter.category) {
              case PipelineFilterCategory.RESUME_ANALYSIS:
                return handleFilterByResumeAnalysis(filter, application);
              case PipelineFilterCategory.PHONE_NUMBER_STATUS:
                return handleFilterByPhoneNumberStatus(filter, application);
              case PipelineFilterCategory.SMS_DELIVERABILITY:
                return handleFilterBySmsDeliverability(filter, application);
              default:
                return true;
            }

          case PipelineStage.INTERVIEW_COMPLETED:
            switch (filter.category) {
              case PipelineFilterCategory.RESUME_ANALYSIS:
                return handleFilterByResumeAnalysis(filter, application);
              case PipelineFilterCategory.INTERVIEW_ANALYSIS:
                return handleFilterByInterviewAnalysis(filter, interview);
              case PipelineFilterCategory.PHONE_NUMBER_STATUS:
                return handleFilterByPhoneNumberStatus(filter, application);
              case PipelineFilterCategory.SMS_DELIVERABILITY:
                return handleFilterBySmsDeliverability(filter, application);
              default:
                return true;
            }

          case PipelineStage.DISQUALIFIED: {
            const archived = application?.archived;
            return filter.options.some(
              (option) =>
                option.isSelected &&
                option.label === (archived ? 'Archived' : 'Not Archived')
            );
          }
          default:
            return true;
        }
      });
    });
  }, [items, stageFilters, stage]);

  const virtualizer = useVirtualizedList({
    itemCount: filteredItems.length,
    virtualizerOptions: {
      getScrollElement: () => applicantsContainerRef.current,
    },
  });

  const shortlistAction: PipelineBulkAction = {
    title: 'Shortlist',
    onClick: () => {
      bulkShortlistMutation({
        applicationIds: selectedApplicants.map(
          (applicant) => applicant.application.id
        ),
        shortlisted: true,
      });
      handleDisableBulkSelection();
    },
  };
  const revertAction: PipelineBulkAction = {
    title: 'Revert',
    onClick: () => {
      setisBulkRevertModalOpen(true);
    },
  };

  async function handleBulkRevert() {
    bulkShortlistMutation({
      applicationIds: selectedApplicants.map(
        (applicant) => applicant.application.id
      ),
      shortlisted: false,
    });
    handleDisableBulkSelection();
  }

  const disqualifyAction: PipelineBulkAction = {
    title: 'Disqualify',
    onClick: () => {
      handleOpenBulkDisqualifyModal(selectedApplicants);
    },
  };

  const bulkActionsByStage: Record<PipelineStage, PipelineBulkAction[]> = {
    [PipelineStage.APPLIED]: [
      {
        title: 'Send interview',
        onClick: () => {
          setSendBulkInterviewModalOpen(true);
        },
      },
      shortlistAction,
      disqualifyAction,
    ],
    [PipelineStage.INTERVIEW_SENT]: [],
    [PipelineStage.INTERVIEW_COMPLETED]: [shortlistAction, disqualifyAction],
    [PipelineStage.SHORTLISTED]: [revertAction, disqualifyAction],
    [PipelineStage.DISQUALIFIED]: [],
  };

  const bulkActions = bulkActionsByStage[stage];

  const applicantsText =
    selectedApplicants.length > 1
      ? `${selectedApplicants.length} applicants`
      : '1 applicant';

  return (
    <>
      <AlertModal
        type="info"
        open={sendBulkInterviewModalOpen}
        onClose={() => setSendBulkInterviewModalOpen(false)}
        title={`You are about to send interview to  ${applicantsText}`}
        description={`Please confirm that you want to send interview to ${applicantsText} you selected. This action cannot be undone. Once sent, the applicants will receive a notification.`}
        confirmText="Send"
        onProceed={handleBulkSendInterview}
      />
      <AlertModal
        open={isBulkRevertModalOpen}
        onClose={() => setisBulkRevertModalOpen(false)}
        title={`You are about to revert ${applicantsText}`}
        description={`Please confirm that you want to revert ${applicantsText} back to their previous columns`}
        confirmText="Revert"
        onProceed={handleBulkRevert}
      />
      {BulkDisqualifyModal}
      <div
        className={cn(
          'flex h-full flex-col overflow-y-hidden rounded border border-gray-300 shadow',
          active && 'overflow-visible',
          isOver && 'bg-gray-100'
        )}
        ref={setNodeRef}
      >
        <div className="sticky top-0 flex min-h-[44px] flex-shrink-0 flex-col rounded rounded-bl-none rounded-br-none border-b border-gray-300 bg-white p-2">
          <div className="flex h-full items-center">
            <div className="mr-auto flex items-center text-sm 2xl:text-base">
              {bulkSelectionActive && (
                <BulkSelectCheckbox
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedApplicants(filteredItems);
                    } else {
                      setSelectedApplicants([]);
                    }
                  }}
                />
              )}
              <Icon className="mr-1 size-5" />
              <span className="font-bold text-gray-700">
                {title} {items.length > 0 && `(${items.length})`}
              </span>
            </div>

            <div className="flex items-center justify-center gap-1">
              {filteredItems.length > 0 && bulkActions.length > 0 && (
                <Button
                  variant="ghost"
                  onClick={() => {
                    onChangeBulkSelectionActive?.(!bulkSelectionActive);
                    setSelectedApplicants([]);
                  }}
                  className="flex h-fit items-center gap-1 p-1"
                >
                  {bulkSelectionActive ? 'Cancel' : 'Select'}
                </Button>
              )}
              {filters.length > 0 && (
                <Filters
                  onSelectFilterOption={(option, category) =>
                    handleSelectFilterOption({
                      filters,
                      selectedFilterOption: option,
                      stage,
                      category,
                      positionSlug: slug,
                    })
                  }
                  onClearAllFilters={() => clearStageFilters(stage)}
                  filters={filters}
                  selectedFilters={stageFilters}
                  stage={stage}
                  disabled={items.length === 0}
                />
              )}
            </div>
          </div>
          {bulkSelectionActive && selectedApplicants.length > 0 && (
            <div className="mt-1 flex items-center">
              <span className="flex items-center gap-1 text-sm">
                <Check className="size-4" />
                {selectedApplicants.length} selected
              </span>
              <div className="ml-auto flex items-center gap-1">
                {bulkActions.length > 2 ? (
                  <>
                    <Button
                      variant="ghost"
                      className="flex h-fit items-center p-1"
                      key={bulkActions[0].title}
                      onClick={bulkActions[0].onClick}
                    >
                      {bulkActions[0].title}
                    </Button>
                    <BulkActionMenu actions={bulkActions.slice(1)} />
                  </>
                ) : (
                  bulkActions.map((action) => (
                    <Button
                      variant="ghost"
                      className="flex h-fit items-center p-1"
                      key={action.title}
                      onClick={action.onClick}
                    >
                      {action.title}
                    </Button>
                  ))
                )}
              </div>
            </div>
          )}
        </div>

        <div
          ref={applicantsContainerRef}
          className={cn(
            'no-scrollbar w-full overflow-y-auto px-2 pt-2',
            active && 'overflow-y-visible'
          )}
        >
          <div
            style={{
              width: '100%',
              position: 'relative',
              height: `${virtualizer.getTotalSize()}px`,
            }}
          >
            {virtualizer.getVirtualItems().map((virtualItem) => {
              const applicant = filteredItems[virtualItem.index];
              return (
                <div
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={virtualizer.measureElement}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualItem.start}px)`,
                    marginBottom: '8px', // Add margin here,
                    zIndex:
                      active?.id === applicant.application.id.toString()
                        ? 10
                        : 0,
                  }}
                >
                  <ApplicantPipelineItem
                    applicant={applicant}
                    searchQuery={searchQuery}
                    stage={stage}
                    bulkSelectionActive={bulkSelectionActive}
                    selectedApplicants={selectedApplicants}
                    onSelect={(applicant, isSelected) => {
                      if (isSelected) {
                        setSelectedApplicants((prev) =>
                          prev.filter(
                            (a) => a.application.id !== applicant.application.id
                          )
                        );
                      } else {
                        setSelectedApplicants((prev) => [...prev, applicant]);
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pipeline;
