import { createRoute } from '@tanstack/react-router';

import { z } from 'zod';

import { ConfirmationPage } from '@/components/core/interview-confirmation';
import { Route as interviewRoute } from './interviews.$interviewUUid';

const confirmationSearchSchema = z.object({
  phone: z.union([z.string(), z.number()]),
});

export const Route = createRoute({
  path: '/confirmation',
  getParentRoute: () => interviewRoute,
  validateSearch: (search) => confirmationSearchSchema.parse(search),
  component: ConfirmationPageRoute,
});

function ConfirmationPageRoute() {
  return <ConfirmationPage />;
}
