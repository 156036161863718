import { create } from 'zustand';
import {
  PipelineFilter,
  PipelineFilterCategory,
  PipelineFilterOption,
  PipelineStage,
} from './types';
import { persist } from 'zustand/middleware';

type PipelineStoreFilter = PipelineFilter & {
  stage: PipelineStage;
  positionSlug: string;
};

type FilterStore = {
  selectedFilters: PipelineStoreFilter[];
  handleRemoveFilterOption: (
    selectedFilterOption: PipelineFilterOption,
    filterIndex: number
  ) => void;
  handleSelectFilterOption: (options: {
    filters: PipelineFilter[];
    selectedFilterOption: PipelineFilterOption;
    stage: PipelineStage;
    category?: PipelineFilterCategory;
    positionSlug: string;
  }) => void;
  clearStageFilters: (stage: PipelineStage) => void;
  clearSelectedFilters: () => void;
};

const useFilterStore = create<FilterStore>()(
  persist(
    (set, get) => ({
      selectedFilters: [],
      handleRemoveFilterOption: (selectedFilterOption, filterIndex) => {
        const selectedStoreFilters = get().selectedFilters;
        const updatedFilters = [...selectedStoreFilters];
        const filter = { ...updatedFilters[filterIndex] };

        filter.options = filter.options.map((option) => ({
          ...option,
          isSelected:
            option.label === selectedFilterOption.label
              ? !option.isSelected
              : option.isSelected,
        }));

        updatedFilters[filterIndex] = filter;

        const result = updatedFilters.filter((filter) =>
          filter.options.some((option) => option.isSelected)
        );
        set({ selectedFilters: result });
        return result;
      },
      handleSelectFilterOption: ({
        filters,
        selectedFilterOption,
        stage,
        category,
        positionSlug,
      }) => {
        const { selectedFilters, handleRemoveFilterOption } = get();
        const existingFilterIndex = selectedFilters.findIndex(
          (filter) =>
            filter.category === category &&
            filter.stage === stage &&
            filter.positionSlug === positionSlug
        );

        if (existingFilterIndex !== -1) {
          handleRemoveFilterOption(selectedFilterOption, existingFilterIndex);
        } else {
          const filterTemplate = filters.find(
            (filter) => filter.category === category
          );

          if (!filterTemplate) return selectedFilters;

          const newFilter: PipelineStoreFilter = {
            ...filterTemplate,
            options: filterTemplate.options.map((option) => ({
              ...option,
              isSelected: option.label === selectedFilterOption.label,
            })),
            stage,
            positionSlug,
          };

          const result = [...selectedFilters, newFilter];
          set({ selectedFilters: result });
          return result;
        }
      },
      clearStageFilters: (stage) => {
        const selectedStoreFilters = get().selectedFilters;
        const result = selectedStoreFilters.filter(
          (filter) => filter.stage !== stage
        );
        set({ selectedFilters: result });
        return result;
      },
      clearSelectedFilters: () => set({ selectedFilters: [] }),
    }),
    {
      name: 'applicationFilters',
    }
  )
);

export default useFilterStore;
