export const afterApplicationSubjectTurkish =
  '{{company_name}} şirketinde {{position_name}} pozisyonu için yeni mülakat';

export const afterInterviewSubjectTurkish =
  '{{company_name}} | {{position_name}} Mülakatı';

export const disqualifiedSubjectTurkish =
  '{{company_name}} şirketindeki {{position_name}} pozisyonu için başvuru durumu güncellemesi';

export const applicationEmailTurkish = `<p>Sayın {{candidate_name}},</p><p><br/></p><p>{{company_name}} bünyesinde {{position_name}} pozisyonuna başvurduğunuz için çok teşekkür ederiz. Mülakatınız için hazırız!</p><p><br/></p><p>Hazır olduğunuzda, lütfen mülakatınıza başlamak için aşağıdaki bağlantıya tıklayın.</p>`;

export const interviewCompletedEmailTurkish = `<p>Sayın {{candidate_name}},</p><p><br/></p><p>{{company_name}} bünyesinde {{position_name}} pozisyonu için yaptığınız mülakatı tamamladığınız için teşekkür ederiz. Mülakatınızı gözden geçirip size geri dönüş yapacağız. Herhangi bir sorunuz olursa, lütfen {{company_email}} adresinden bize ulaşmaktan çekinmeyin.</p><p><br/></p><p>Geri bildiriminize değer veriyoruz! Lütfen mülakat deneyiminizle ilgili kısa anketimizi doldurmak için bir dakikanızı ayırın: https://sprw.io/stt-eG-cv.</p><p><br/></p><p>Birlikte çalışma olasılığı bizi heyecanlandırıyor ve yakında sizinle iletişime geçmeyi dört gözle bekliyoruz.</p><p><br/></p><p>Saygılarımızla,<br/>{{company_name}}</p>`;

export const disqualifiedEmailTurkish = `<p>Sayın {{candidate_name}},</p><p><br/></p><p>Üzülerek belirtmek isteriz ki {{position_name}} pozisyonu için başvuru sürecinden elendiniz. Herhangi bir sorunuz olursa, lütfen bizimle {{company_email}} adresinden iletişime geçmekten çekinmeyin.</p><p><br/></p><p>En iyi dileklerimizle,<br/>{{company_name}}</p>`;
