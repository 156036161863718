import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useUpdateInterviewConfirmationMutation } from '@/fetchers/useInterview';
import { RouterParams } from '@/main';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from '@tanstack/react-router';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { z } from 'zod';
import { RequiredStar } from '../common/RequiredStar';
import PhoneInput from 'react-phone-input-2';
import { cn } from '@/lib/utils';
import { TFunction } from 'i18next';
import { useIsMutating } from '@tanstack/react-query';
import { useEffect } from 'react';

const getDisplayFormSchema = (t: TFunction) =>
  z.object({
    phone: z.string().min(10, { message: t('phone_number_error_message') }),
  });

type UpdatePhoneFormValues = z.infer<ReturnType<typeof getDisplayFormSchema>>;

export function UpdatePhoneDialog({
  open,
  setOpen,
  phone,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  phone: string;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  const updateInterviewConfirmation = useUpdateInterviewConfirmationMutation();
  const { interviewUUid } = useParams({ strict: false }) as RouterParams;
  const navigate = useNavigate();
  const isMutating = useIsMutating();
  const form = useForm<UpdatePhoneFormValues>({
    resolver: zodResolver(getDisplayFormSchema(t)),
    mode: 'onChange',
    defaultValues: {
      phone: '',
    },
  });
  const { control, reset, handleSubmit, watch } = form;

  useEffect(() => {
    reset({ phone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  async function onSubmit(data: UpdatePhoneFormValues) {
    const { phone: newPhone } = data;

    await updateInterviewConfirmation({
      interviewUUid,
      phone: `+${newPhone}`,
    });

    toast.success('Phone number updated successfully.');

    navigate({
      to: `/interviews/$interviewUUid/confirmation`,
      params: { interviewUUid },
      search: {
        phone: `+${newPhone}`,
      },
    });
    setOpen(false);
  }

  const newPhone = watch('phone');

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          reset();
        }
      }}
    >
      <DialogContent>
        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <DialogHeader>
              <DialogTitle>{t('update_phone_number')}</DialogTitle>
              <DialogDescription>
                {t('update_phone_number_description')}
              </DialogDescription>
            </DialogHeader>
            <FormField
              control={control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('phone_number_input_label')}
                    <RequiredStar />
                  </FormLabel>
                  <FormControl>
                    <PhoneInput
                      value={field.value}
                      onChange={field.onChange}
                      country={'us'}
                      inputProps={{
                        className: cn(
                          `flex h-10 w-full rounded-md border border-input bg-background 
        pl-14 py-2 text-sm ring-offset-background file:border-0 
        file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground 
        focus-visible:outline-none focus-visible:ring-2
        focus-visible:ring-ring focus-visible:ring-offset-2 
        disabled:cursor-not-allowed disabled:opacity-50`
                        ),
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              isLoading={isMutating > 0}
              type="submit"
              disabled={newPhone === phone || isMutating > 0}
              className="self-end"
            >
              {t('save')}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
