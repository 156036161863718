import AlertModal from '@/components/core/common/AlertModal';
import { useDisqualifyCandidateMutation } from '@/fetchers/useInterview';
import { useCommunicationForm } from '@/fetchers/usePosition';
import { RouterParams } from '@/main';
import { useParams } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';

const useDisqualifyCandidateModal = () => {
  const [open, setOpen] = useState(false);
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const { slug } = useParams({ strict: false }) as RouterParams;
  const disqualifyCandidate = useDisqualifyCandidateMutation();
  const { data: communicationFormData } = useCommunicationForm({ slug });

  async function handleDisqualifyCandidate() {
    if (!applicationId) return;
    await disqualifyCandidate({
      applicationId,
    }).then(() => {
      toast.success('Applicant disqualified successfully');
    });
  }

  const handleOpenModal = (applicationId: number) => {
    setApplicationId(applicationId);
    setOpen(true);
  };

  const modalDescription = useMemo(() => {
    const text =
      'Are you sure you want to disqualify this applicant? This action cannot be undone.';
    if (communicationFormData?.disqualified) {
      return text.concat(
        ' Once disqualified, the applicant will receive an email notification.'
      );
    }
    return text;
  }, [communicationFormData]);

  const DisqualifyCandidateModal = (
    <AlertModal
      open={!!open}
      onClose={() => setOpen(false)}
      title="You are about to disqualify this applicant"
      description={modalDescription}
      confirmText="Disqualify"
      onProceed={handleDisqualifyCandidate}
    />
  );

  return {
    handleOpenDisqualifyCandidateModal: handleOpenModal,
    DisqualifyCandidateModal,
  };
};

export default useDisqualifyCandidateModal;
