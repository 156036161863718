import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { useIsMutating } from '@tanstack/react-query';

export type DialogType = 'danger' | 'warning' | 'info';

const colorsByType: Record<
  DialogType,
  { icon: string; iconBg: string; button: string }
> = {
  danger: {
    icon: 'text-red-600',
    iconBg: 'bg-red-100',
    button: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
  },
  warning: {
    icon: 'text-yellow-600',
    iconBg: 'bg-yellow-100',
    button: 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500',
  },
  info: {
    icon: 'text-green-600',
    iconBg: 'bg-green-100',
    button: 'bg-black hover:bg-secondary-700 focus:ring-secondary-500',
  },
};

type AlertModalProps = {
  title: string;
  description?: string;
  content?: React.ReactNode;
  confirmText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onProceed?: () => void | Promise<any>;
  cancelText?: string;
  open: boolean;
  onClose: () => void;
  // needed for places where the secondary action and x button doesn't do the same thing. for example unsaved changes alert modal
  onSecondaryActionClick?: () => void;
  type?: DialogType;
  isConfirmLoading?: boolean;
  isForm?: boolean;
  onSubmit?: () => void;
  noIcon?: boolean;
};

export default function AlertModal({
  open,
  onClose,
  title,
  description,
  content = null,
  confirmText,
  cancelText = 'Cancel',
  onProceed,
  onSecondaryActionClick,
  type = 'danger',
  isForm = false,
  onSubmit,
  noIcon,
}: AlertModalProps) {
  const isMutating = useIsMutating();
  function onCancelClick() {
    if (onSecondaryActionClick) {
      onSecondaryActionClick();
    } else {
      onClose();
    }
  }

  async function onProceedClick() {
    await onProceed?.();
    onClose();
  }

  const colorScheme = colorsByType[type];

  const renderedContent = (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 md:flex-row">
        {!noIcon && (
          <div
            className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full md:mx-0 ${colorScheme.iconBg}`}
          >
            <ExclamationTriangleIcon
              className={`h-6 w-6 ${colorScheme.icon}`}
              aria-hidden="true"
            />
          </div>
        )}
        <div className="flex flex-1 flex-col gap-3">
          <DialogHeader className="gap-1">
            <DialogTitle>{title}</DialogTitle>
            {description && (
              <DialogDescription>{description}</DialogDescription>
            )}
          </DialogHeader>
          {content ?? null}
        </div>
      </div>
      <DialogFooter className="gap-2 sm:space-x-0">
        <Button
          variant="outline"
          type="button"
          onClick={onCancelClick}
          disabled={isMutating > 0}
          autoFocus
        >
          {cancelText}
        </Button>
        <Button
          variant="destructive"
          type={isForm ? 'submit' : 'button'}
          className={colorScheme.button}
          onClick={isForm ? undefined : onProceedClick}
          isLoading={isMutating > 0}
          disabled={isMutating > 0}
        >
          {confirmText}
        </Button>
      </DialogFooter>
    </div>
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        if (isMutating > 0) return;
        if (!isOpen) {
          onClose();
        }
      }}
    >
      <DialogContent>
        {isForm ? (
          <form onSubmit={onSubmit}>{renderedContent}</form>
        ) : (
          renderedContent
        )}
      </DialogContent>
    </Dialog>
  );
}
