import { Button } from '@/components/ui/button';
import useDims from '@/lib/useDims';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import { HelpCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type THeaderProps = {
  logo: string;
  title: string;
  organizationUrl?: string;
  companyReachOutEmail: string;
};
function InterviewConfirmationHeader({
  logo,
  title,
  organizationUrl,
  companyReachOutEmail,
}: Readonly<THeaderProps>) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  const { isMobile } = useDims();
  const { showNewMessage, openChat } = useIntercomMessenger({
    customAttributes: {
      isCandidate: true,
    },
  });
  return (
    <div className="sticky top-0 z-10 mb-4 mt-6 w-full bg-white lg:mt-4">
      <div className="container flex w-full flex-col justify-between py-2 md:flex-row">
        <div className="flex items-center justify-center">
          <div className="size-[60px] overflow-hidden rounded">
            <img
              src={logo}
              alt="Company Logo"
              style={{
                width: '60px',
                height: '60px',
                aspectRatio: 1,
              }}
            />
          </div>

          <div className="ml-4 flex flex-col">
            <p className="text-2xl font-bold">{title}</p>
            <Button variant={'link'} className="h-6 w-14">
              <a
                href={`mailto:${companyReachOutEmail}`}
                className="text-sm text-gray-700"
              >
                {t('contact_us')}
              </a>
            </Button>
          </div>
        </div>

        <div className="mt-2 flex items-center justify-center gap-2 text-center">
          <Button
            variant={'link'}
            onClick={() => {
              openChat();
              showNewMessage('Hi! I need help with my interview');
            }}
          >
            <HelpCircle className="mr-2 size-4" />
            {t('get_support')}
          </Button>

          {organizationUrl && (
            <a href={organizationUrl} target="__blank">
              <Button variant={isMobile ? 'link' : 'secondary'}>
                {t('company_website')}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default InterviewConfirmationHeader;
