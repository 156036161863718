import { InterviewPage } from '@/components/core/interview';
import { Loader } from '@/components/core/loader';
import {
  createRoute,
  ErrorComponent,
  Outlet,
  useRouterState,
} from '@tanstack/react-router';
import { Route as RootRoute } from './__root';
import { useEffect, useState } from 'react';
import {
  TInterviewConfirmation,
  useInterviewDetailsQuery,
} from '@/fetchers/useInterview';
import InterviewNoLongerActive from '@/components/core/interview/interview-no-longer-active';
import { useTranslation } from 'react-i18next';

export const Route = createRoute({
  path: '/interviews/$interviewUUid',
  getParentRoute: () => RootRoute,
  errorComponent: ({ error }) => {
    console.log('error @scenario page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: InterviewRoute,
});

function InterviewRoute() {
  const { interviewUUid } = Route.useParams();
  const {
    location: { pathname },
  } = useRouterState();
  const [interviewDetails, setInterviewDetails] =
    useState<TInterviewConfirmation | null>(null);
  const { data, isFetching } = useInterviewDetailsQuery({
    interviewUUid,
  });
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data) {
      setInterviewDetails(data);
      i18n.changeLanguage(data.language);
    }
  }, [data, i18n]);

  if (isFetching) {
    return <Loader />;
  }

  if (interviewDetails && !interviewDetails.isActive) {
    return <InterviewNoLongerActive interviewDetails={interviewDetails} />;
  }

  if (pathname.includes('confirmation')) {
    return <Outlet />;
  }

  return <InterviewPage />;
}
