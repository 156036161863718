import { useTranslation, Trans } from 'react-i18next';

export const Disclaimer = ({ buttonText }: { buttonText: string }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  return (
    <div className="mt-4 text-center">
      <p className="text-base">{t('recording_disclaimer')}</p>
      <p className="text-base text-muted-foreground">
        <Trans
          i18nKey="terms_and_conditions_disclaimer"
          t={t}
          values={{
            buttonText,
          }}
          components={{
            termsTag: (
              <a
                href="https://app.veton.ai/terms"
                target="_blank"
                className="underline"
              />
            ),
            privacyTag: (
              <a
                href="https://app.veton.ai/privacy"
                target="_blank"
                className="underline"
              />
            ),
          }}
        />
      </p>
    </div>
  );
};
