export type TimeSaved = {
  resumeAnalysisDuration: string;
  interviewDuration: string;
  interviewAnalysisDuration: string;
  applicantCount: number;
  interviewedApplicantCount: number;
  totalDuration: string;
};

export enum PhoneLineType {
  MOBILE = 'mobile',
  LANDLINE = 'landline',
  FIXED_VOIP = 'fixedVoip',
  NON_FIXED_VOIP = 'nonFixedVoip',
  PERSONAL = 'personal',
  TOLL_FREE = 'tollFree',
  PREMIUM = 'premium',
  SHARED_COST = 'sharedCost',
  UAN = 'uan',
  VOICEMAIL = 'voicemail',
  PAGER = 'pager',
  UNKNOWN = 'unknown',
}
