import { useCommunicationForm } from '@/fetchers/usePosition';
import { RouterParams } from '@/main';
import { useParams } from '@tanstack/react-router';

export function usePositionCommunicationLanguage() {
  const { slug } = useParams({ strict: false }) as RouterParams;
  const { data: communicationFormData, isFetching } = useCommunicationForm({
    slug,
  });
  return {
    positionCommunicationLanguage: communicationFormData?.language,
    isFetchingPositionCommunicationLanguage: isFetching,
  };
}
