import { AdminDashboardComponent } from '@/components/core/admin/dashboard';
import { Loader } from '@/components/core/loader';
import { useGetAdminDashboard } from '@/fetchers/useOrganization';
import { createRoute } from '@tanstack/react-router';
import { Route as adminPanelRoute } from './admin-panel';

export const Route = createRoute({
  path: '/',
  getParentRoute: () => adminPanelRoute,
  component: DashboardPage,
});

function DashboardPage() {
  const { data, isFetching } = useGetAdminDashboard({
    enabled: true,
  });

  if (isFetching) {
    return <Loader />;
  }

  return (
    <AdminDashboardComponent
      items={data || []}
      title="Dashboard"
      description="Latest Interviews"
    />
  );
}
