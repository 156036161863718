import { Form } from '@/components/ui/form';
import { toast } from 'sonner';
import { mergeDataWithDefaults } from './form-defaults';
import {
  TPosition,
  useCommunicationForm,
  useUpdateCommunicationFormMutation,
} from '@/fetchers/usePosition';
import { useNavigate, useParams } from '@tanstack/react-router';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import {
  handleMapFormValuesToData,
  PositionCommunicationFormValues,
  usePositionCommunicationForm,
} from '@/lib/usePositionCommunicationForm';
import { PositionCommunicationForm } from './communication-form';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useEffect } from 'react';
import { RouterParams } from '@/main';
import { usePositionManageStickyActionBar } from '@/hooks/usePositionManageStickyActionBar';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';

export function CommunicationFormContainer({
  position,
}: {
  position?: TPosition;
}) {
  const { organizationId, slug } = useParams({ strict: false }) as RouterParams;
  const { creating } = usePositionManageRouteSearch();
  const updateCommunicationForm = useUpdateCommunicationFormMutation();
  const navigate = useNavigate();
  const { data: communicationFormData } = useCommunicationForm({
    slug,
  });

  const { selectedOrganization } = useUserDetailStore();

  const form = usePositionCommunicationForm();

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (communicationFormData) {
      reset({
        ...mergeDataWithDefaults(communicationFormData),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationFormData, position, selectedOrganization?.name]);

  async function onSubmit(values: PositionCommunicationFormValues) {
    const { error, data: updatedForm } = await updateCommunicationForm({
      data: handleMapFormValuesToData(values),
      organizationId,
      slug,
    });

    if (error) {
      toast.error('Error updating communication form');
      return;
    }

    toast.success('Position updated successfully!');

    if (updatedForm) {
      reset({
        ...mergeDataWithDefaults(updatedForm),
        isInitialized: true,
      });
    }

    if (creating) {
      navigate({
        to: '/organizations/$organizationId/positions/manage/$slug/$step',
        params: {
          organizationId,
          slug,
          step: 'ai-config',
        },
        search: {
          creating: true,
        },
        ignoreBlocker: true,
      });
    }
  }

  const { StickyActionBar } = usePositionManageStickyActionBar({
    isFormDirty: isDirty,
    position,
    onSave: handleSubmit(onSubmit),
  });

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <PositionCommunicationForm />
          {isFormInitialized && StickyActionBar}
        </form>
      </Form>
    </>
  );
}
