import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { TextEditor } from '../../common/TextEditor';
import { RequiredStar } from '../../common/RequiredStar';
import { useRef, useState } from 'react';
import { InsertVariables } from './InsertVariables';
import {
  autoSendSelectOptions,
  interviewDelaySelectOptions,
} from './constants';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Input } from '@/components/ui/input';
import { useFormContext } from 'react-hook-form';
import { PositionCommunicationFormValues } from '@/lib/usePositionCommunicationForm';
import { Loader } from '../../loader';
import { languages } from '@/constants/languages';
import { SelectSearchInput } from '../../select-search-input';
import { useSearch } from '@tanstack/react-router';
import AlertModal from '../../common/AlertModal';
import { templatesByLocale } from './email-templates';
import { Language } from '@/i18n';
import { AutoSendOption, InterviewDelay } from './types';

export function PositionCommunicationForm() {
  const { creating } = useSearch({ strict: false });
  const { control, watch, setValue, getValues } =
    useFormContext<PositionCommunicationFormValues>();
  const applicationEmailRef = useRef();
  const interviewCompletedEmailRef = useRef();
  const disqualifiedRef = useRef();
  const afterApplicationSubjectInputRef = useRef<HTMLInputElement | null>(null);
  const afterInterviewSubjectInputRef = useRef<HTMLInputElement | null>(null);
  const disqualifiedSubjectInputRef = useRef<HTMLInputElement | null>(null);
  const [focusedSubjectInput, setFocusedSubjectInput] = useState<
    | 'afterApplicationSubject'
    | 'afterInterviewSubject'
    | 'disqualifiedSubject'
    | null
  >(null);

  const [languageSearchQuery, setLanguageSearchQuery] = useState('');
  const [languageChangeAlertModal, setLanguageChangeAlertModal] = useState<{
    open: boolean;
    selectedLanguage: Language | null;
  }>({
    open: false,
    selectedLanguage: null,
  });

  const autoInterview = watch('autoInterview');
  const isInitialized = watch('isInitialized');

  const handleInsertVariableToSubjectField = (variable: string) => {
    if (!focusedSubjectInput) return;
    let inputRef: HTMLInputElement | null = null;
    let currentValue = '';

    switch (focusedSubjectInput) {
      case 'afterApplicationSubject':
        inputRef = afterApplicationSubjectInputRef.current;
        currentValue = getValues('afterApplicationSubject');
        break;
      case 'afterInterviewSubject':
        inputRef = afterInterviewSubjectInputRef.current;
        currentValue = getValues('afterInterviewSubject');
        break;
      case 'disqualifiedSubject':
        inputRef = disqualifiedSubjectInputRef.current;
        currentValue = getValues('disqualifiedSubject');
        break;
      default:
        break;
    }

    const selectionStart = inputRef?.selectionStart ?? 0;
    const selectionEnd = inputRef?.selectionEnd ?? 0;

    const newValue =
      currentValue.substring(0, selectionStart) +
      variable +
      currentValue.substring(selectionEnd);

    switch (focusedSubjectInput) {
      case 'afterApplicationSubject':
        setValue('afterApplicationSubject', newValue);
        break;
      case 'afterInterviewSubject':
        setValue('afterInterviewSubject', newValue);
        break;
      case 'disqualifiedSubject':
        setValue('disqualifiedSubject', newValue);
        break;
      default:
        break;
    }

    // Restore cursor position after React updates the DOM
    setTimeout(() => {
      const newCursorPosition = selectionStart + variable.length;
      inputRef?.focus();
      inputRef?.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleUpdateMailTemplates = (language: Language) => {
    const templates = templatesByLocale[language];
    Object.entries(templates).forEach(([key, value]) => {
      setValue(key as keyof PositionCommunicationFormValues, value, {
        shouldDirty: true,
      });
    });
  };

  if (!isInitialized) {
    return <Loader className="static h-[50vh] bg-transparent" />;
  }
  return (
    <>
      <AlertModal
        open={languageChangeAlertModal.open}
        onClose={() =>
          setLanguageChangeAlertModal({ open: false, selectedLanguage: null })
        }
        title="Change interview language?"
        description="Changing the interview language will remove your communication messages and replace them with default messages in the new language. Would you like to proceed?"
        confirmText="Continue"
        cancelText="Cancel"
        onProceed={() => {
          if (!languageChangeAlertModal.selectedLanguage) return;
          setValue('language', languageChangeAlertModal.selectedLanguage, {
            shouldDirty: true,
          });
          handleUpdateMailTemplates(languageChangeAlertModal.selectedLanguage);
          setLanguageChangeAlertModal({
            open: false,
            selectedLanguage: null,
          });
          setLanguageSearchQuery('');
        }}
      />
      <FormField
        control={control}
        name="language"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Interview language</FormLabel>
            <FormDescription
              style={{
                marginTop: 2,
              }}
            >
              Email/SMS communication and AI interviews will be in the selected
              language.
            </FormDescription>
            <FormControl>
              <Select
                {...field}
                onValueChange={(value) => {
                  if (creating) {
                    field.onChange(value);
                    setLanguageSearchQuery('');
                    handleUpdateMailTemplates(value as Language);
                  } else {
                    setLanguageChangeAlertModal({
                      open: true,
                      selectedLanguage: value as Language,
                    });
                  }
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select language" />
                </SelectTrigger>
                <SelectContent>
                  <SelectSearchInput
                    value={languageSearchQuery}
                    onChange={setLanguageSearchQuery}
                    placeholder="Search language"
                  />
                  {languages
                    .filter((language) =>
                      language?.name
                        .toLowerCase()
                        .includes(languageSearchQuery.toLowerCase())
                    )
                    .map((language) => (
                      <SelectItem key={language.locale} value={language.locale}>
                        {language.flag} {language.name}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="selectedMethod"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Preferred Communication Method</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="email">Email</TabsTrigger>
                    <TabsTrigger value="sms">SMS</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />

            <FormDescription>
              SMS option only available for US numbers.
            </FormDescription>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="interviewType"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Preferred Interview Method </FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="phone">Phone Call</TabsTrigger>
                    <TabsTrigger value="video">Video Call</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormDescription>
              <p>
                Whether you prefer the AI to conduct interviews via phone or
                video call.
              </p>
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="autoInterview"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full items-center justify-between">
              <FormLabel>Auto Send Interviews</FormLabel>
              <FormControl>
                <Tabs
                  value={field.value ? 'enabled' : 'disabled'}
                  onValueChange={(val) => field.onChange(val === 'enabled')}
                >
                  <TabsList>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    <TabsTrigger value="enabled">Enabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>
            <FormDescription>
              Automatically send interview requests to applicants.
            </FormDescription>

            <FormMessage />
          </FormItem>
        )}
      />

      {autoInterview && (
        <FormField
          control={control}
          name="autoSendOption"
          render={({ field }) => (
            <FormItem>
              <div className="flex w-full items-center justify-between">
                <FormLabel className="w-full">Auto Send to</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl className="w-[180px]">
                    <SelectTrigger>
                      <SelectValue placeholder="Auto send option" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.keys(autoSendSelectOptions).map((key) => (
                      <SelectItem key={key} value={key}>
                        {autoSendSelectOptions[key as AutoSendOption]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <FormDescription>
                Choose to send all applicants or only good fits.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {autoInterview && (
        <FormField
          control={control}
          name="interviewDelay"
          render={({ field }) => (
            <FormItem>
              <div className="flex w-full items-center justify-between">
                <FormLabel className="w-full">Interview Delay</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl className="w-[140px]">
                    <SelectTrigger>
                      <SelectValue placeholder="Select interview delay" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.keys(interviewDelaySelectOptions).map((key) => (
                      <SelectItem key={key} value={key}>
                        {interviewDelaySelectOptions[key as InterviewDelay]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <FormDescription>
                How soon after applying would you like to invite applicants to
                perform their interview
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <div className="space-y-2">
        <FormLabel className="text-md">
          Interview Invitation Message
          <RequiredStar />
        </FormLabel>
        <FormField
          control={control}
          name="afterApplicationSubject"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    afterApplicationSubjectInputRef.current = ref;
                  }}
                  onFocus={() => {
                    setFocusedSubjectInput('afterApplicationSubject');
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="afterApplication"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TextEditor
                  // @ts-expect-error style works
                  // style={{ height: "80%" }}
                  className="h-[300px] pb-20"
                  theme="snow"
                  placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                  onFocus={() => {
                    if (focusedSubjectInput) {
                      setFocusedSubjectInput(null);
                    }
                  }}
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    // @ts-ignore
                    applicationEmailRef.current = ref;
                  }}
                />
              </FormControl>
              <FormDescription>
                <InsertVariables
                  customRef={applicationEmailRef}
                  onVariableClick={handleInsertVariableToSubjectField}
                />
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <Separator />

      <div className="space-y-2">
        <FormLabel className="text-md">
          Interview Completed Message
          <RequiredStar />
        </FormLabel>
        <FormField
          control={control}
          name="afterInterviewSubject"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    afterInterviewSubjectInputRef.current = ref;
                  }}
                  onFocus={() => {
                    setFocusedSubjectInput('afterInterviewSubject');
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="afterInterview"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TextEditor
                  // @ts-expect-error style works
                  // style={{ height: "80%" }}
                  className="h-[300px] pb-20"
                  theme="snow"
                  placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                  onFocus={() => {
                    if (focusedSubjectInput) {
                      setFocusedSubjectInput(null);
                    }
                  }}
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    // @ts-ignore
                    interviewCompletedEmailRef.current = ref;
                  }}
                />
              </FormControl>
              <FormDescription>
                <InsertVariables
                  customRef={interviewCompletedEmailRef}
                  onVariableClick={handleInsertVariableToSubjectField}
                />
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <Separator />
      <FormField
        control={control}
        name="disqualifiedToggle"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Tabs value={field.value} onValueChange={field.onChange}>
                <div className="flex w-full items-center justify-between">
                  <FormLabel>Disqualified Communication</FormLabel>
                  <TabsList>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    <TabsTrigger value="enabled">Enabled</TabsTrigger>
                  </TabsList>
                </div>
                <TabsContent value="disabled">
                  <FormDescription>
                    Automatically send a message to disqualified applicants.
                  </FormDescription>
                </TabsContent>

                <TabsContent value="enabled">
                  <div className="space-y-2">
                    <FormField
                      control={control}
                      name="disqualifiedSubject"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Disqualification Subject
                            <RequiredStar />
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              ref={(ref) => {
                                field.ref(ref);
                                disqualifiedSubjectInputRef.current = ref;
                              }}
                              onFocus={() => {
                                setFocusedSubjectInput('disqualifiedSubject');
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="disqualified"
                      render={({ field }) => (
                        <FormItem>
                          {/* <FormLabel>Disqualification Email to be sent<RequiredStar /></FormLabel> */}
                          <FormControl>
                            <TextEditor
                              // @ts-expect-error style works
                              // style={{ height: "80%" }}
                              className="h-[300px] pb-20"
                              theme="snow"
                              placeholder="Disqualification email content. ie: We regret to inform you that you have been disqualified from the application process for {{position_name}}. If you have any questions, please feel free to contact us at {{company_email}}."
                              onFocus={() => {
                                if (focusedSubjectInput) {
                                  setFocusedSubjectInput(null);
                                }
                              }}
                              {...field}
                              value={field.value}
                              ref={(ref) => {
                                field.ref(ref);
                                // @ts-ignore
                                disqualifiedRef.current = ref;
                              }}
                            />
                          </FormControl>
                          <FormDescription>
                            <InsertVariables
                              customRef={disqualifiedRef}
                              onVariableClick={
                                handleInsertVariableToSubjectField
                              }
                            />
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </TabsContent>
              </Tabs>
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
