import { Language } from '@/i18n';

export const languages = [
  {
    flag: '🇺🇸',
    locale: Language.EN_US,
    name: 'English (US)',
  },
  {
    flag: '🇳🇱',
    locale: Language.NL,
    name: 'Dutch',
  },
  {
    flag: '🇹🇷',
    locale: Language.TR,
    name: 'Turkish',
  },
  {
    flag: '🇫🇷',
    locale: Language.FR,
    name: 'French',
  },
  {
    flag: '🇪🇸',
    locale: Language.ES,
    name: 'Spanish',
  },
  {
    flag: '🇩🇪',
    locale: Language.DE,
    name: 'German',
  },
];
