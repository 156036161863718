import { BarChart, FileSearch, PhoneCall } from 'lucide-react';
import { TimeSavedGridCard } from './time-saved-grid-card';
import { TimeSaved } from '@/fetchers/types';

type TimeSavedGridProps = {
  parsedTimeSaved: TimeSaved;
};

const TimeSavedGrid = ({ parsedTimeSaved }: TimeSavedGridProps) => {
  const {
    resumeAnalysisDuration,
    interviewDuration,
    interviewAnalysisDuration,
    interviewedApplicantCount,
    applicantCount,
  } = parsedTimeSaved;

  return (
    <div className="mb-4 flex gap-3 overflow-x-auto">
      <TimeSavedGridCard
        category="Resume analysis"
        timeSaved={resumeAnalysisDuration}
        icon={FileSearch}
        applicantCount={applicantCount}
      />
      <TimeSavedGridCard
        category="AI interviews"
        timeSaved={interviewDuration}
        icon={PhoneCall}
        applicantCount={interviewedApplicantCount}
      />
      <TimeSavedGridCard
        category="Interview analysis"
        timeSaved={interviewAnalysisDuration}
        icon={BarChart}
        applicantCount={interviewedApplicantCount}
      />
    </div>
  );
};

export default TimeSavedGrid;
