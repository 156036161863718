import { TPosition } from '@/fetchers/usePosition';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useNavigate, useParams } from '@tanstack/react-router';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { RouterParams } from '@/main';
import StickyActionBar from '../../sticky-action-bar';
import { getIsPositionDraft } from '@/lib/getIsPositionDraft';
import { useIsMutating } from '@tanstack/react-query';
import useOpenPosition from '@/hooks/useOpenPosition';
import './preview.css';

type TProps = {
  position?: TPosition;
};
export default function PreviewFormView({ position }: Readonly<TProps>) {
  const { slug, organizationId } = useParams({ strict: false }) as RouterParams;
  const handleOpenPosition = useOpenPosition();
  const isMutating = useIsMutating();
  const { selectedOrganization } = useUserDetailStore();
  const navigate = useNavigate();

  const isPositionOpen = getIsPositionOpen(position);
  const isPositionDraft = getIsPositionDraft(position);

  async function onPublishClickHandler() {
    await handleOpenPosition();
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: { organizationId, slug, step: 'applications' },
      ignoreBlocker: true,
    });
  }

  return (
    <div className="preview-container">
      <div className="mb-2">
        <h3 className="text-lg font-medium">Preview</h3>
        <p className="text-sm text-muted-foreground">
          Setup company details for your organization and position.
        </p>
      </div>
      <div className="md:no-scrollbar h-[130vh] w-full overflow-y-auto overflow-x-hidden rounded shadow-md md:mx-auto md:aspect-A4 md:h-full md:overflow-y-hidden">
        <iframe
          src={`${window.location.origin}/careers/${selectedOrganization?.slug}/${slug}?preview=true`}
          className="preview-iframe pointer-events-none h-full w-full origin-top-left transform border-0 md:scale-50"
        />
      </div>

      {!isPositionOpen && (
        <StickyActionBar
          isSaveLoading={isMutating > 0}
          isSaveDisabled={isMutating > 0}
          saveButtonText={isPositionDraft ? 'Publish' : 'Open position'}
          onSave={onPublishClickHandler}
        />
      )}
    </div>
  );
}
