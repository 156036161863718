import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '../../ui/separator';
import { TimeSavedBreakdownItem } from './time-saved-breakdown-item';
import { useParsedTimeSaved } from '@/lib/useParsedTimeSaved';
import { TimeSaved } from '@/fetchers/types';

type TimeSavedBreakdownProps = {
  timeSaved?: TimeSaved;
  title: string;
};

const TimeSavedBreakdown = ({ timeSaved, title }: TimeSavedBreakdownProps) => {
  const parsedTimeSaved = useParsedTimeSaved(timeSaved);

  if (!parsedTimeSaved) return null;

  const {
    resumeAnalysisDuration,
    interviewDuration,
    interviewAnalysisDuration,
    applicantCount,
    interviewedApplicantCount,
    totalDuration,
  } = parsedTimeSaved;

  return (
    <Card className="w-[300px] border-none shadow-none">
      <CardHeader className="mb-2 p-0">
        <CardTitle className="flex items-center gap-2 text-lg font-bold">
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <div className="grid gap-4">
          <TimeSavedBreakdownItem
            category="Resume analysis"
            applicantCount={applicantCount}
            timeSaved={resumeAnalysisDuration}
          />
          <TimeSavedBreakdownItem
            category="AI interviews"
            applicantCount={interviewedApplicantCount}
            timeSaved={interviewDuration}
          />
          <TimeSavedBreakdownItem
            category="Interview analysis"
            applicantCount={interviewedApplicantCount}
            timeSaved={interviewAnalysisDuration}
          />
        </div>
        <Separator className="my-2" />
        <div className="flex items-center justify-between text-lg">
          <span>Total</span>
          <span className="font-bold">{totalDuration}</span>
        </div>
      </CardContent>
    </Card>
  );
};

export default TimeSavedBreakdown;
