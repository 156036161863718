import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as CompanyRoute } from '@/routes/organizations.$organizationId.company';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { buttonVariants } from '@/components/ui/button';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from 'sonner';
import { Input } from '@/components/ui/input';
import { TextEditor } from '../components/core/common/TextEditor';
import { ColorPicker } from '../components/core/common/ColorPicker';
import { RequiredStar } from '../components/core/common/RequiredStar';
import {
  useOrganizationStore,
  useUpdateOrganization,
} from '@/fetchers/useOrganization';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { Link, useNavigate } from '@tanstack/react-router';
import { alphaNumericRegex, cn } from '@/lib/utils';
import ImageCropper from '../components/core/common/ImageCropper';
import { useEffect } from 'react';
import SlugInput from '../components/core/common/SlugInput';
import { VetonIndicator } from '../components/core/common/VetonIndicator';
import { Breadcrumb } from '@/components/core/breadcrumb';
import StickyActionBar from '@/components/core/sticky-action-bar';
import { useIsMutating } from '@tanstack/react-query';
import { useUnsavedChangesAlertModal } from '@/hooks/useUnsavedChangesAlertModal';
import { pick } from 'lodash';
import { sanitizeHtml } from '@/lib/sanitizeHtml';
import DOMPurify from 'dompurify';

export const Route = createRoute({
  getParentRoute: () => CompanyRoute,
  path: '/settings',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanySettingsPage,
});

const displayFormSchema = z.object({
  logo: z.union([z.string(), z.instanceof(File)]).optional(),
  name: z.string().min(1, {
    message: 'Company name is required.',
  }),
  slug: z
    .string()
    .min(1, {
      message: 'Company slug is required.',
    })
    .regex(alphaNumericRegex, {
      message: 'Company slug should only contain alphanumeric characters.',
    }),
  color: z.string().optional(),
  companyDescription: z.string().refine((val) => sanitizeHtml(val).length > 0, {
    message: 'Company description is required.',
  }),
  companyReachOutEmail: z.string().email({
    message: 'Invalid email address.',
  }),
});

type DisplayFormValues = z.infer<typeof displayFormSchema>;

const defaultValues: Partial<DisplayFormValues> = {
  logo: '',
  name: '',
  companyDescription: '',
  color: '#70e2ff',
  slug: '',
  companyReachOutEmail: '',
};

export function CompanySettingsPage() {
  const { organizationId } = Route.useParams();
  const { selectedOrganization } = useUserDetailStore();
  const updateOrganization = useUpdateOrganization();
  const {
    auth: { user },
  } = Route.useRouteContext();
  const navigate = useNavigate();
  const { selectedParentOrganization } = useOrganizationStore();
  const isMutating = useIsMutating();
  const userEmail = user?.emailAddresses?.[0]?.emailAddress;
  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    reset({
      ...pick(selectedOrganization, [
        'name',
        'slug',
        'color',
        'companyDescription',
      ]),
      logo:
        selectedOrganization?.logoUrl ?? selectedParentOrganization?.logoUrl,
      companyReachOutEmail:
        selectedOrganization?.companyReachOutEmail || userEmail,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization, selectedParentOrganization, userEmail]);

  const isCreate = organizationId === 'create';

  async function onSubmit(data: DisplayFormValues) {
    const organizationId = selectedOrganization?.id || -1;
    if (!selectedParentOrganization?.id) {
      toast.error('Please select a parent organization.');
      return;
    }
    const updatedOrganization = await updateOrganization({
      organizationId,
      parentOrganizationId: selectedParentOrganization?.id,
      payload: {
        ...data,
        companyDescription: DOMPurify.sanitize(data.companyDescription),
        logo: typeof data.logo === 'string' ? undefined : data.logo,
      },
    });
    if (updatedOrganization) {
      if (isCreate) {
        navigate({
          to: '/organizations/$organizationId/positions/list',
          params: { organizationId: String(updatedOrganization.id) },
        });
      }
      toast.success('Board settings updated successfully!');
    } else {
      toast.error('Something went wrong. Please try again.');
    }
  }

  const { UnsavedChangesAlertModal } = useUnsavedChangesAlertModal({
    show: isDirty,
    onSave: handleSubmit(onSubmit),
  });

  return (
    <>
      {UnsavedChangesAlertModal}
      <div className="flex flex-col">
        <Breadcrumb
          items={[
            {
              title: isCreate ? 'Create Board' : 'Board Settings',
            },
          ]}
        />
        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto flex max-h-screen w-full max-w-4xl flex-col gap-8"
          >
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <h2 className="text-2xl font-bold tracking-tight">
                  Board Settings
                </h2>
                <p className="text-muted-foreground">Setup your board</p>
              </div>
              {!isCreate && (
                <Link
                  to={
                    selectedOrganization?.integrationName
                      ? '/organizations/$organizationId/company/position-defaults/communication'
                      : '/organizations/$organizationId/company/position-defaults/application'
                  }
                  params={{
                    organizationId,
                  }}
                  className={buttonVariants({ variant: 'outline' })}
                >
                  Position defaults
                </Link>
              )}
            </div>
            <div className="mb-[190px] flex w-full flex-col gap-8 overflow-y-auto px-1 pb-4 md:mb-[124px]">
              <FormField
                control={control}
                name="logo"
                render={({ field }) => (
                  <FormItem className="max-w-md">
                    <FormLabel>Board Logo (260 x 260)</FormLabel>
                    <FormDescription>
                      Upload a logo for this specific board to display on the
                      careers page for all positions on this job board.
                    </FormDescription>

                    {field.value && (
                      <ImageCropper
                        onChange={field.onChange}
                        value={field.value}
                        layoutClasses="size-[260px]"
                      />
                    )}

                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex space-x-4">
                <FormField
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-sm">
                      <FormLabel aria-required>
                        Board Name
                        <RequiredStar />
                      </FormLabel>
                      <FormDescription></FormDescription>
                      <FormControl>
                        <Input placeholder="Board name" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={control}
                name="companyReachOutEmail"
                render={({ field }) => (
                  <FormItem className="w-full max-w-sm">
                    <FormLabel aria-required>
                      Board Email
                      <RequiredStar />
                    </FormLabel>
                    <FormDescription>
                      This will be the main point of contact for applicants and
                      will be public
                    </FormDescription>
                    <FormControl>
                      <Input placeholder="Board raech out email" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                <FormField
                  control={control}
                  name="slug"
                  render={({ field }) => (
                    <FormItem
                      className={cn(
                        'max-w-xs',
                        selectedOrganization?.slug &&
                          'pointer-events-none opacity-50'
                      )}
                    >
                      <FormLabel aria-required>
                        Board Slug
                        <RequiredStar />
                      </FormLabel>
                      <FormDescription>
                        This cannot be changed later
                        {/* <p className="font-bold">Cannot be changed once it is set</p> */}
                        {/* Should be alphanumeric characters only, as it will be used in your careers page. */}
                      </FormDescription>
                      <FormControl>
                        {/* <Input placeholder="Company slug" {...field} /> */}
                        <SlugInput {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name="color"
                  // can't pass ref to ColorPicker and we don't need it anyways
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  render={({ field: { ref, ...field } }) => (
                    <FormItem className="flex flex-col justify-between">
                      <FormLabel className="lg:mt-1.5">Board Color</FormLabel>
                      <FormControl>
                        <ColorPicker {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={control}
                name="companyDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Company Description for this Board
                      <VetonIndicator required />
                    </FormLabel>
                    <FormDescription>
                      {/* Detailed company description. <br /> This will be displayed on your company's veton careers page, and will be referred by our AI during the interviews. */}
                      This will be displayed on the top of the board, and will
                      be referred by our AI during the interviews. <br />
                      We recommend a few sentences to a single paragraph
                    </FormDescription>
                    <FormControl>
                      <TextEditor
                        // @ts-expect-error style works
                        // style={{ height: "80%" }}
                        className="h-[350px] pb-12"
                        theme="snow"
                        placeholder="An introduction to your company for applicants on this board"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <StickyActionBar
              isSaveLoading={isMutating > 0}
              isSaveDisabled={isMutating > 0 || !isDirty}
            />
          </form>
        </Form>
      </div>
    </>
  );
}
