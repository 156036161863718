import { Layout } from '@/components/core/dashboard/layout';
import { Loader } from '@/components/core/loader';
import {
  useATSConnect,
  useGetMemberships,
  useGetOrganizations,
} from '@/fetchers/useOrganization';

import { useUserDetails } from '@/fetchers/useUserDetails';
import AuthenticationPage from '@/pages/authentication/page';
import {
  createRoute,
  ErrorComponent,
  Outlet,
  redirect,
  useParams,
} from '@tanstack/react-router';
import { useEffect, useRef } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';
import { Route as RootRoute } from './__root';
import 'regenerator-runtime/runtime';
import { RouterParams } from '@/main';
import { Organizations } from '@/components/core/organizations';
import { ATS_CONNECTIONS } from '@/components/core/ats/constants';

const integrationStateSchema = z.object({
  state: z
    .union([
      z.string(),
      z.object({
        organizationId: z.string().optional(),
        integrationType: z.string().optional(),
        authMethod: z.string().optional(),
        redirectUri: z.string().optional(),
      }),
    ])
    .optional(),
  location: z.string().optional(),
  code: z.string().optional(),
});

export const Route = createRoute({
  path: '/organizations',
  getParentRoute: () => RootRoute,
  beforeLoad: ({ context, location }) => {
    const isSignedIn = context?.auth?.isSignedIn;
    if (!isSignedIn) {
      throw redirect({
        to: '/signin',
        search: {
          redirect_url: location.href,
        },
      });
    }
  },
  validateSearch: integrationStateSchema,
  component: OrganizationsComponent,

  errorComponent: ({ error }) => {
    console.log('error @organizations', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
});

function OrganizationsComponent() {
  useUserDetails();
  const { organizationId } = useParams({ strict: false }) as RouterParams;
  const { state: oauthState, location, code } = Route.useSearch();

  // const { isFetching } = useUserDetails()
  const { data: userDetails, isFetching: isFetchingUserDetails } =
    useUserDetails();
  const { data: memberships, isFetching: isFetchingMemberships } =
    useGetMemberships({ enabled: !isFetchingUserDetails && !!userDetails?.id });

  const { data: organizations, isFetching } = useGetOrganizations({
    enabled: !isFetchingMemberships,
  });
  const atsConnect = useATSConnect();
  const connecting = useRef(false);

  useEffect(() => {
    if (connecting.current) return;
    if (!oauthState || !code || !location) return;
    try {
      // @ts-ignore
      const { organizationId, integrationType, authMethod, redirectUri } =
        oauthState;

      const redirectUrl = new URL(redirectUri);
      // const clientId = redirectUrl.searchParams.get('one') || '';
      // const clientSecret = redirectUrl.searchParams.get('two') || '';

      console.log('location', location);
      let domain = 'com';
      if (location === 'eu') domain = 'eu';
      if (location === 'cn') domain = 'com.cn';
      if (location === 'jp') domain = 'jp';
      if (location === 'au') domain = 'com.au';
      if (location === 'in') domain = 'in';

      connecting.current = true;
      atsConnect({
        authMethod,
        integrationType,
        organizationId,
        credentials: {
          auth_code: code,
          domain,
          redirect_uri: redirectUrl.origin + redirectUrl.pathname, // Remove the query parameters
          // client_id: clientId,
          // client_secret: clientSecret,
        },
      })
        .then(() => {
          console.log('ATS Connected');
          const integrationName = ATS_CONNECTIONS.find(
            (connection) => connection.id === integrationType
          )?.name;
          toast.success(`${integrationName} Connected Successfully`);
        })
        .finally(() => {
          connecting.current = false;
        });
    } catch {
      console.log('error parsing state');
    }
  }, [oauthState, location, code]);

  useEffect(() => {
    if (isFetchingMemberships) return;
    if (!memberships || memberships.length === 0) {
      const script = document.createElement('script');
      script.src =
        'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [memberships, isFetchingMemberships]);

  if (isFetching || isFetchingUserDetails || isFetchingMemberships) {
    return <Loader />;
  }

  if (!memberships || memberships.length === 0) {
    return (
      // <ParentOgranizationCreationView />
      <AuthenticationPage whiteBg>
        <div>
          <h1 className="text-center text-3xl font-bold">
            Book a demo to get started{' '}
          </h1>
        </div>
        <div
          className="meetings-iframe-container w-full"
          data-src="https://meetings.hubspot.com/peter1234?embed=true"
        ></div>
      </AuthenticationPage>
    );
  }

  return (
    <Layout>
      {organizationId ? (
        <Outlet />
      ) : (
        <Organizations organizations={organizations || []} />
      )}
    </Layout>
  );
}
