import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip';
import logo from '@/assets/svg/logo-small.svg';
import { RequiredStar } from './RequiredStar';

export function VetonIndicator({
  hidden = false,
  required = false,
}: { hidden?: boolean; required?: boolean } = {}) {
  if (hidden) {
    return null;
  }
  return (
    <Tooltip>
      <TooltipTrigger onClick={(event) => event.preventDefault()}>
        <div className="ml-1 flex">
          <img alt="powered by veton.ai" src={logo} className="size-3" />
          {required && <RequiredStar />}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        Veton logo indicates AI Interviewer will refer to this field
      </TooltipContent>
    </Tooltip>
  );
}
