import {
  cn,
  getContrastColorFromBackgroundColor,
  getDominantColor,
} from '@/lib/utils';
import AlertModal from '../common/AlertModal';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from '../common/Spinner';
import { Button } from '@/components/ui/button';
import {
  InterviewQuery,
  useFinishInterviewMutation,
  useStartInterviewMutation,
} from '@/fetchers/useInterview';

import { Mic, User2Icon, Video, VideoOff, XIcon } from 'lucide-react';
import Webcam from 'react-webcam';
import { PreInterviewComponent } from '../interview/PreInterview';

import Lottie from 'react-lottie';
import lottieData from '@/assets/lottie/audio_waves.json';

import VetonLogo from '@/assets/svg/logo-small.svg?react';
import { SettingsDialog } from '../interview/SettingsDialog';
import { useInterviewTimeElapsedCounter } from '@/lib/useInterviewTimeElapsedCounter';
import { useVocalConversation } from '@/fetchers/useVocalConversation';
import { useTranslation } from 'react-i18next';

type TProps = {
  data?: InterviewQuery;
  startStream: () => void;
  aiTalking?: boolean;
  endStream: () => void;
};
export function Interview({
  data,
  startStream,
  aiTalking,
  endStream,
}: Readonly<TProps>) {
  const [alertOpen, setAlertOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const playerRef = useRef<any>(null);
  const { webcamManager, stopRecording } = useVocalConversation({
    interviewId: data?.id,
  });

  const {
    mutateAsync: startInterviewMutation,
    isPending: isStartingInterview,
  } = useStartInterviewMutation();
  const finishInterviewMutation = useFinishInterviewMutation();
  const [prepDone, setPrepDone] = useState(false);

  const username = data?.userName || 'Candidate';
  const intials = data?.userName?.charAt(0) || 'C';

  const { timeElapsed } = useInterviewTimeElapsedCounter({
    interviewId: Number(data?.id) || -1,
    start: prepDone,
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });

  const bgColor = data?.organization?.color || '#70e2ff';

  const avatarAnswerLoading = aiTalking === undefined;
  const avatarTalking = aiTalking;
  const listening = !aiTalking;

  async function onProceedHandler() {
    if (data?.id) {
      finishInterviewMutation({ interviewUUID: data.uuid });
    }
    endStream();
    stopRecording();
    webcamManager.shutdownCamera();
  }

  useEffect(() => {
    if (prepDone) {
      containerRef.current?.requestFullscreen().catch((error) => {
        console.log("couldn't request fullscreen", error);
      });
    }
  }, [prepDone]);

  if (!prepDone) {
    return (
      <PreInterviewComponent
        onClickStart={async () => {
          if (data?.uuid) {
            await startInterviewMutation({ interviewUUid: data?.uuid });
          }
          startStream();
          setPrepDone(true);
          webcamManager?.turnonCamera();
        }}
        isStartingInterview={isStartingInterview}
        webcamManager={webcamManager}
        data={data}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        background: getDominantColor(bgColor),
      }}
    >
      <audio ref={playerRef} id="player" controls className="hidden">
        Your browser does not support the audio element.
      </audio>
      <AlertModal
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title={t('finish_interview_dialog_title')}
        description={t('finish_interview_dialog_description')}
        confirmText={t('finish_interview')}
        cancelText={t('cancel')}
        onProceed={onProceedHandler}
      />
      <div className="flex h-screen w-full flex-col overflow-hidden px-4 py-4">
        <div className="mb-2 flex h-full space-x-2">
          <div
            className={cn(
              'relative flex h-full w-full items-center justify-center overflow-hidden rounded-xl p-1'
            )}
          >
            <span
              style={{ background: bgColor }}
              className="flex h-full w-full items-center justify-center rounded-lg shadow"
            >
              <div className="absolute left-4 top-4 flex items-center justify-between text-slate-100">
                {data?.organization?.logoUrl && (
                  <div className="mr-2 size-[25px] rounded-full bg-gray-200">
                    <img
                      src={data?.organization?.logoUrl}
                      alt="Company Logo"
                      className="size-[25px] rounded object-cover sm:size-[25px]"
                    />
                  </div>
                )}
                <p
                  style={{
                    color: getContrastColorFromBackgroundColor(bgColor),
                  }}
                  className="text-sm text-gray-200 sm:text-xl"
                >
                  {data?.title}
                </p>
              </div>
              {avatarAnswerLoading && (
                <div
                  style={{
                    background: bgColor,
                    color: getContrastColorFromBackgroundColor(bgColor),
                  }}
                  className="absolute right-4 top-4 flex space-x-2 text-slate-100"
                >
                  <Spinner />
                </div>
              )}

              <div className="grid w-full grid-cols-1 place-items-center gap-10 px-2 sm:px-10 md:grid-cols-2">
                <div
                  className={cn(
                    'aspect-video max-h-[40vh] w-full',
                    'overflow-hidden',
                    'rounded-xl shadow-xl',
                    'inline-block animate-border from-[#0558EE] via-[#ffffff] to-[#21E8E6] bg-[length:400%_400%]',
                    'relative flex h-full w-full items-center justify-center overflow-hidden rounded-xl bg-slate-900 p-1',
                    avatarTalking ? 'bg-gradient-to-r' : 'border-2 border-white'
                  )}
                >
                  <span className="flex h-full w-full items-center justify-center rounded-lg bg-slate-900">
                    <div className="flex h-full w-full items-center justify-center">
                      <img
                        src={data?.voiceModel?.imageUrl}
                        className="z-10 size-[140px] rounded-full object-cover lg:size-[200px]"
                      />

                      {avatarTalking && (
                        <div className="absolute z-0 size-[180px] grayscale lg:size-[255px]">
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: lottieData,
                              rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice',
                              },
                            }}
                            isPaused={!avatarTalking}
                          />
                        </div>
                      )}
                    </div>

                    <div className="absolute bottom-0 left-0 text-gray-200">
                      <div
                        className="absolute bottom-2 left-2 flex items-center space-x-1 rounded-xl p-2"
                        style={{
                          background: bgColor,
                          color: getContrastColorFromBackgroundColor(bgColor),
                        }}
                      >
                        <VetonLogo className="size-3" />
                        <p className="text-xs">{data?.voiceModel?.name}</p>
                      </div>
                    </div>
                  </span>
                </div>

                <div
                  className={cn(
                    'relative aspect-video max-h-[40vh] w-full',
                    'overflow-hidden',
                    'flex items-center justify-center',
                    'overflow-hidden rounded-xl shadow-xl',
                    'inline-block animate-border from-[#0558EE] via-[#ffffff] to-[#21E8E6] bg-[length:400%_400%]',
                    'relative flex h-full w-full items-center justify-center overflow-hidden rounded-xl bg-slate-900 p-1',
                    listening ? 'bg-gradient-to-r' : 'border-2 border-white'
                  )}
                >
                  <span className="flex h-full w-full items-center justify-center rounded-lg bg-slate-900">
                    {webcamManager?.userSetCamereOn && (
                      <>
                        <div className="absolute z-10 size-full">
                          <Webcam
                            audio={false}
                            width={'100%'}
                            height={'100%'}
                            mirrored
                          />
                        </div>

                        {webcamManager.activeRecordings?.map(
                          (recording, idx) => (
                            <video
                              key={recording?.id || idx}
                              className="webcam size-full"
                              ref={recording?.webcamRef}
                              loop
                              autoPlay
                              playsInline
                              muted
                            />
                          )
                        )}
                      </>
                    )}
                    {!webcamManager?.userSetCamereOn && (
                      <div className="flex size-[80px] items-center justify-center rounded-full bg-gray-100 text-xl sm:size-[180px] sm:text-4xl lg:text-7xl">
                        <p className="text-gray-500">
                          {intials?.toUpperCase()}
                        </p>
                      </div>
                    )}
                    <div
                      className="absolute bottom-2 left-2 z-10 flex items-center space-x-1 rounded-xl p-2"
                      style={{
                        background: bgColor,
                        color: getContrastColorFromBackgroundColor(bgColor),
                      }}
                    >
                      <User2Icon className="size-3" />
                      <p className="text-xs">{username}</p>
                    </div>
                  </span>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div
          className="relative flex h-[60px] w-full items-center justify-center space-x-2 rounded-xl shadow"
          style={{
            background: bgColor,
          }}
        >
          <div className="grid w-full grid-cols-3">
            <div className="flex h-[40px] w-full flex-row items-center space-x-3 pl-4 text-[16px]">
              <div className="flex flex-col items-center justify-center">
                <p className="font-bold">
                  {`0${Math.floor(timeElapsed / 60)}`.slice(-2)}
                </p>
                <p
                  className="text-[8px] opacity-50"
                  style={{
                    color: getContrastColorFromBackgroundColor(bgColor),
                  }}
                >
                  {t('minutes')}
                </p>
              </div>

              <p className="font-bold">:</p>

              <div className="flex flex-col items-center justify-center">
                <p className="font-bold">
                  {timeElapsed ? `0${timeElapsed % 60}`.slice(-2) : 0}
                </p>
                <p
                  className="text-[8px] opacity-50"
                  style={{
                    color: getContrastColorFromBackgroundColor(bgColor),
                  }}
                >
                  {t('seconds')}
                </p>
              </div>
            </div>
            <div className="flex w-full justify-center gap-2">
              <Button
                className="flex"
                style={{
                  background: bgColor,
                  color: getContrastColorFromBackgroundColor(bgColor),
                  borderColor: getContrastColorFromBackgroundColor(bgColor),
                }}
                disabled
                variant={'outline'}
              >
                <Mic className="size-4" />
                <p className="hidden lg:ml-1 lg:block">{t('unmute')}</p>
              </Button>
              <Button
                className="flex"
                disabled
                style={{
                  background: bgColor,
                  color: getContrastColorFromBackgroundColor(bgColor),
                  borderColor: getContrastColorFromBackgroundColor(bgColor),
                }}
                variant={'outline'}
              >
                {webcamManager.userSetCamereOn ? (
                  <Video className="size-4" />
                ) : (
                  <VideoOff className="size-4 text-red-400" />
                )}
                <p className="hidden lg:ml-1 lg:block">
                  {webcamManager.userSetCamereOn
                    ? t('stop_camera')
                    : t('start_camera')}
                </p>
              </Button>

              <Button
                className="flex"
                variant="destructive"
                onClick={() => {
                  if (document.fullscreenElement) {
                    document.exitFullscreen().catch((error) => {
                      console.log('Full screen exit error', error);
                    });
                  }
                  setAlertOpen(true);
                }}
                disabled={status === 'initial'}
              >
                <XIcon className="size-4" />
                <p className="hidden lg:ml-1 lg:block">
                  {t('finish_interview')}
                </p>
              </Button>
            </div>
            <div className="ml-auto">
              <SettingsDialog variant={'icon'} webcamManager={webcamManager} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
