import { LucideIcon, UsersIcon } from 'lucide-react';

type TimeSavedGridCardProps = {
  category: string;
  timeSaved: string;
  icon: LucideIcon;
  applicantCount: number;
};

export const TimeSavedGridCard = ({
  category,
  timeSaved,
  icon: Icon,
  applicantCount,
}: TimeSavedGridCardProps) => {
  return (
    <div className="relative flex min-w-60 flex-1 flex-col rounded-lg border p-4 shadow-sm transition-shadow duration-200 md:min-w-56">
      <div className="flex items-center gap-1">
        <Icon className="size-4 text-primary" />
        <span className="text-sm text-muted-foreground">{category}</span>
      </div>
      <p className="mt-1 text-2xl font-bold text-black">
        {timeSaved ? `${timeSaved} saved` : '-'}
      </p>
      <span className="absolute right-4 top-4 flex items-center gap-1 text-xs text-muted-foreground">
        <UsersIcon className="size-4" />
        {applicantCount}
      </span>
    </div>
  );
};

export default TimeSavedGridCard;
