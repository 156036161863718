import { Button } from '@/components/ui/button';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  CardContent,
} from '@/components/ui/card';
import { DialogFooter } from '@/components/ui/dialog';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Textarea } from '@/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from '@/components/ui/form';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { RequiredStar } from '../common/RequiredStar';
import {
  useInviteNewTeamMember,
  useOrganizationStore,
} from '@/fetchers/useOrganization';

const userSchema = z.object({
  role: z.enum(['admin', 'member']).default('member'),
  email: z
    .string({
      required_error: 'Email is required.',
    })
    .email(),
  message: z
    .string({
      required_error: 'Personalized message is required.',
    })
    .min(3, {
      message: 'Personalized message must be at least 3 characters.',
    }),
});

type UserConfigValues = z.infer<typeof userSchema>;

// This can come from your database or API.
const defaultValues: Partial<UserConfigValues> = {
  role: 'member',
  email: undefined,
  message: undefined,
};

type TProps = {
  onSubmit?: () => void;
};
export function InviteForm({ onSubmit }: Readonly<TProps>) {
  const { selectedParentOrganization } = useOrganizationStore();
  const inviteMutation = useInviteNewTeamMember();

  const form = useForm<UserConfigValues>({
    resolver: zodResolver(userSchema),
    // mode: "onChange",
    defaultValues: {
      ...defaultValues,
      message: `Please accept this invitation to get access to manage ${selectedParentOrganization?.name} organization on Veton.`,
    },
  });

  function onSubmitHandler(data: UserConfigValues) {
    onSubmit?.();

    inviteMutation(data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmitHandler)}>
        <Card className="border-none shadow-none">
          <CardHeader>
            <CardTitle className="">Add People</CardTitle>
            <CardDescription className="pt-2">
              Enter the email address of the person you want to invite. We will
              send them an email with an invitation.
            </CardDescription>
            {/* <Separator /> */}
          </CardHeader>
          <CardContent>
            <div className="grid gap-6">
              <div className="grid gap-3">
                <FormField
                  control={form.control}
                  name="email"
                  // disabled={!!organization?.name}
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel aria-required>
                        Email
                        <RequiredStar />
                      </FormLabel>
                      <FormDescription></FormDescription>
                      <FormControl>
                        <Input placeholder="email@domain.com" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid gap-3">
                <FormField
                  control={form.control}
                  name="message"
                  // disabled={!!organization?.name}
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel aria-required>
                        Personal Message
                        <RequiredStar />
                      </FormLabel>
                      <FormDescription></FormDescription>
                      <FormControl>
                        <Textarea
                          className="min-h-36 whitespace-pre-line text-sm"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>
                      User Role
                      <RequiredStar />
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-col space-y-1"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="admin" />
                          </FormControl>
                          <FormLabel>Admin</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="member" />
                          </FormControl>
                          <FormLabel>Member</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
        </Card>

        <DialogFooter>
          <Button onClick={form.handleSubmit(onSubmitHandler)}>
            Save changes
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
