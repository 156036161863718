import { Badge } from '@/components/ui/badge';
import { TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Tooltip } from '@/components/ui/tooltip';
import { Organization, useUserDetailStore } from '@/fetchers/useUserDetails';
import { sanitizeHtml } from '@/lib/sanitizeHtml';
import { useParsedTimeSaved } from '@/lib/useParsedTimeSaved';
import { cn } from '@/lib/utils';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from '@tanstack/react-router';
import { ClockIcon } from 'lucide-react';
import TimeSavedBreakdown from '../time-saved-breakdown';
import { pick } from 'lodash';

export const OrganizationCard = ({
  organization,
}: {
  organization: Organization;
}) => {
  const { setSelectedOrganization } = useUserDetailStore();
  const navigate = useNavigate();

  const {
    name,
    logoUrl,
    companyDescription,
    color,
    positionCount,
    integrationName,
  } = organization;
  const timeSaved = pick(organization, [
    'resumeAnalysisDuration',
    'interviewDuration',
    'interviewAnalysisDuration',
    'applicantCount',
    'interviewedApplicantCount',
    'totalDuration',
  ]);
  const parsedTimeSaved = useParsedTimeSaved(timeSaved);

  return (
    <div
      className="group relative flex w-full cursor-pointer flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
      onClick={() => {
        setSelectedOrganization(organization);
        navigate({
          to: `/organizations/$organizationId/positions/list`,
          params: { organizationId: organization.id.toString() },
        });
      }}
    >
      <div
        className="h-1"
        style={{
          background: color,
        }}
      />
      <div className="flex h-[115px] w-full items-center space-x-4 bg-gray-50 px-4 py-4 group-hover:opacity-75">
        <img
          src={logoUrl}
          alt={name}
          className="h-[70px] w-[70px] rounded-lg object-cover object-center"
        />
        <div className="">
          <h3 className="text-xl font-medium text-gray-900">{name}</h3>
          <p className="text-sm text-gray-600">
            {positionCount} position
            {positionCount === 1 ? '' : 's'} open
          </p>
        </div>
      </div>
      <div className="flex flex-1 flex-col p-4">
        <p className="mb-4 line-clamp-3 text-sm italic text-gray-500">
          {sanitizeHtml(companyDescription)}
        </p>
        <div
          className={cn(
            'mt-auto flex w-full items-center border-t border-gray-200 pt-3.5',
            parsedTimeSaved?.totalDuration && 'pt-2'
          )}
        >
          {parsedTimeSaved?.totalDuration && (
            <Tooltip>
              <TooltipTrigger>
                <Badge
                  variant="outline"
                  className="gap-1 border-none p-2 hover:bg-gray-100"
                >
                  <ClockIcon className="size-4" />
                  {parsedTimeSaved.totalDuration} saved
                </Badge>
              </TooltipTrigger>
              <TooltipContent className="p-4" side="bottom">
                <TimeSavedBreakdown
                  title="Time saved for this board"
                  timeSaved={timeSaved}
                />
              </TooltipContent>
            </Tooltip>
          )}
          <div
            className={cn(
              'ml-auto flex items-center',
              !parsedTimeSaved?.totalDuration && 'pb-1.5'
            )}
          >
            <LinkIcon className="mr-2 size-3" />
            <p className="text-right text-sm font-bold text-gray-900">
              {integrationName ? `${integrationName}` : 'Veton ATS'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
