import { Form } from '@/components/ui/form';

import {
  HiringMember,
  TPosition,
  useAddHiringTeamMember,
  useHiringTeamMembers,
  useRemoveHiringTeamMember,
} from '@/fetchers/usePosition';

import { useIsMutating } from '@tanstack/react-query';
import { useNavigate, useParams } from '@tanstack/react-router';

import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { HiringTeamForm } from './hiring-team-form';
import { usePositionHiringTeamForm } from '@/lib/usePositionHiringTeamForm';
import { toast } from 'sonner';
import { TeamMember } from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import { RouterParams } from '@/main';
import StickyActionBar from '../../sticky-action-bar';
import useOpenPosition from '@/hooks/useOpenPosition';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { getIsPositionClosed } from '@/lib/getIsPositionClosed';

export function HiringTeamFormContainer({
  position,
}: {
  position?: TPosition;
}) {
  const { organizationId, slug } = useParams({ strict: false }) as RouterParams;
  const { creating } = usePositionManageRouteSearch();
  const navigate = useNavigate();
  const { selectedOrganization } = useUserDetailStore();
  const isMutating = useIsMutating();
  const { data: hiringTeamMembers } = useHiringTeamMembers({
    organizationId,
    slug,
  });
  const form = usePositionHiringTeamForm();
  const { reset, watch } = form;
  const isFormInitialized = watch('isInitialized');
  const isPositionOpen = getIsPositionOpen(position);
  const isPositionClosed = getIsPositionClosed(position);

  useEffect(() => {
    if (hiringTeamMembers) {
      reset({ hiringTeamMembers: hiringTeamMembers, isInitialized: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiringTeamMembers]);

  const removeMember = useRemoveHiringTeamMember();
  const addMember = useAddHiringTeamMember();
  const handlePublishPosition = useOpenPosition();

  const handleRemoveMember = async (member: HiringMember) => {
    const res = await removeMember({
      organizationId,
      email: member.user.email,
      slug,
    });
    if (res.data?.success) {
      toast.success('Member has been removed successfully!');
    } else {
      toast.error(res.error);
    }
  };

  const handleAddMember = async (member: TeamMember) => {
    const res = await addMember({
      organizationId,
      slug,
      member: member.email,
    });
    if (res.data?.id) {
      toast.success('Member has been added successfully!');
    } else {
      toast.error(res.error);
    }
  };

  const handleAddAllMembers = async () => {
    const res = await addMember({
      organizationId,
      slug,
      member: 'all',
    });
    if (res.data?.id) {
      toast.success('All members have been added successfully!');
    } else {
      toast.error(res.error);
    }
  };

  return (
    <div className="hiring-team-form-container">
      <Form {...form}>
        <form className="space-y-8">
          <HiringTeamForm
            onRemoveMember={handleRemoveMember}
            onAddMember={handleAddMember}
            onAddAllMembers={handleAddAllMembers}
          />
          {isFormInitialized && !isPositionOpen && (
            <StickyActionBar
              isSaveLoading={isMutating > 0}
              isSaveDisabled={isMutating > 0}
              saveButtonText={
                creating
                  ? 'Next'
                  : isPositionClosed
                    ? 'Open position'
                    : 'Publish'
              }
              onSave={async () => {
                if (creating) {
                  navigate({
                    to: '/organizations/$organizationId/positions/manage/$slug/$step',
                    params: {
                      organizationId,
                      slug,
                      step: selectedOrganization?.integrationName
                        ? 'applications'
                        : 'preview',
                    },
                    ignoreBlocker: true,
                  });
                } else {
                  await handlePublishPosition();
                }
              }}
            />
          )}
        </form>
      </Form>
    </div>
  );
}
