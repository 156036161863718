import { Search } from 'lucide-react';
import { Input } from '../ui/input';
import { SelectSeparator } from '../ui/select';

type SelectSearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
};

export const SelectSearchInput = ({
  value,
  onChange,
  placeholder,
}: SelectSearchInputProps) => {
  return (
    <div className="flex flex-col">
      <div className="relative">
        <Search className="absolute left-2 top-1/2 size-4 -translate-y-1/2 text-muted-foreground" />
        <Input
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full rounded-none border-none pl-8 focus-visible:ring-0"
        />
      </div>
      <SelectSeparator />
    </div>
  );
};
