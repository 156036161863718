import AlertModal from '@/components/core/common/AlertModal';
import { useBulkDisqualifyMutation } from '@/fetchers/useApplication';
import { useCommunicationForm } from '@/fetchers/usePosition';
import { Applicant } from '@/lib/useApplicants';
import { RouterParams } from '@/main';
import { useParams } from '@tanstack/react-router';
import { useMemo, useState } from 'react';

const useBulkDisqualifyModal = ({ onSuccess }: { onSuccess: () => void }) => {
  const [open, setOpen] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState<Applicant[]>([]);
  const { slug } = useParams({ strict: false }) as RouterParams;
  const bulkDisqualifyMutation = useBulkDisqualifyMutation();
  const { data: communicationFormData } = useCommunicationForm({ slug });

  async function handleBulkDisqualify() {
    if (!selectedApplicants.length) return;
    bulkDisqualifyMutation({
      applicationIds: selectedApplicants.map(
        (applicant) => applicant.application.id
      ),
    });
    onSuccess();
  }

  const handleOpenModal = (selectedApplicants: Applicant[]) => {
    setSelectedApplicants(selectedApplicants);
    setOpen(true);
  };

  const applicantsText =
    selectedApplicants.length > 1
      ? `${selectedApplicants.length} applicants`
      : '1 applicant';

  const modalDescription = useMemo(() => {
    const text = `Please confirm that you want to disqualify ${applicantsText} you selected. This action cannot be undone.`;
    if (communicationFormData?.disqualified) {
      return text.concat(
        ' Once disqualified, applicants will receive an email notification.'
      );
    }
    return text;
  }, [applicantsText, communicationFormData?.disqualified]);

  const BulkDisqualifyModal = (
    <AlertModal
      type="danger"
      open={open}
      onClose={() => setOpen(false)}
      title={`You are about to disqualify  ${applicantsText} you selected`}
      description={modalDescription}
      confirmText="Disqualify"
      onProceed={handleBulkDisqualify}
    />
  );

  return {
    handleOpenBulkDisqualifyModal: handleOpenModal,
    BulkDisqualifyModal,
  };
};

export default useBulkDisqualifyModal;
