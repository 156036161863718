import { useShallow } from 'zustand/shallow';
import useFilterStore from './useFilterStore';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';
import { pipelineTitleByStage } from './constants';
import { useParams } from '@tanstack/react-router';

const SelectedFilters = () => {
  const { slug } = useParams({ strict: false });
  const {
    selectedFilters: selectedStoreFilters,
    clearSelectedFilters,
    handleRemoveFilterOption,
    clearStageFilters,
  } = useFilterStore(
    useShallow((state) => ({
      selectedFilters: state.selectedFilters,
      clearSelectedFilters: state.clearSelectedFilters,
      handleRemoveFilterOption: state.handleRemoveFilterOption,
      clearStageFilters: state.clearStageFilters,
    }))
  );
  const selectedFilters = selectedStoreFilters.filter(
    (filter) => filter.positionSlug === slug
  );

  if (!selectedFilters.length) return null;

  return (
    <div className="flex items-center gap-2">
      <span className="self-baseline font-bold">Filters</span>
      <div className="flex flex-wrap items-center gap-2">
        {selectedFilters.map(({ category, stage, options }) => {
          const selectedOptions = options.filter((option) => option.isSelected);
          return (
            <Badge
              key={`${stage}-${category}`}
              variant="secondary"
              className="font-norma6 relative flex flex-wrap items-center rounded-md py-0.5 pr-7"
            >
              <span className="font-bold">{pipelineTitleByStage[stage]}</span>
              <span className="px-1">•</span>
              {category}:
              {selectedOptions.map((option) => (
                <Badge
                  key={option.label}
                  variant="default"
                  className="ml-1 bg-white font-normal text-foreground"
                >
                  {option.label}
                  {selectedOptions.length > 1 && (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="ml-1 size-3"
                      onClick={() => {
                        const filterIndex = selectedFilters.findIndex(
                          (filter) =>
                            filter.category === category &&
                            filter.stage === stage
                        );
                        handleRemoveFilterOption(option, filterIndex);
                      }}
                    >
                      <X />
                    </Button>
                  )}
                </Badge>
              ))}
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-1 top-1/2 ml-1 size-4 -translate-y-1/2"
                onClick={() => {
                  clearStageFilters(stage);
                }}
              >
                <X />
              </Button>
            </Badge>
          );
        })}
        <Button
          variant="ghost"
          onClick={clearSelectedFilters}
          className="h-fit p-1 text-primary"
        >
          Clear all
        </Button>
      </div>
    </div>
  );
};

export default SelectedFilters;
