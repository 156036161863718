import { Button } from '@/components/ui/button';

import logo from '@/assets/svg/logo-whitebg.svg';
import { cn, getContrastColorFromBackgroundColor } from '@/lib/utils';
import { Mic, MicOff, User2Icon, Video, VideoOff } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

import useDims from '@/lib/useDims';
import {
  InterviewQuery,
  useUpdateInterviewConfirmationMutation,
} from '@/fetchers/useInterview';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { SettingsDialog } from './SettingsDialog';
import { useIsMutating } from '@tanstack/react-query';
import { MIME_TYPE, UploadInput } from '../common/UploadInput';
import { useParams } from '@tanstack/react-router';
import { RouterParams } from '@/main';
import { Disclaimer } from './disclaimer';
import { useTranslation } from 'react-i18next';

type TProps = {
  onClickStart: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webcamManager: any;
  data?: InterviewQuery;
  noMic?: boolean | string;
  isStartingInterview: boolean;
};
export function PreInterviewComponent({
  onClickStart,
  webcamManager,
  data,
  noMic,
  isStartingInterview,
}: TProps) {
  const [litBars, setLitBars] = useState(0);
  const [litbarsAmount, setLitbarsAmount] = useState(0);
  const [micOn, setMicOn] = useState(true);
  const isMutating = useIsMutating();
  const [hasResume, setHasResume] = useState(true);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const requestRef = useRef<number | null>(null);
  const litBarsRef = useRef<HTMLDivElement>(null);
  const update = useUpdateInterviewConfirmationMutation();
  const { interviewUUid } = useParams({ strict: false }) as RouterParams;
  const username = data?.userName || 'Candidate';
  const intials = data?.userName?.charAt(0) || 'C';
  const { t } = useTranslation('translation', {
    keyPrefix: 'interview',
  });
  const updateAudioLevels = useCallback(() => {
    requestRef.current = requestAnimationFrame(updateAudioLevels);
    if (!analyserRef.current) return;
    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteFrequencyData(dataArray);

    const maxLevel = Math.max(...dataArray);
    const totalBars = 40;
    const normalizedMaxLevel = (maxLevel / 255) * totalBars; // Normalize against the full range
    setLitBars(Math.round(normalizedMaxLevel));
  }, []);

  const setupAudio = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256; // Ensure FFT size is sufficient
    source.connect(analyser);
    audioContextRef.current = audioContext;
    analyserRef.current = analyser;
    updateAudioLevels();
  }, []);

  useEffect(() => {
    setupAudio();

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      audioContextRef.current?.close();
    };
  }, []);

  useEffect(() => {
    if (!data) return;
    setHasResume(data.hasResume);
  }, [data]);

  useEffect(() => {
    if (litBarsRef.current) {
      const gap = 4;
      setLitbarsAmount(Math.floor(litBarsRef.current.offsetWidth / (5 + gap)));
    }
  }, []);

  const startInterviewButton = (
    <Button
      disabled={!micOn || isStartingInterview}
      className="w-full text-lg"
      onClick={onClickStart}
      isLoading={isStartingInterview}
    >
      {t('start_interview')}
    </Button>
  );
  return (
    <TooltipProvider delayDuration={0}>
      <div className="relative h-screen w-screen overflow-y-auto pb-20">
        <Header
          logo={data?.organization?.logoUrl || logo}
          title={data?.organization?.name || ''}
        />
        <div className="flex w-full flex-col items-center">
          <Card className="md:border-1 w-full max-w-xl border-none shadow-none md:shadow">
            <CardHeader className="whitespace-wrap overflow-hidden">
              <CardTitle>
                <img
                  src={data?.organization?.logoUrl || logo}
                  // className="object-fit"
                  alt="Company Logo"
                  className="mb-4"
                  style={{
                    width: '40px',
                    height: '40px',
                    // objectFit: "fill",
                    aspectRatio: 1,
                  }}
                />

                {data?.title}
              </CardTitle>
              <CardDescription>{t('interview_ready')}</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4 overflow-hidden">
              <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden rounded bg-black sm:h-[252px]">
                {webcamManager.userSetCamereOn &&
                  // @ts-ignore
                  webcamManager.activeRecordings?.map((recording, idx) => (
                    <video
                      key={recording?.id || idx}
                      className="webcam h-full w-full"
                      ref={recording?.webcamRef}
                      loop
                      autoPlay
                      playsInline
                      muted
                    />
                  ))}

                {!webcamManager.userSetCamereOn && (
                  <div className="flex size-[120px] items-center justify-center rounded-full bg-gray-100 text-6xl">
                    <p className="text-gray-500">{intials?.toUpperCase()}</p>
                  </div>
                )}

                <div
                  className="absolute bottom-2 left-2 flex items-center space-x-1 rounded-xl p-2"
                  style={{
                    background: data?.organization?.color,
                    color: getContrastColorFromBackgroundColor(
                      data?.organization?.color
                    ),
                  }}
                >
                  <User2Icon className="size-3" />
                  <p className="text-xs">{username}</p>
                </div>
              </div>

              <div className="flex w-full space-x-2 rounded-xl bg-gray-100 px-4 py-2">
                {micOn ? (
                  <Mic className={cn('size-4', micOn && 'text-green-500')} />
                ) : (
                  <MicOff className="size-4" />
                )}
                <div
                  className="flex w-full items-end justify-center gap-1"
                  ref={litBarsRef}
                >
                  {Array.from({ length: litbarsAmount }).map((_, index) => (
                    <div
                      key={index}
                      className={cn(
                        'h-full w-[5px] rounded-lg',
                        micOn && index < litBars
                          ? 'bg-green-700'
                          : 'bg-gray-500'
                      )}
                    />
                  ))}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2">
                <Button
                  disabled={!!noMic}
                  variant={'outline'}
                  onClick={() => {
                    setMicOn(!micOn);
                  }}
                >
                  {micOn ? (
                    <Mic className="size-4 flex-shrink-0 md:mr-2" />
                  ) : (
                    <MicOff className="size-4 flex-shrink-0 text-red-400 md:mr-2" />
                  )}
                  <span className="hidden sm:block">
                    {micOn ? t('mute') : t('unmute')}
                  </span>
                </Button>
                <Button
                  disabled={webcamManager.noCameraError}
                  variant={'outline'}
                  onClick={async () => {
                    webcamManager.setUserSetCameraOn(
                      !webcamManager.userSetCamereOn
                    );
                    if (webcamManager.userSetCamereOn) {
                      await webcamManager.shutdownCamera();
                    } else {
                      await webcamManager.turnonCamera();
                    }
                  }}
                >
                  {webcamManager.userSetCamereOn ? (
                    <Video className="size-4 flex-shrink-0 md:mr-2" />
                  ) : (
                    <VideoOff className="size-4 flex-shrink-0 text-red-400 md:mr-2" />
                  )}

                  <span className="hidden sm:block">
                    {webcamManager.userSetCamereOn
                      ? t('stop_camera')
                      : t('start_camera')}
                  </span>
                </Button>

                <SettingsDialog
                  variant={'outline'}
                  webcamManager={webcamManager}
                />
              </div>
            </CardContent>
            <CardFooter className="flex flex-col">
              {hasResume ? (
                micOn ? (
                  startInterviewButton
                ) : (
                  <Tooltip>
                    <TooltipTrigger className="w-full" asChild>
                      {startInterviewButton}
                    </TooltipTrigger>
                    <TooltipContent className="bg-black text-gray-200">
                      {t('start_interview_tooltip')}
                    </TooltipContent>
                  </Tooltip>
                )
              ) : (
                <UploadInput
                  buttonOnly
                  buttonOnlyText={t('upload_resume')}
                  className="w-full"
                  isLoading={isMutating > 0}
                  mimeType={MIME_TYPE.PDF}
                  onChange={async (event) => {
                    const file = event.target.files?.[0];

                    if (!file) {
                      console.log('no file');
                      return;
                    }

                    await update({
                      interviewUUid,
                      resume: file,
                    });

                    setHasResume(true);
                  }}
                />
              )}
              <Disclaimer buttonText={t('start_interview')} />
            </CardFooter>
          </Card>
        </div>
        <a
          href="https://veton.ai"
          target="_blank"
          className="fixed bottom-0 left-0 z-20 flex h-[60px] w-full items-center justify-center border-t border-gray-100 bg-white p-4 text-lg"
        >
          Powered by{' '}
          <img
            alt="powered by veton.ai"
            src={logo}
            style={{ height: 25 }}
            className="ml-1"
          />
        </a>
      </div>
    </TooltipProvider>
  );
}

function Header({
  logo,
  title,
  organizationUrl,
}: {
  logo: string;
  title: string;
  organizationUrl?: string;
}) {
  const { isMobile } = useDims();
  return (
    <div className="sticky top-0 z-10 mb-4 mt-6 hidden w-full bg-white md:block lg:mt-4">
      <div className="container flex w-full flex-col justify-between py-2 md:flex-row">
        <div className="flex items-center justify-center">
          <div className="size-[60px] overflow-hidden rounded">
            <img
              src={logo}
              // className="object-fit"
              alt="Company Logo"
              style={{
                width: '60px',
                height: '60px',
                // objectFit: "fill",
                aspectRatio: 1,
              }}
            />
          </div>

          <div className="ml-4 flex flex-col">
            <p className="text-2xl font-bold">{title}</p>
          </div>
        </div>
        <div className="mt-2 text-center">
          {organizationUrl && (
            <a href={organizationUrl} target="__blank">
              <Button variant={isMobile ? 'link' : 'secondary'}>
                Company Website
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
