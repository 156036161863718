export const typeValues = [
  { value: 'Full-time', label: 'Full-time' },
  { value: 'Part-time', label: 'Part-time' },
  { value: 'Contract', label: 'Contract' },
  { value: 'Internship', label: 'Internship' },
  { value: 'Freelance', label: 'Freelance' },
  { value: 'Temporary', label: 'Temporary' },
  { value: 'Other', label: 'Other' },
];

export const educationLevelValues = [
  { value: 'Unspecified', label: 'Unspecified' },
  { value: 'Any', label: 'Any' },
  { value: 'High School or equivalent', label: 'High School or equivalent' },
  { value: 'Certification', label: 'Certification' },
  { value: 'Vocational', label: 'Vocational' },
  { value: 'Associate Degree', label: 'Associate Degree' },
  { value: "Bachelor's Degree", label: "Bachelor's Degree" },
  { value: "Master's Degree", label: "Master's Degree" },
  { value: 'Doctorate', label: 'Doctorate' },
  { value: 'Professional', label: 'Professional' },
];

export const experienceLevelValues = [
  { value: 'Not applicable', label: 'Not applicable' },
  { value: 'Internship', label: 'Internship' },
  { value: 'Entry level', label: 'Entry level' },
  { value: 'Associate', label: 'Associate' },
  { value: 'Mid level', label: 'Mid level' },
  { value: 'Senior level', label: 'Senior level' },
  { value: 'Director', label: 'Director' },
  { value: 'Executive', label: 'Executive' },
];
