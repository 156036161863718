import { Markdown } from '@/components/core/common/Markdown';
import { Loader } from '@/components/core/loader';
import { Badge } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { SelectSeparator } from '@/components/ui/select';

import {
  ApplicationDetail,
  useGetApplication,
  useGetApplicationMessages,
  useGetMessageResponseRecording,
  useSendInterviewMutation,
} from '@/fetchers/useApplication';
import { cn, parseSalaryToDisplay } from '@/lib/utils';
import {
  createRoute,
  ErrorComponent,
  useNavigate,
} from '@tanstack/react-router';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  DownloadIcon,
  Ellipsis,
  NotepadText,
  PlayCircle,
  User2Icon,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import pendingImage from '@/assets/svg/pending.svg';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ASSISTANT_TYPES } from '@/constants/interview';
import { Tabs } from '@/components/ui/tabs';
import { Applicant, useApplicants } from '@/lib/useApplicants';
import { z } from 'zod';
import { toast } from 'sonner';
import { Breadcrumb } from '@/components/core/breadcrumb';
import {
  useCommunicationForm,
  usePaginatedPositionsQuery,
} from '@/fetchers/usePosition';
import { Route as positionManageRoute } from './organizations.$organizationId.positions.manage.$slug.$step';
import useDisqualifyCandidateModal from '@/hooks/useDisqualifyCandidateModal';
import useResetInterviewModal from '@/hooks/useResetInterviewModal';
import {
  interviewVerdictValues,
  resumeVerdictValues,
} from '@/components/core/applications/constants';
import { PhoneLineType } from '@/fetchers/types';
import { Alert } from '@/components/core/alert';
import { CommunicationMethod } from '@/components/core/position/communication/types';
import { useUserDetailStore } from '@/fetchers/useUserDetails';

const searchSchema = z.object({
  selectedTab: z.string().optional(),
  searchQuery: z.string().optional(),
});

export const Route = createRoute({
  path: '/$id',
  getParentRoute: () => positionManageRoute,
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  validateSearch: searchSchema,
  pendingComponent: () => {
    return <Loader />;
  },
  component: CandidateInfo,
});

const rightPanelItems = [
  { title: 'Analysis', value: 'analysis' },
  { title: 'Custom Questions', value: 'questions' },
  { title: 'Recordings', value: 'recordings' },
  { title: 'Resume', value: 'resume' },
  { title: 'Cover Letter', value: 'cover-letter' },
];

export function CandidateInfo() {
  const { id, organizationId, slug } = Route.useParams();
  const [selectedTab, setSelectedTab] = useState('analysis');
  const { data: application, isFetching: isFetchingApplication } =
    useGetApplication({
      applicationId: Number(id),
    });
  const { data: messages = [], isFetching: isFetchingMessages } =
    useGetApplicationMessages({
      applicationId: Number(id),
      enabled: selectedTab === 'recordings',
    });

  const { data: positions, isFetching: isFetchingPositionData } =
    usePaginatedPositionsQuery({
      organizationId,
      slug,
    });
  const { data: communicationForm } = useCommunicationForm({
    slug,
  });
  const isCommunicationMethodSMS =
    communicationForm?.selectedMethod === CommunicationMethod.SMS;
  const showPhoneNumberInvalidAlert =
    isCommunicationMethodSMS && !application?.phoneIsValid;

  const positionData = positions?.[0];

  const interview = application?.interviews?.[0];
  const [messageId, setMessageId] = useState<string | undefined>(undefined);
  const { data: recording } = useGetMessageResponseRecording({
    messageId: messageId || '',
    applicationId: Number(id),
  });
  const navigate = useNavigate();
  const { searchQuery = '' } = Route.useSearch();
  const sendInterviewMutation = useSendInterviewMutation();
  const { applicants } = useApplicants({
    searchQuery,
  });
  const { selectedOrganization } = useUserDetailStore();

  const { handleOpenResetInterviewModal, ResetInterviewModal } =
    useResetInterviewModal();
  const { handleOpenDisqualifyCandidateModal, DisqualifyCandidateModal } =
    useDisqualifyCandidateModal();

  useEffect(() => {
    if (!interview) return;
    if (!interview?.assistantType) return;

    if (interview?.assistantType !== ASSISTANT_TYPES.RESTRICTED) return;

    setSelectedTab('questions');
  }, [interview]);

  const currentIndex = applicants?.findIndex(
    (applicant) => applicant?.application?.id === Number(id)
  );

  const nextApplicant =
    currentIndex !== undefined &&
    currentIndex >= 0 &&
    currentIndex < applicants.length - 1
      ? applicants[currentIndex + 1]
      : undefined;

  const previousApplicant =
    currentIndex !== undefined && currentIndex > 0
      ? applicants[currentIndex - 1]
      : undefined;

  function handleNextApplicant(applicant?: Applicant) {
    if (!applicant) return;
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
      params: {
        organizationId,
        slug,
        step: 'applications',
        id: applicant.application?.id.toString(),
      },
    });
  }

  function handleReportCardClick() {
    window.open(
      `/o/${organizationId}/positions/print/${slug}/applications/${id}`,
      '_blank'
    );
  }

  function getApplicationStatus(application?: ApplicationDetail) {
    if (!application) return;
    const interview = application?.interviews?.[0];

    if (interview?.finalVerdict) {
      return { status: 'completed', label: 'COMPLETED' };
    } else if (!interview?.finalVerdict && interview?.completedAt) {
      return { status: 'processesing', label: 'PROCESSING INTERVIEW' };
    } else if (!interview?.completedAt && interview?.startedAt) {
      return { status: 'in_progress', label: 'INTERVIEW IN PROGRESS' };
    } else if (interview && !interview?.startedAt) {
      return { status: 'interview_sent', label: 'INTERVIEW SENT' };
    } else if (!interview?.startedAt && application?.createdAt) {
      return { status: 'applied', label: 'APPLIED' };
    }
  }

  const phoneLineType = application?.phoneLineType;

  const firstInterviewUrl = application?.interviews?.find(
    (interview) => interview?.interviewUrl
  )?.interviewUrl;
  const dropdownActions = [
    firstInterviewUrl && {
      title: 'Copy Interview Url',
      onClick: () => {
        navigator.clipboard.writeText(firstInterviewUrl);
        toast.success('Interview URL copied to clipboard');
      },
    },

    (application?.interviews || [])?.length === 0 && {
      title:
        showPhoneNumberInvalidAlert ||
        (phoneLineType && phoneLineType !== PhoneLineType.MOBILE)
          ? 'Send interview via email'
          : 'Send interview',
      onClick: () => {
        if (!application?.id) return;
        sendInterviewMutation({
          applicationId: application.id,
        });
      },
    },

    (application?.interviews || [])?.length > 0 && {
      title: 'Reset interview',
      onClick: () => {
        if (!application) return;
        handleOpenResetInterviewModal(application?.interviews?.[0]?.id);
      },
      customClasses: 'font-bold cursor-pointer text-red-500',
    },

    {
      title: 'Disqualify',
      onClick: () => {
        if (!application) return;
        handleOpenDisqualifyCandidateModal(application?.id);
      },
      customClasses: 'font-bold cursor-pointer text-red-500',
    },
  ].filter(Boolean);

  if (isFetchingApplication || isFetchingPositionData) {
    return <Loader />;
  }

  return (
    <>
      {DisqualifyCandidateModal}
      {ResetInterviewModal}

      <Breadcrumb
        items={[
          {
            title: positionData?.title ?? '',
            href: `/organizations/${organizationId}/positions/manage/${slug}/${selectedOrganization?.integrationName ? 'communication' : 'details'}`,
          },
          {
            title: 'Applications',
            href: `/organizations/${organizationId}/positions/manage/${slug}/applications`,
          },
          {
            title: application?.name ?? '',
          },
        ]}
      />
      <Tabs
        defaultValue="all"
        // className='max-w-[1300px]'
        className="w-screen md:w-full"
      >
        <div className="flex flex-col justify-between md:flex-row">
          <div className="no-print flex items-center px-4 py-2">
            <h1 className="text-xl font-bold">Candidate Information</h1>
          </div>

          <div className="no-print flex items-center gap-2 p-2">
            <Button
              variant={'outline'}
              className={cn(!previousApplicant && 'hidden')}
              onClick={() => handleNextApplicant(previousApplicant)}
            >
              <ChevronLeft className="size-4" />
              <p>{previousApplicant?.title}</p>
            </Button>
            <Button
              variant={'outline'}
              className={cn(!nextApplicant && 'hidden')}
              onClick={() => handleNextApplicant(nextApplicant)}
            >
              <p>{nextApplicant?.title}</p>
              <ChevronRight className="size-4" />
            </Button>
            <Button variant={'outline'} onClick={handleReportCardClick}>
              <DownloadIcon className="mr-1 size-4" />
              Report Card
            </Button>
          </div>
        </div>
        <SelectSeparator />

        <div className="grid max-h-[90vh] grid-cols-4 gap-4 overflow-y-auto p-4 md:overflow-y-hidden xl:p-8">
          <div className="col-span-4 max-h-[85vh] overflow-y-auto p-2 lg:col-span-2">
            <div className="rounded-lg p-8 shadow-lg">
              <div className="mb-6 flex items-center justify-between">
                <div className="flex flex-col xl:flex-row xl:items-center">
                  <p className="text-3xl font-bold">{application?.name}</p>
                  <div className="mb-1 xl:ml-4">
                    <Badge className="text-center">
                      {getApplicationStatus(application)?.label}
                    </Badge>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  {application?.createdAt && (
                    <p className="text-right text-sm text-gray-700">
                      Applied{' '}
                      {formatDistanceToNow(new Date(application?.createdAt), {
                        addSuffix: true,
                      })}
                    </p>
                  )}

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon" className="ml-2">
                        <Ellipsis />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-42">
                      <DropdownMenuGroup>
                        {dropdownActions.map((action, idx) => (
                          <DropdownMenuItem
                            key={idx}
                            // @ts-ignore
                            onClick={action.onClick}
                            role="button"
                            className={cn(
                              'cursor-pointer',
                              // @ts-ignore
                              action.customClasses
                            )}
                          >
                            {/* @ts-ignore */}
                            {action?.title || ''}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>

              <Avatar className="size-[100px] text-4xl text-gray-400">
                {application?.userAvatar && (
                  <AvatarImage src={application.userAvatar} alt="User Avatar" />
                )}
                <AvatarFallback>
                  <User2Icon className="size-12" />{' '}
                </AvatarFallback>
              </Avatar>
              <InformationTable
                application={application}
                showPhoneNumberInvalidAlert={showPhoneNumberInvalidAlert}
                phoneLineType={phoneLineType}
              />
            </div>

            {interview?.analysisSummary && (
              <div className="mb-12 mt-12 rounded-lg p-8 shadow-lg lg:mb-0">
                <div className="mb-6 flex items-center justify-between">
                  <h2 className="text-2xl font-bold">Interview Summary</h2>
                  {interview?.finalVerdict && (
                    <Badge>
                      {interviewVerdictValues[interview?.finalVerdict]}
                    </Badge>
                  )}
                </div>
                <p>{interview?.analysisSummary}</p>
              </div>
            )}

            {application?.resumeAnalysis && (
              <div className="mb-12 mt-12 rounded-lg p-8 shadow-lg lg:mb-0">
                <div className="mb-6 flex items-center justify-between">
                  <h2 className="text-2xl font-bold">Resume Analysis</h2>
                  <Badge>
                    {resumeVerdictValues[application?.resumeAnalysisVerdict]}
                  </Badge>
                </div>
                <p>{application?.resumeAnalysis}</p>
              </div>
            )}
          </div>

          <div className="col-span-4 p-2 lg:col-span-2">
            <div className="h-[85vh] overflow-x-hidden rounded-lg shadow-lg">
              <nav className="sticky top-0 z-10 flex space-x-2 overflow-auto bg-white p-4">
                {rightPanelItems.map((item) => {
                  if (
                    item.value === 'analysis' &&
                    interview?.assistantType === ASSISTANT_TYPES.RESTRICTED
                  )
                    return null;
                  if (
                    item.value === 'questions' &&
                    !interview?.additionalQuestionsAndAnswers
                  )
                    return null;
                  return (
                    <Button
                      key={item.value}
                      // @ts-ignore
                      // to={item.href}
                      onClick={() => setSelectedTab(item.value)}
                      variant="ghost"
                      className={cn(
                        buttonVariants({ variant: 'ghost' }),
                        // "pathname" === item.href
                        item.value === selectedTab
                          ? 'bg-muted hover:bg-muted'
                          : 'hover:bg-transparent hover:underline',
                        'justify-start'
                      )}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </nav>
              <Separator className="sticky top-[72px]" />

              {selectedTab === 'analysis' && (
                <div className="p-4 px-6">
                  <h2 className="mb-4 text-2xl font-bold">
                    Interview Analysis
                  </h2>
                  {interview?.analysis && (
                    <Markdown content={interview?.analysis} />
                  )}
                  {!interview?.analysis && (
                    <EmptyTabView
                      title="No analysis available"
                      subtitle="Awaiting applicant to finish its interview to analyze"
                      image={pendingImage}
                    />
                  )}
                </div>
              )}

              {selectedTab === 'questions' && (
                <div className="p-4 px-6">
                  {interview?.additionalQuestionsAndAnswers && (
                    <div className="prose">
                      {interview?.additionalQuestionsAndAnswers.map(
                        (question, idx) => (
                          <div key={idx} className="mb-4">
                            <h3 className="text-lg font-bold">
                              {question.question}
                            </h3>
                            <p>{question.answer}</p>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {!interview?.additionalQuestionsAndAnswers && (
                    <EmptyTabView
                      title="No analysis available"
                      subtitle="Awaiting applicant to finish its interview to analyze"
                      image={pendingImage}
                    />
                  )}
                </div>
              )}

              {selectedTab === 'recordings' &&
                (isFetchingMessages ? (
                  <Loader className="static h-1/2 bg-transparent" />
                ) : messages.length > 1 ? (
                  <div>
                    {interview?.audioRecordingUrl && (
                      <audio
                        controls
                        src={interview?.audioRecordingUrl}
                        className="mb-2 mt-4 w-full px-4"
                      >
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                    )}
                    {messages.map((message, idx) => {
                      const content = message?.content?.replace(
                        /Alice:|Alex:|Emma:|James:|HR Manager:|Hiring Manager:/,
                        ''
                      );
                      return (
                        <div className="p-4 px-6" key={message?.id || idx}>
                          <div className="flex items-center justify-between">
                            <h2 className="mb-4 text-lg font-bold">
                              {message?.role === 'user'
                                ? application?.name
                                : 'HR Manager'}
                              :
                            </h2>

                            {message?.role === 'user' &&
                              !interview?.audioRecordingUrl && (
                                <>
                                  {(!messageId ||
                                    messageId !== messages[idx - 1]?.id) && (
                                    <Button
                                      variant="outline"
                                      onClick={() =>
                                        setMessageId(messages?.[idx - 1]?.id)
                                      }
                                    >
                                      <PlayCircle />
                                    </Button>
                                  )}

                                  {messageId &&
                                  messageId === messages?.[idx - 1]?.id ? (
                                    <Button
                                      variant="outline"
                                      onClick={() => setMessageId(undefined)}
                                    >
                                      <NotepadText />
                                    </Button>
                                  ) : null}
                                </>
                              )}
                          </div>

                          <Markdown content={content} />

                          {message.role === 'user' &&
                            (messageId !== messages?.[idx - 1]?.id ? null : ( // <Markdown content={content} />
                              <video
                                autoPlay
                                controls
                                playsInline
                                src={recording?.url}
                                className="mb-2 mt-4 h-[350px] w-full px-4"
                              >
                                Your browser does not support the
                                <code>video</code> element.
                              </video>
                            ))}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <EmptyTabView
                    title="No recordings available"
                    subtitle="Awaiting applicant to start the interview to record messages"
                    image={pendingImage}
                  />
                ))}

              {selectedTab === 'resume' && (
                <div className="p-4 px-6">
                  {application?.resumeUrl ? (
                    <iframe
                      src={application?.resumeUrl?.split('?')?.[0] || ''}
                      width="100%"
                      height="85vh"
                      style={{
                        height: '85vh',
                      }}
                    ></iframe>
                  ) : (
                    <EmptyTabView
                      title="No resume available"
                      subtitle="Candidate didn't provide a resume."
                      image={pendingImage}
                    />
                  )}
                </div>
              )}

              {selectedTab === 'cover-letter' && (
                <div className="p-4 px-6">
                  {application?.coverLetterUrl ? (
                    <iframe
                      src={application?.coverLetterUrl}
                      width="100%"
                      height="85vh"
                      style={{
                        height: '85vh',
                      }}
                    ></iframe>
                  ) : (
                    <EmptyTabView
                      title="No cover letter available"
                      subtitle="Candidate didn't provide a cover letter."
                      image={pendingImage}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Tabs>
    </>
  );
}

function InformationTable({
  application,
  showPhoneNumberInvalidAlert,
  phoneLineType,
}: {
  application?: ApplicationDetail;
  showPhoneNumberInvalidAlert: boolean;
  phoneLineType?: PhoneLineType;
}) {
  if (!application) return null;
  return (
    <div className="mt-8 w-full space-y-8 break-all">
      {application?.email && (
        <div className="grid grid-cols-2">
          <span className="mr-4 font-semibold">Email</span>
          <p>{handleUndefinedValue(application?.email)}</p>
        </div>
      )}

      {application?.phone && (
        <div className="grid grid-cols-2">
          <span className="mr-4 font-semibold">Phone</span>
          <div className="flex flex-wrap items-center gap-2">
            <p className="flex-shrink-0">
              {handleUndefinedValue(application?.phone)}
            </p>
            {showPhoneNumberInvalidAlert && (
              <div className="min-w-0">
                <Alert
                  severity="error"
                  variant="ghost"
                  description="Invalid phone number"
                />
              </div>
            )}
            {phoneLineType === PhoneLineType.LANDLINE && (
              <div className="min-w-0">
                <Alert
                  severity="error"
                  variant="ghost"
                  description="This phone cannot receive SMS messages."
                />
              </div>
            )}
          </div>
        </div>
      )}

      {application?.linkedinProfile && (
        <div className="grid grid-cols-2">
          <span className="mr-4 font-semibold">Linkedin Profile</span>
          <p>{handleUndefinedValue(application?.linkedinProfile)}</p>
        </div>
      )}

      {application?.additionalInfo && (
        <div className="grid grid-cols-2">
          <span className="mr-4 font-semibold">Additional Information</span>
          <p
            style={{
              wordBreak: 'break-word',
            }}
          >
            {handleUndefinedValue(application?.additionalInfo)}
          </p>
        </div>
      )}

      {application?.desiredSalary && application?.salaryCurrency && (
        <div className="grid grid-cols-2">
          <span className="mr-4 font-semibold">Desired Salary</span>
          {application?.desiredSalary === 'undefined' ? (
            <p>Blank</p>
          ) : (
            <p>
              {parseSalaryToDisplay(
                Number(application?.desiredSalary),
                application?.salaryCurrency
              )}{' '}
              / {application?.salaryType}
            </p>
          )}
        </div>
      )}

      {application?.education && (
        <div className="grid grid-cols-2">
          <span className="mr-4 font-semibold">Education</span>
          <p>{handleUndefinedValue(application?.education)}</p>
        </div>
      )}
    </div>
  );
}

function handleUndefinedValue(value: string | undefined) {
  if (value === 'undefined') {
    return 'Blank';
  } else {
    return value;
  }
}

function EmptyTabView({
  title,
  subtitle,
  image,
}: {
  title: string;
  subtitle: string;
  image: string;
}) {
  return (
    <div className="mt-12 flex flex-col items-center space-y-8">
      <p className="text-xl font-bold"> {title}</p>
      <img
        src={image}
        alt="pending image"
        className="rounded-lg"
        style={{
          width: '50%',
          height: 'auto',
        }}
      />
      <p className="mt-12 max-w-xs text-center">{subtitle}</p>
    </div>
  );
}
