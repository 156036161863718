import { createRoute } from '@tanstack/react-router';
import { BillingComponent } from '@/components/core/billing';
import { useGetBilling, useGetTeamList } from '@/fetchers/useOrganization';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { Layout } from '@/components/core/dashboard/layout';
import { Route as RootRoute } from './__root';

export const Route = createRoute({
  path: '/billing',
  getParentRoute: () => RootRoute,
  component: BillingPageView,
});

function BillingPageView() {
  const { data: teamMembers } = useGetTeamList();
  const { data: billing } = useGetBilling({ enabled: true });

  return (
    <Layout>
      <Breadcrumb
        items={[
          {
            title: 'Billing',
          },
        ]}
      />
      <BillingComponent
        tier={{
          amount: billing?.amount || 0,
          isMonthly: billing?.isMonthly || false,
          maxInterviewAllowed: billing?.maxInterviewAllowed || 5,
          maxApplicationAvailable: Infinity,
          maxResumeAnalysisAvailable: Infinity,
          atsIntegration: billing?.atsIntegration || false,
          nextRenewalDate: billing?.nextRenewalDate || '',
          planStatus: billing?.planStatus || '',
          currentPlan: billing?.currentPlan,
        }}
        portalUrl={billing?.portalUrl}
        metrics={{
          interviewsTotal: billing?.interviewTotal || 0,
          applicationsTotal: billing?.applicationTotal || 0,
          teamCount: teamMembers?.length || 0,
        }}
      />
    </Layout>
  );
}
