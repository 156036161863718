import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { FormLabel } from '@/components/ui/form';

import { Button } from '@/components/ui/button';

import { HiringMember } from '@/fetchers/usePosition';

import { useIsMutating } from '@tanstack/react-query';

import { Separator } from '@/components/ui/separator';
import { HiringTeamFormValues } from '@/lib/usePositionHiringTeamForm';
import { useFormContext } from 'react-hook-form';
import { TeamMember, useGetTeamList } from '@/fetchers/useOrganization';
import Member from './member';
import { useState } from 'react';
import { pick } from 'lodash';
import { Loader } from '../../loader';

export function HiringTeamForm({
  onAddMember,
  onAddAllMembers,
  onRemoveMember,
}: {
  onAddMember: (member: TeamMember) => Promise<void>;
  onAddAllMembers: () => void;
  onRemoveMember: (member: HiringMember) => Promise<void>;
}) {
  const [selectedMember, setSelectedMember] = useState('');
  const isMutating = useIsMutating();

  const { watch } = useFormContext<HiringTeamFormValues>();
  const hiringTeamMembers = watch('hiringTeamMembers');

  const { data: teamMembers } = useGetTeamList();

  const availableMembers =
    teamMembers?.filter((teamMember) => {
      return !hiringTeamMembers?.find(
        (hiringTeamMember) => hiringTeamMember.user.email === teamMember.email
      );
    }) ?? [];

  const enrichedHiringTeam = (hiringTeamMembers?.map((hiringTeamMember) => ({
    ...hiringTeamMember,
    user: {
      ...hiringTeamMember.user,
      ...pick(
        teamMembers?.find(
          (teamMember) => teamMember.email === hiringTeamMember.user.email
        ),
        ['fullName', 'imageUrl']
      ),
    },
  })) ?? []) as HiringMember[];

  const isInitialized = watch('isInitialized');

  if (!isInitialized) {
    return <Loader className="static h-[50vh] bg-transparent" />;
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <FormLabel>Add Team Members</FormLabel>
        <div className="flex gap-2">
          <Select onValueChange={setSelectedMember}>
            <SelectTrigger>
              <SelectValue placeholder="Select a member" />
            </SelectTrigger>
            <SelectContent>
              {availableMembers.length > 1 && (
                <SelectItem value="all">All Members</SelectItem>
              )}
              {availableMembers.map((member) => (
                <SelectItem key={member.id} value={member.email}>
                  {member.email}
                </SelectItem>
              ))}
              {!availableMembers.length && (
                <p className="py-2 text-center text-sm">
                  There are no members to add
                </p>
              )}
            </SelectContent>
          </Select>
          <Button
            type="button"
            isLoading={isMutating > 0}
            className="float-right"
            onClick={async () => {
              if (selectedMember === 'all') {
                return onAddAllMembers();
              }
              const memberToAdd = availableMembers.find(
                (member) => member.email === selectedMember
              );
              if (!memberToAdd) return;
              await onAddMember(memberToAdd);
            }}
            disabled={!availableMembers.length}
          >
            Add
          </Button>
        </div>
      </div>
      <div>
        <Separator className="my-8" />
        <h2 className="mb-4 text-lg font-medium">Team</h2>

        <div className="space-y-2">
          {enrichedHiringTeam.length > 0 ? (
            enrichedHiringTeam.map((member) => (
              <Member member={member} onRemove={onRemoveMember} />
            ))
          ) : (
            <div className="flex flex-col items-center gap-3">
              <h1 className="max-w-sm text-center text-xl">
                You don't have any hiring team members
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
